/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Modal, Button, FormControl, InputGroup, Image } from 'react-bootstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import { fetchAllPlaces } from '../../redux/dispatchers/placeDispatcher';
import DashboardAPI from '../../service/api';
import { updateStoreRedux } from '../../redux/dispatchers/storeDispatcher';

const cookies = new Cookies();

function ModalStore(props) {
  const { modalVisible = false, onHide = () => null, placeList = [], placeLoading = true, type = 'store', modalDataPassed = {} } = props;

  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({
    modalId: null,
    modalName: null,
    modalCat: null,
    modalImg: null,
    modalDesc: null,
    modalCreated: null,
    modalUpdateImg: false,
    modalNewImgUrl: null,
    modalCreateNewStore: false,
    modalPlaces: false,
    modalPlacesStoreId: null,
    modalPlacesList: [],
    modalPlacesListRaw: [],
    modalPlacesAdd: [],
    modalPlacesDelete: [],
  });

  const {
    modalId,
    modalName,
    // eslint-disable-next-line no-unused-vars
    modalCat,
    modalImg,
    modalDesc,
    // eslint-disable-next-line no-unused-vars
    modalCreated,
    modalUpdateImg,
    modalNewImgUrl,
    // eslint-disable-next-line no-unused-vars
    modalPlaces,
    modalPlacesStoreId,
    modalPlacesList,
    modalPlacesListRaw,
    // eslint-disable-next-line no-unused-vars
    modalPlacesAdd,
    // eslint-disable-next-line no-unused-vars
    modalPlacesDelete,
  } = modalData;

  useEffect(() => {
    if (placeList.length === 0 && !placeLoading) {
      props.fetchAllPlaces();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModalData((prevState) => ({
      ...prevState,
      ...modalDataPassed,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalDataPassed]);

  const updateStorePlaces = async () => {
    const toBeAdded = modalPlacesList.filter((a) => !modalPlacesListRaw.some((b) => b.value === a.value));
    const toBeDeleted = modalPlacesListRaw.filter((a) => !modalPlacesList.some((b) => b.value === a.value));
    const token = cookies.get('token');
    if (toBeAdded.length > 0) {
      try {
        const result = await DashboardAPI.StoreAPI.placeAssociationAdd(toBeAdded, token, modalPlacesStoreId);
        if (result.status === 200) {
          setModalData((prevState) => ({
            ...prevState,
            modalPlaces: false,
            modalName: null,
            modalCat: null,
            modalImg: null,
            modalDesc: null,
            modalCreated: null,
            modalPlacesStoreId: null,
            modalPlacesList: [],
            modalPlacesListRaw: [],
          }));
          onHide();
        }
      } catch (error) {
        alert('There are some error, please try again');
      }
    }
    if (toBeDeleted.length > 0) {
      try {
        const result = await DashboardAPI.StoreAPI.placeAssociationDelete(toBeDeleted, token, modalPlacesStoreId);
        if (result.status === 200) {
          setModalData((prevState) => ({
            ...prevState,
            modalPlaces: false,
            modalName: null,
            modalCat: null,
            modalImg: null,
            modalDesc: null,
            modalCreated: null,
            modalPlacesStoreId: null,
            modalPlacesList: [],
            modalPlacesListRaw: [],
          }));
          onHide();
        }
      } catch (error) {
        alert('There are some error, please try again');
      }
    }
  };

  const updateStore = async () => {
    const token = cookies.get('token');
    if (modalId) {
      setLoading(true);
      const data = {
        name: modalName,
        description: modalDesc,
        storeId: modalId,
        imgUrl: modalNewImgUrl,
        uploadImg: modalUpdateImg,
      };
      console.log('DATAA', data);
      try {
        const response = await DashboardAPI.StoreAPI.storeUpdate(token, data);
        console.log(response);
        alert('succeed');
        setModalData((prevState) => ({
          ...prevState,
          modalVisible: false,
          modalId: null,
          modalName: null,
          modalCat: null,
          modalImg: null,
          modalDesc: null,
          modalCreated: null,
          modalUpdateImg: false,
          modalNewImgUrl: null,
          loading: false,
        }));
        // eslint-disable-next-line react/destructuring-assignment
        props.updateStoreRedux(data);
        onHide();
        setLoading(false);
      } catch (error) {
        alert('error, please try again');
        console.log(error);
      }
    } else {
      alert('No ID');
    }
  };

  if (!modalVisible) return null;
  return (
    <Modal show={modalVisible} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Modify Store {type === 'places' && 'Place'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3 mt-3 row">
          <div className="col-3">
            <FormControl placeholder="id" aria-label="id" name="id" id="id" aria-describedby="basic-addon1" value={modalId} />
            <label className="form-control-placeholder" htmlFor="id" style={{ fontSize: 12 }}>
              <i className="fas fa-user prefix white-text active pr-2" /> id
            </label>
          </div>
          <div className="col-2">{modalImg ? <Image src={modalImg} rounded style={{ width: 50, height: 50 }} /> : ` `}</div>
          <div className="col-7" style={{ padding: 0 }}>
            <FormControl
              placeholder="Ex: Gopay, OVO, Chatime"
              name="name"
              id="name"
              aria-label="name"
              aria-describedby="basic-addon1"
              value={modalName}
              onChange={(e) => {
                // eslint-disable-next-line prefer-destructuring
                const value = e.target.value;
                setModalData((prevState) => ({ ...prevState, modalName: value }));
              }}
            />
            <label className="form-control-placeholder" htmlFor="name" style={{ left: '-15px', fontSize: 12 }}>
              <i className="fas fa-user prefix white-text active pr-2" /> Name
            </label>
          </div>
        </InputGroup>

        {type !== 'places' && (
          <InputGroup className="mb-3 mt-4">
            <FormControl
              as="textarea"
              aria-label="With textarea"
              id="description"
              name="description"
              value={modalDesc}
              onChange={(e) => {
                // eslint-disable-next-line prefer-destructuring
                const value = e.target.value;
                setModalData((prevState) => ({ ...prevState, modalDesc: value }));
              }}
            />
            <label className="form-control-placeholder" htmlFor="description" style={{ left: '-10px', fontSize: 12 }}>
              <i className="fas fa-user prefix white-text active pr-2" /> Description
            </label>
          </InputGroup>
        )}

        {type !== 'places' && (
          <InputGroup className="mb-3 mt-5 row">
            <div className="form-group pb-1 col">
              <label className="switch" style={{ float: 'left' }}>
                <input
                  type="checkbox"
                  name="modalUpdateImg"
                  id="modalUpdateImg"
                  onChange={() => setModalData((prevState) => ({ ...prevState, modalUpdateImg: !modalUpdateImg }))}
                  checked={modalUpdateImg}
                />
                <span className="slider round"> </span>
              </label>
              <span style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, marginLeft: 20 }}>Update image?</span>
            </div>
            {modalUpdateImg && (
              <div className="form-group pb-1 col" style={{ padding: 0 }}>
                <FormControl
                  aria-label="Image url"
                  aria-describedby="basic-addon1"
                  value={modalNewImgUrl}
                  onChange={(e) => {
                    // eslint-disable-next-line prefer-destructuring
                    const value = e.target.value;
                    setModalData((prevState) => ({ ...prevState, modalNewImgUrl: value }));
                  }}
                />
                <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', fontSize: 12 }}>
                  <i className="fas fa-user prefix white-text active pr-2" /> Image url to be uploaded
                </label>
              </div>
            )}
          </InputGroup>
        )}

        {type === 'places' && placeList && (
          <InputGroup className="mb-3 mt-5 row">
            <div className="form-group pb-1 col">
              <Select
                isMulti
                name="colors"
                options={placeList}
                className="basic-multi-select"
                classNamePrefix="select"
                value={modalPlacesList}
                onChange={(selected) => setModalData((prevState) => ({ ...prevState, modalPlacesList: selected }))}
              />
            </div>
          </InputGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        {loading === true ? (
          <h3>Loading...</h3>
        ) : (
          <Button onClick={() => (type !== 'places' ? updateStore() : updateStorePlaces())} variant="info">
            Update
          </Button>
        )}
        <Button onClick={onHide} variant="danger">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = ({ place }) => {
  const { placeList, placeLoading } = place;
  return { placeList, placeLoading };
};
export default connect(mapStateToProps, { updateStoreRedux, fetchAllPlaces })(ModalStore);
