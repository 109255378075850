import axios from 'axios';

const headers = {
  Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1bmlxdWVfbmFtZSI6IjYyODU2OTc0NzMxNzlAcG9pbmluLmNvbSIsIm5hbWVpZCI6ImYxZWE4Yjg0LTQyNWYtNDlhOS1iOGEwLWEwMDgzNmIzMzUxMyIsInJvbGUiOiJCdXllciIsImlzcyI6Imh0dHBzOi8vYXBpLnBvaW5pbi5jb20iLCJhdWQiOiIwOTkxNTNjMjYyNTE0OWJjOGVjYjNlODVlMDNmMDAyMiIsImV4cCI6MTYzMTI2NDQxNCwibmJmIjoxNjAwMTYwNDE0fQ.Suf7kXXqD3Qc_OgFCgVm3DfCcGAP-x3I65SJABhkcD8`,
};

async function getHotPoinin(page = 0) {
  const response = await axios.get(`https://promo.poinin.com/api/PromoPage/GetHotPromo?page=${page}`);
  const { data } = response;
  if (data.status === false) {
    throw data;
  } else {
    return data;
  }
}

async function getLatestPoinin(page = 0) {
  const response = await axios.get(`https://promo.poinin.com/api/PromoPage/GetLatest?page=${page}`);
  const { data } = response;
  if (data.status === false) {
    throw data;
  } else {
    return data;
  }
}

const pages = [0, 1, 2, 3, 4];

async function getLatestPoinin5Pages() {
  // eslint-disable-next-line prefer-const
  let result = [];
  await Promise.all(
    pages.map(async (a) => {
      const response = await axios.get(`https://promo.poinin.com/api/PromoPage/GetLatest?page=${a}`, { headers });
      const { data } = response;
      if (data.status === false) {
        throw data;
      } else {
        result.push(...data);
        // result = [...result, ...data];
      }
    })
  );
  return result;
}

async function getDetailPoinin(id) {
  const response = await axios.get(`https://promo.poinin.com/api/PromoPage?id=${id}&currentLatitude=0&currentLongitude=0&getStores=false`, {
    headers,
  });
  const { data } = response;
  if (data.status === false) {
    throw data;
  } else {
    return data;
  }
}

async function getSearchPoinin(term) {
  const response = await axios.get(`https://search.poinin.com/api/Search?term=${term}&page=0`, {
    headers,
  });
  const { data } = response;
  if (data.status === false) {
    throw data;
  } else {
    return data;
  }
}

async function getMerchantDetailPoinin(id) {
  const response = await axios.get(`https://entity.poinin.com/api/MerchantPage/${id}`, {
    headers,
  });
  const { data } = response;
  if (data.status === false) {
    throw data;
  } else {
    return data;
  }
}

export default {
  getHotPoinin,
  getLatestPoinin,
  getDetailPoinin,
  getLatestPoinin5Pages,
  getSearchPoinin,
  getMerchantDetailPoinin,
};
