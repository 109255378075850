/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { Modal, Button, FormControl, InputGroup } from 'react-bootstrap';
import Select from 'react-select/lib/Select';

const CheckboxComponent = (props) => {
  const { name, id, checked, param, setState, title } = props;
  return (
    <>
      <label className="switch" style={{ float: 'left' }}>
        <input type="checkbox" name={name} id={id} checked={checked} onChange={() => setState(param, !checked)} />
        <span className="slider round"> </span>
      </label>
      <span style={{ fontSize: 18, fontWeight: 'bold' }}>{title}</span>
    </>
  );
};

export default CheckboxComponent;
