/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import copy from 'copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import DashboardAPI from '../../../service/api';
import ModalAdd from './modaladd';
import BrandDiscountModal from '../../../components/modal/modal-brand-discount';
import 'react-toastify/dist/ReactToastify.css';

const store = [
  {
    id: 1,
    storeId: 21,
    storeName: 'Staqarbucks',
    type: 'instagram',
    url: 'https://www.instagram.com/starbucksindonesia/?__a=1',
    username: 'starbucksindonesia',
    lastendpoint: '',
    lastcheck: '2020-02-27T00:11:10.500Z',
    created: '2020-01-25T23:32:01.000Z',
  },
  {
    id: 2,
    storeId: 22,
    storeName: 'Gulu Gulu',
    type: 'instagram',
    url: 'https://www.instagram.com/guluguluid/?__a=1',
    username: 'guluguluid',
    lastendpoint: '',
    lastcheck: '2020-02-27T00:11:10.500Z',
    created: '2020-01-27T23:32:01.000Z',
  },
  {
    id: 3,
    storeId: 23,
    storeName: 'KFC',
    type: 'instagram',
    url: 'https://www.instagram.com/kfcindonesia/?__a=1',
    username: 'kfcindonesia',
    lastendpoint: '',
    lastcheck: '2020-02-27T00:11:10.500Z',
    created: '2020-01-25T23:32:01.000Z',
  },
  {
    id: 4,
    storeId: 24,
    storeName: 'Chatime',
    type: 'instagram',
    url: 'https://www.instagram.com/chatimeindo/?__a=1',
    username: 'kfcindonesia',
    lastendpoint: '',
    lastcheck: '2020-02-27T00:11:10.500Z',
    created: '2020-01-25T23:32:01.000Z',
  },
];

const CheckBox = ({ onChange, checked }) => {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="switch" style={{ float: 'left' }}>
      <input type="checkbox" name="checked" onChange={onChange} checked={checked} />
      <span className="slider round"> </span>
    </label>
  );
};

const addEditDataDefault = {
  storeId: null,
  type: 'instagram',
  url: '',
  username: '',
};

function CrawlerScreen(props) {
  const [sites, setSites] = useState(store);
  const [sitesArr, setSitesArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [checkedStore, setCheckedStore] = useState([]);
  const [modal, setModal] = useState({ show: false, type: null });
  const [editId, setEditId] = useState(null);
  const [addEditData, setAddEditData] = useState(addEditDataDefault);
  const [addEditDataStore, setAddEditDataStore] = useState(null);
  const [brandDiscountPopUp, setBrandDiscountPopUp] = useState({
    show: false,
    list: [],
  });

  const cookies = new Cookies();
  const token = cookies.get('token');
  const { globalState = {} } = props;

  async function fetchList() {
    setLoading(true);
    try {
      const response = await DashboardAPI.CrawlAPI.getList(token);
      if (response.data.status === true) {
        setSites(response.data.result);
        setSitesArr([...response.data.result.map((a) => a.storeId)]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert('Ada masalah mengambil data');
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchList();
  }, []);

  async function fetchUrl(url) {
    try {
      const res = await axios.get(url);
      console.log('Fetch res', res);
      if (!res.data.graphql.user.edge_owner_to_timeline_media.edges) {
        throw new Error('No data');
      }
      return res.data.graphql.user.edge_owner_to_timeline_media.edges;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  const checkStore = async (id) => {
    const index = checkedStore.indexOf(id);
    console.log('checkStore', id, index, checkedStore);
    let tempCheck = Object.assign([], checkedStore);
    if (index !== -1) {
      tempCheck = tempCheck.filter((e) => e !== id);
      console.log('REMOVE', tempCheck);
    } else {
      tempCheck = [...tempCheck, id];
      console.log('ADD', tempCheck);
    }
    setCheckedStore(tempCheck);
    const newData = data.map((res) => {
      /* if(tempCheck.includes(id)){
                return { ...res, checked: true }
            } else {
                return { ...res, checked: false }
            } */
      return { ...res, checked: tempCheck };
    });
    setData(newData);
  };

  const crawl = async () => {
    console.log('start crawl');
    const array = [];
    setLoading(true);
    await Promise.all(
      sites.map(async (a) => {
        if (!moment(a.lastcheck).isSame(moment(), 'day')) {
          const res = await fetchUrl(a.url);
          console.log(res);
          if (res instanceof Error) {
            return null;
          }
          array.push({
            check: checkedStore.includes(a.storeId),
            id: a.id,
            username: a.username,
            url: a.url,
            type: a.type,
            storeId: a.storeId,
            storeName: a.storeName,
            lastcheck: a.lastcheck,
            data: [],
          });
          await Promise.all(
            res.map((b) => {
              const { __typename, edge_media_to_caption, thumbnail_src, taken_at_timestamp, shortcode = '' } = b.node;
              const datePosted = moment.unix(taken_at_timestamp);
              const dateLastCheck = moment(a.lastcheck);
              if ((__typename === 'GraphImage' || __typename === 'GraphSidecar') && datePosted.diff(dateLastCheck, 'days') >= 0) {
                const desc = edge_media_to_caption.edges[0].node.text;
                const img = thumbnail_src;
                if (['promo', 'diskon', 'potongan harga', 'cashback', 'katalog', 'sale'].some((c) => desc.toLowerCase().includes(c))) {
                  array[array.length - 1].data.push({
                    desc,
                    img,
                    posted: datePosted.format('DD-MMM-YYYY'),
                    shortcode,
                  });
                }
              }
            })
          );
          array[array.length - 1].total = array[array.length - 1].data.length;
        }
      })
    );
    console.log(array);
    if (array === []) {
      alert('Belum ada data baru');
    }
    setData(array);
    setLoading(false);
  };

  const copyUrl = (url) => {
    copy(url);
    toast.info('🦄 Berhasil tercopy', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const columns = [
    {
      dataField: 'check',
      text: 'Checked?',
      formatter: (cell, row) => {
        return <CheckBox checked={checkedStore.includes(row.id)} id={row.id} onChange={() => checkStore(row.id)} />;
      },
    },
    {
      dataField: 'storeId',
      text: 'Store ID',
    },
    {
      dataField: 'storeName',
      text: 'Store Name',
    },
    {
      dataField: 'lastcheck',
      text: 'Last checked?',
      formatter: (cell) => {
        return moment(cell).format('DD-MMM-YYYY');
      },
    },
    {
      dataField: 'total',
      text: 'Total',
    },
    {
      dataField: 'checkLastDiscount',
      text: 'Check Last Discount',
      formatter: (cell, row) => {
        return (
          // eslint-disable-next-line no-use-before-define
          <Button variant="warning" onClick={() => checkBrandDiscount(row.storeId)}>
            Check Last
          </Button>
        );
      },
    },
    {
      dataField: 'edit',
      text: 'Edit',
      formatter: (cell, row) => {
        return (
          <Button
            variant="secondary"
            onClick={() => {
              const { id, storeId, storeName, url, username, type } = row;
              setEditId(id);
              setAddEditData({ url, storeId, username, type });
              setAddEditDataStore({ label: storeName, value: storeId });
              setModal({ type: 'edit', show: true });
            }}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <div key={row.id}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th style={{ width: 70 }}>#</th>
              <th style={{ width: 100 }}>Img </th>
              <th>Desc</th>
              <th>Posted</th>
              <th style={{ width: 100 }}>Post</th>
            </tr>
          </thead>
          <tbody>
            {row.data.map((a, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={i}>
                  <td>{i}</td>
                  <td>
                    <img src={a.img} style={{ width: 80, height: 80 }} alt="asd" />
                  </td>
                  <td style={{ textAlign: 'left' }}>{a.desc.substr(0, 150)}</td>
                  <td style={{ textAlign: 'left' }}>{a.posted}</td>
                  <td>
                    <Link
                      to={`/dashboard/discount/submit?crawlIg=true&igUrl=${`https://instagram.com/p/${a.shortcode}`}`}
                      target="_blank"
                      query={{ crawlIg: true, igUrl: `https://instagram.com/p/${a.shortcode}` }}
                      params={{ crawlIg: true, igUrl: `https://instagram.com/p/${a.shortcode}` }}
                    >
                      <Button variant="primary">Post</Button>
                    </Link>
                    <Button className="mt-2" variant="secondary" onClick={() => copyUrl(`https://instagram.com/p/${a.shortcode}`)}>
                      Copy URL
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return (
          <span className="sidebar-icon">
            <i className="fa fa-minus-square" style={{ color: '#30d151', fontSize: 18 }} />
          </span>
        );
      }
      return (
        <span className="sidebar-icon">
          <i className="fa fa-plus-square" style={{ color: '#30d151', fontSize: 18 }} />
        </span>
      );
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <span className="sidebar-icon">
            <i className="fa fa-minus-square" style={{ color: '#30d151', fontSize: 18 }} />
          </span>
        );
      }
      return (
        <span className="sidebar-icon">
          <i className="fa fa-plus-square" style={{ color: '#30d151', fontSize: 18 }} />
        </span>
      );
    },
  };

  const finish = async () => {
    console.log(checkedStore);
    try {
      setLoading(true);
      const response = await DashboardAPI.CrawlAPI.updateLastCheck(token, { data: checkedStore });
      if (response.data.status === true) {
        alert('Berhasil');
        setTimeout(() => {
          fetchList();
        }, 100);
        setTimeout(() => {
          crawl();
        }, 2500);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const addNew = async (event) => {
    event.preventDefault();
    if (modal.type === 'add' && sitesArr.includes(addEditData.storeId)) {
      alert('Brand sudah dimasukkan');
    } else {
      setLoading(true);
      try {
        const response =
          modal.type === 'edit'
            ? await DashboardAPI.CrawlAPI.updateList(token, { id: editId, ...addEditData })
            : await DashboardAPI.CrawlAPI.addList(token, { ...addEditData, lastcheck: moment().subtract(7, 'd').format('YYYY-MM-DD HH:mm:ss') });
        if (response.data.status === true) {
          alert('Berhasil');
          fetchList();
        }
        setLoading(false);
        setModal({ show: false });
        setAddEditDataStore(addEditDataDefault);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const checkBrandDiscount = async (id) => {
    // eslint-disable-next-line camelcase
    const result_array = [id];
    const listBrand = result_array.toString().split(',').join("','");
    try {
      const response = await DashboardAPI.DiscountAPI.multipleBrandDiscount(token, listBrand);
      console.log('response', response);
      if (response.data.status === true) {
        setBrandDiscountPopUp({
          list: response.data.result,
          show: true,
        });
      } else {
        alert('Terjadi kesalaahn, silahkan cek internet anda atau coba beberapa saat lagi');
      }
    } catch (error) {
      alert('Terjadi kesalaahn, silahkan cek internet anda atau coba beberapa saat lagi');
    }
  };
  // console.log(props);
  // (dataBrand = {}), setBrand, selectedBrand, setType, selectedType, setUrl, valueUrl, setUsername, valueUsername;
  return (
    <>
      <ToastContainer />
      {loading === true && <div className="loading">Loading</div>}
      <div className="view" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: 10 }}>
        <ModalAdd
          title={modal.type === 'edit' ? 'Edit Crawl List' : 'Add Crawl List'}
          submitTitle={modal.type === 'edit' ? 'Edit' : 'Add'}
          show={modal.show}
          onHide={() => setModal((prevState) => ({ ...prevState, show: false }))}
          dataBrand={globalState.liststore}
          setUrl={(value) => setAddEditData((prev) => ({ ...prev, url: value }))}
          valueUrl={addEditData.url}
          setType={(value) => setAddEditData((prev) => ({ ...prev, type: value }))}
          selectedType={addEditData.type}
          setUsername={(value) => setAddEditData((prev) => ({ ...prev, username: value }))}
          valueUsername={addEditData.username}
          setBrand={(value) => {
            setAddEditData((prev) => ({ ...prev, storeId: value.value }));
            setAddEditDataStore(value);
          }}
          selectedBrand={addEditDataStore}
          submit={addNew}
        />
        <BrandDiscountModal
          data={brandDiscountPopUp.list}
          visible={brandDiscountPopUp.show}
          hide={() => setBrandDiscountPopUp((prev) => ({ ...prev, show: false }))}
        />
        <div className="mask rgba-gradient align-items-center">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12 col-xl-12 mb-0">
                <h2 style={{ textAlign: 'left' }}>Crawler</h2>
                <Button
                  variant="secondary"
                  style={{ position: 'absolute', top: 0, right: 250 }}
                  onClick={() => {
                    setModal({ show: true, type: 'add' });
                    setAddEditDataStore(addEditDataDefault);
                  }}
                >
                  + Add new list
                </Button>
                <Button variant="primary" style={{ position: 'absolute', top: 0, right: 150 }} onClick={finish}>
                  + Finish
                </Button>
                <Button variant="primary" style={{ position: 'absolute', top: 0, right: 20 }} onClick={crawl}>
                  + Start crawl
                </Button>
              </div>
              <div className="col-md-12 col-xl-12 mb-0">
                <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
                  <div className="card-body">
                    <BootstrapTable keyField="storeId" data={data} columns={columns} expandRow={expandRow} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CrawlerScreen;
