/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import '../../assets/style/dashboard.css';
import $ from 'jquery';

import { Route, Switch, Link } from 'react-router-dom';
import Axios from 'axios';
import Cookies from 'universal-cookie';
import AddDiscountScreen from './discount/adddiscount';
import AddPlaceStoreScreen from '../storeinplace';
import AddStoreScreen from './store/index';
import DashboardMain from './dashboardMain';

import authService from '../../service/authService';
import AddNewsScreen from './addnews';
import UploadImgScreen from './uploadimg';
import NotificationScreen from './notification/index';
import ListDiscountAdmin from './discount/index';
import EditDiscountScreen from './editdiscount';
import UserScreen from './user/index';
import Report from './report/index';
import Polling from './polling/index';
import NewsList from './news/newslist';
import ThirdParty from './third-party/index';
import ThirdPartySearch from './third-party/search';
import ThirdPartyMerchant from './third-party/merchantDetail';

import CrawlerScreen from './crawler';
import CouponScreen from './coupon';
import DashboardAPI from '../../service/api';

const cookies = new Cookies();

const style = {
  sidebarText: { fontSize: 'large', letterSpacing: 1, float: 'left', paddingLeft: 10 },
  sidebarIcon: { fontSize: 'large', float: 'left', color: '#35395C' },
};

class DashboardScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      listdiscount: [],
      // eslint-disable-next-line react/no-unused-state
      liststore: [],
      // eslint-disable-next-line react/no-unused-state
      listplace: [],
      userData: [],
      loading: false,
      loadingDiscount: false,
      loadingStore: false,
      loadingPlace: false,
    };
  }

  componentDidMount = () => {
    const userData = cookies.get('userData');
    /* this.renderDiscount();
    this.renderStore();
    this.renderPlace(); */
    $(document).ready(function () {
      $('footer').hide();
      $('#menu_icon').click(function () {
        if ($('.page-sidebar').hasClass('expandit')) {
          $('.page-sidebar').addClass('collapseit');
          $('.page-sidebar').removeClass('expandit');
          $('.profile-info').addClass('short-profile');
          $('.logo-area').addClass('logo-icon');
          $('#main-content').addClass('sidebar_shift');
          $('.menu-title').css('display', 'none');
        } else {
          $('.page-sidebar').addClass('expandit');
          $('.page-sidebar').removeClass('collapseit');
          $('.profile-info').removeClass('short-profile');
          $('.logo-area').removeClass('logo-icon');
          $('#main-content').removeClass('sidebar_shift');
          $('.menu-title').css('display', 'inline-block');
        }
      });
    });
    // const { state = { data: null } } = this.props.history.location;
    if (userData) {
      this.setState({ userData });
    }
  };

  renderDiscount = async () => {
    const token = cookies.get('token');
    this.setState({ loadingDiscount: true });
    try {
      const res = await DashboardAPI.DiscountAPI.listdiscount(token, 50);
      const { result = [], status } = res.data;
      if (status === true) {
        this.setState({
          listdiscount: result,
        });
      } else if (res.data.message === 'Token Expired1' || res.data.message === 'Token Expired') {
        this.logout();
      }
      this.setState({ loadingDiscount: false });
    } catch (error) {
      this.setState({ loadingDiscount: false });
    }
  };

  renderStore = async () => {
    const token = cookies.get('token');
    try {
      this.setState({ loadingStore: true });
      const res = await DashboardAPI.StoreAPI.storeList(token, 3000);
      const { result = [], status } = res.data;
      if (status === true) {
        const newRes = result.map((a) => ({
          ...a,
          label: a.name,
          value: a.id,
        }));
        this.setState({
          liststore: newRes,
        });
      } else if (res.data.message === 'Token Expired1' || res.data.message === 'Token Expired') {
        this.logout();
      }
      this.setState({ loadingStore: false });
    } catch (error) {
      if (error.response) {
        const message = error.response.err?.data?.message || '';
        if (message === 'Token Expired1' || message === 'Token Expired') {
          this.logout();
        }
      }
      this.setState({ loadingStore: false });
    }
  };

  renderPlace = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    this.setState({ loadingPlace: true });
    Axios.post(`${BASE_URL}api/showplaceall`)
      .then((res) => {
        this.setState({
          listplace: res.data.data,
          loadingPlace: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loadingPlace: false });
      });
  };

  async logout() {
    try {
      const res = await authService.deleteJWT();
      console.log('Logging out', res);
      this.props.history.replace({ pathname: '/login-brand' });
    } catch (error) {
      this.props.history.replace({ pathname: '/login-brand' });
    }
  }

  updateState = (param, value) => {
    console.log('updateGlobal', { param, value });
    this.setState({ [param]: value });
  };

  render() {
    const {
      userData,
      listdiscount = [],
      // eslint-disable-next-line react/no-unused-state
      liststore = [],
      // eslint-disable-next-line react/no-unused-state
      listplace = [],
      loading = false,
      loadingDiscount,
      loadingPlace,
      loadingStore,
    } = this.state;
    const stateGlobal = {
      listdiscount,
      // eslint-disable-next-line react/no-unused-state
      liststore,
      // eslint-disable-next-line react/no-unused-state
      listplace,
      userData,
      loadingGlobal: !!loading || !!loadingStore || !!loadingPlace || !!loadingDiscount,
    };
    return (
      <div style={{ marginTop: 50, height: '100%' }}>
        {(!!loading || !!loadingStore || !!loadingPlace || !!loadingDiscount) && <div className="loading">Loading&#8230;</div>}
        <div className="page-topbar">
          <div className="logo-area"> </div>
          <div className="quick-area">
            <ul className="pull-left info-menu  user-notify" style={{ float: 'left' }}>
              <button id="menu_icon">
                <i className="fa fa-bars" aria-hidden="true" style={{ fontSize: 'xx-large', justifyContent: 'center', display: 'flex' }} />
              </button>
              {/* <li><a href="#"> <i className="fa fa-envelope"></i> <span className="badge">8</span></a></li>
                <li><a href="#"> <i className="fa fa-bell"></i> <span className="badge">5</span></a></li>    */}
            </ul>
            <ul className="pull-right info-menu user-info" style={{ float: 'right' }}>
              <li className="profile">
                <a href="#" data-toggle="dropdown" className="toggle" aria-expanded="false">
                  <img alt="" src={userData.img || 'https://placeimg.com/50/50/any'} className="img-circle img-inline" />
                  <span>
                    {userData.name || 'User'} <i className="fa fa-angle-down" />
                  </span>
                </a>
                <ul className="dropdown-menu profile fadeIn">
                  <li>
                    <a href="#settings">
                      <i className="fa fa-wrench" />
                      Settings
                    </a>
                  </li>
                  <li>
                    <a href="#profile">
                      <i className="fa fa-user" />
                      {` Profile`}
                    </a>
                  </li>

                  <li className="last">
                    <a href="ui-login.html">
                      <i className="fa fa-lock" />
                      Sign Out
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="page-sidebar expandit">
          <div className="sidebar-inner" id="main-menu-wrapper">
            {/* <div className="profile-info row ">
                <div className="profile-image ">
                        <a href="ui-profile.html">
                            <img alt="" src="https://lh3.googleusercontent.com/-uwagl9sPHag/WM7WQa00ynI/AAAAAAAADso/ZGTVC0LVk1cFReheTbZzpGa2aawWyV8QACL0B/w140-d-h148-p-rw/profile-pic.jpg" className="img-circle img-inline img-responsive"/>
                        </a>
                    </div>
                    <div className="profile-details">
                        <h3>
                            <a href="ui-profile.html">Shane Taylor</a> 
                        </h3>
                        <p className="profile-title">Web Developer</p>

                    </div>
                </div> */}

            <ul className="wraplist" style={{ height: 'auto', marginTop: 20 }}>
              {/* <li className="menusection float-left">Main</li> */}
              <li>
                <Link to="/dashboard/home" style={{ backgroundColor: this.props.location.pathname.startsWith('/dashboard/home') ? '#e6e6e6' : '' }}>
                  <span className="sidebar-icon">
                    <i className="fa fa-tachometer-alt" style={style.sidebarIcon} />
                  </span>
                  <span className="menu-title" style={style.sidebarText}>
                    {' '}
                    Dashboard
                  </span>
                </Link>
              </li>
              {/* userData.uac <= 4 && (
                <li>
                  <Link
                    to="/dashboard/submit-discount"
                    style={{ backgroundColor: this.props.location.pathname.startsWith('/dashboard/submit-discount') ? '#e6e6e6' : '' }}
                  >
                    <span className="sidebar-icon">
                      <i className="fa fa-bullseye" style={style.sidebarIcon} />
                    </span>
                    <span className="menu-title" style={style.sidebarText}>
                      {' '}
                      Add New Discount
                    </span>
                  </Link>
                </li>
              ) */}
              {userData.uac <= 4 && (
                <li>
                  <Link
                    to="/dashboard/store"
                    style={{ backgroundColor: this.props.location.pathname.startsWith('/dashboard/store') ? '#e6e6e6' : '' }}
                  >
                    <span className="sidebar-icon">
                      <i className="fa fa-store" style={style.sidebarIcon} />
                    </span>
                    <span className="menu-title" style={style.sidebarText}>
                      {' '}
                      Store/Brand
                    </span>
                  </Link>
                </li>
              )}
              {userData.uac <= 3 && (
                <li>
                  <Link
                    to="/dashboard/discount"
                    style={{ backgroundColor: this.props.location.pathname.startsWith('/dashboard/discount') ? '#e6e6e6' : '' }}
                  >
                    <span className="sidebar-icon">
                      <i className="fa fa-percent" style={style.sidebarIcon} />
                    </span>
                    <span className="menu-title" style={style.sidebarText}>
                      {' '}
                      Discount
                    </span>
                  </Link>
                </li>
              )}
              {userData.uac <= 4 && (
                <li>
                  <Link
                    to="/dashboard/place"
                    style={{ backgroundColor: this.props.location.pathname.startsWith('/dashboard/place') ? '#e6e6e6' : '' }}
                  >
                    <span className="sidebar-icon">
                      <i className="fa fa-suitcase" style={style.sidebarIcon} />
                    </span>
                    <span className="menu-title" style={style.sidebarText}>
                      {' '}
                      Place
                    </span>
                  </Link>
                </li>
              )}
              {userData.uac <= 3 && (
                <li>
                  <Link to="/dashboard/news" style={{ backgroundColor: this.props.location.pathname.startsWith('/dashboard/news') ? '#e6e6e6' : '' }}>
                    <span className="sidebar-icon">
                      <i className="fa fa-newspaper" style={style.sidebarIcon} />
                    </span>
                    <span className="menu-title" style={style.sidebarText}>
                      {' '}
                      News
                    </span>
                  </Link>
                </li>
              )}
              {userData.uac <= 3 && (
                <li>
                  <Link
                    to="/dashboard/notification"
                    style={{ backgroundColor: this.props.location.pathname.startsWith('/dashboard/notification') ? '#e6e6e6' : '' }}
                  >
                    <span className="sidebar-icon">
                      <i className="fa fa-bell" style={style.sidebarIcon} />
                    </span>
                    <span className="menu-title" style={style.sidebarText}>
                      {' '}
                      Notifications
                    </span>
                  </Link>
                </li>
              )}
              {userData.uac === 1 && (
                <li>
                  <Link to="/dashboard/user" style={{ backgroundColor: this.props.location.pathname.startsWith('/dashboard/user') ? '#e6e6e6' : '' }}>
                    <span className="sidebar-icon">
                      <i className="fa fa-users" style={style.sidebarIcon} />
                    </span>
                    <span className="menu-title" style={style.sidebarText}>
                      {' '}
                      Users
                    </span>
                  </Link>
                </li>
              )}
              {userData.uac <= 2 && (
                <li>
                  <Link
                    to="/dashboard/report"
                    style={{ backgroundColor: this.props.location.pathname.startsWith('/dashboard/report') ? '#e6e6e6' : '' }}
                  >
                    <span className="sidebar-icon">
                      <i className="fa fa-file" style={style.sidebarIcon} />
                    </span>
                    <span className="menu-title" style={style.sidebarText}>
                      {' '}
                      Report
                    </span>
                  </Link>
                </li>
              )}
              {userData.uac <= 3 && (
                <li>
                  <Link
                    to="/dashboard/polling"
                    style={{ backgroundColor: this.props.location.pathname.startsWith('/dashboard/polling') ? '#e6e6e6' : '' }}
                  >
                    <span className="sidebar-icon">
                      <i className="fa fa-poll" style={style.sidebarIcon} />
                    </span>
                    <span className="menu-title" style={style.sidebarText}>
                      {' '}
                      Polling
                    </span>
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to="/dashboard/crawler"
                  style={{ backgroundColor: this.props.location.pathname.startsWith('/dashboard/crawler') ? '#e6e6e6' : '' }}
                >
                  <span className="sidebar-icon">
                    <i className="fa fa-spider" style={style.sidebarIcon} />
                  </span>
                  <span className="menu-title" style={style.sidebarText}>
                    {' '}
                    Crawler
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/coupon"
                  style={{ backgroundColor: this.props.location.pathname.startsWith('/dashboard/coupon') ? '#e6e6e6' : '' }}
                >
                  <span className="sidebar-icon">
                    <i className="fa fa-tags" style={style.sidebarIcon} />
                  </span>
                  <span className="menu-title" style={style.sidebarText}>
                    {' '}
                    Coupon
                  </span>
                </Link>
              </li>
              <li key="logout" onClick={() => this.logout()}>
                <a href="#">
                  <span className="sidebar-icon">
                    <i className="fa fa-lock" style={style.sidebarIcon} />
                  </span>{' '}
                  <span className="menu-title" style={style.sidebarText}>
                    Sign Out
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <section id="main-content" style={{ flex: 1, height: '100%' }}>
          <Switch>
            <Route path="/dashboard/home" component={() => <DashboardMain globalState={stateGlobal} updateGlobal={this.updateState} />} />
            <Route
              path="/dashboard/submit-discount/"
              component={() => <AddDiscountScreen globalState={stateGlobal} updateGlobal={this.updateState} />}
            />
            <Route path="/dashboard/place/" component={() => <AddPlaceStoreScreen globalState={stateGlobal} updateGlobal={this.updateState} />} />
            <Route path="/dashboard/store/" component={() => <AddStoreScreen globalState={stateGlobal} updateGlobal={this.updateState} />} />
            <Route
              path="/dashboard/notification/"
              component={() => <NotificationScreen globalState={stateGlobal} updateGlobal={this.updateState} />}
            />
            <Route path="/dashboard/manage-images/" component={() => <UploadImgScreen globalState={stateGlobal} updateGlobal={this.updateState} />} />
            <Route path="/dashboard/news/add" component={() => <AddNewsScreen globalState={stateGlobal} />} updateGlobal={this.updateState} />
            <Route
              path="/dashboard/news/update/:id"
              component={() => <AddNewsScreen update globalState={stateGlobal} updateGlobal={this.updateState} />}
              updateGlobal={this.updateState}
            />
            <Route path="/dashboard/news/" component={() => <NewsList globalState={stateGlobal} />} updateGlobal={this.updateState} />
            <Route
              path="/dashboard/discount/submit"
              component={() => <AddDiscountScreen globalState={stateGlobal} updateGlobal={this.updateState} />}
            />
            <Route path="/dashboard/discount/" component={() => <ListDiscountAdmin globalState={stateGlobal} updateGlobal={this.updateState} />} />
            <Route
              path="/dashboard/edit-discount/:id"
              component={() => <EditDiscountScreen globalState={stateGlobal} updateGlobal={this.updateState} />}
              updateGlobal={this.updateState}
            />
            <Route path="/dashboard/user/" component={() => <UserScreen globalState={stateGlobal} />} updateGlobal={this.updateState} />
            <Route path="/dashboard/report/" component={() => <Report globalState={stateGlobal} />} updateGlobal={this.updateState} />
            <Route path="/dashboard/polling/" component={() => <Polling globalState={stateGlobal} />} updateGlobal={this.updateState} />
            <Route path="/dashboard/crawler/" component={() => <CrawlerScreen globalState={stateGlobal} />} updateGlobal={this.updateState} />
            <Route path="/dashboard/coupon/" component={() => <CouponScreen globalState={stateGlobal} />} updateGlobal={this.updateState} />
            <Route
              path="/dashboard/third-party/search"
              component={() => <ThirdPartySearch globalState={stateGlobal} />}
              updateGlobal={this.updateState}
            />
            <Route
              path="/dashboard/third-party/merchant/:id"
              component={() => <ThirdPartyMerchant globalState={stateGlobal} />}
              updateGlobal={this.updateState}
            />
            <Route path="/dashboard/third-party/" component={() => <ThirdParty globalState={stateGlobal} />} updateGlobal={this.updateState} />
            <Route component={() => <DashboardMain globalState={stateGlobal} updateGlobal={this.updateState} />} />
            {/*  <Route path="/dashboard/" component={() => <DashboardMain  globalState={stateGlobal} updateGlobal={this.updateState} />} />
                    { userData.uac === 1 && (
                    <Route path="/dashboard/user/" component={() => <UserScreen  globalState={stateGlobal}/>} updateGlobal={this.updateState}  />
                    )}
                    { userData.uac <= 2 && (
                    <Route path="/dashboard/report/" component={() => <Report  globalState={stateGlobal}/>} updateGlobal={this.updateState}  />
                    )}
                    { userData.uac <= 3 && (
                    <Route path="/dashboard/polling/" component={() => <Polling  globalState={stateGlobal}/>} updateGlobal={this.updateState}  />
                    )}
                    { userData.uac <= 3 && (
                    <Route path="/dashboard/notification/" component={() => <NotificationScreen  globalState={stateGlobal} updateGlobal={this.updateState} />} />
                    )}
                    { userData.uac <= 3 && (
                    <Route path="/dashboard/manage-images/" component={() => <UploadImgScreen  globalState={stateGlobal} updateGlobal={this.updateState} />} />
                    )}
                    { userData.uac <= 3 && (
                    <Route path="/dashboard/submit-news/" component={() => <AddNewsScreen  globalState={stateGlobal}/>} updateGlobal={this.updateState}  />
                    )}
                    { userData.uac <= 3 && (
                    <Route path="/dashboard/discount/" component={() => <ListDiscountAdmin  globalState={stateGlobal} updateGlobal={this.updateState} />} />
                    )}
                    { userData.uac <= 3 && (
                    <Route path="/dashboard/edit-discount/:id" component={() => <EditDiscountScreen  globalState={stateGlobal}/>} updateGlobal={this.updateState}  />
                    )}
                    { userData.uac <= 4 && (
                    <Route path="/dashboard/submit-discount/" component={() => <AddDiscountScreen  globalState={stateGlobal} updateGlobal={this.updateState} />} />
                    )}
                    { userData.uac <= 4 && (
                    <Route path="/dashboard/place/" component={() => <AddPlaceStoreScreen  globalState={stateGlobal} updateGlobal={this.updateState} />} />
                    )}
                    { userData.uac <= 4 && (
                    <Route path="/dashboard/store/" component={() => <AddStoreScreen  globalState={stateGlobal} updateGlobal={this.updateState} />} />
                    )}
                    <Route component={DashboardMain} /> */}
          </Switch>
        </section>
      </div>
    );
  }
}

export default DashboardScreen;
