/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Button, Table, Accordion, Card, Row, Col } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DashboardAPI from '../../../service/api';
import ModalSource from './modal-add-source';

const cookies = new Cookies();
const CheckBox = ({ onChange, checked }) => {
  return (
    <label className="switch" style={{ float: 'left' }}>
      <input type="checkbox" name="checked" onChange={onChange} checked={checked} />
      <span className="slider round"> </span>
    </label>
  );
};

const TableToBeUpdated = ({ data = [] }) => {
  if (data.length < 1) return null;
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>store</th>
          <th>partner</th>
          <th>value</th>
          <th>discount</th>
          <th>priceDiscount</th>
        </tr>
      </thead>
      <tbody>
        {data.map((a) => (
          <tr>
            <td>{a.storeName}</td>
            <td>{a.partnerName}</td>
            <td>{a.value}</td>
            <td>{a.discount}</td>
            <td>{a.priceDiscount}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

function CouponScreen(props) {
  const [source, setSource] = useState([]);
  const [rawSource, setRawSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [checkedStore, setCheckedStore] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const [crawlList, setCrawlList] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [rawCouponList, setRawCouponList] = useState([]);
  const [toBeUpdated, setToBeUpdated] = useState([]);
  const [toBeAdded, setToBeAdded] = useState([]);
  const [toBeInactive, setToBeInactive] = useState([]);
  const [crawlResult, setCrawlResult] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [modalData, setModalData] = useState({
    storeId: null,
    couponpartnerId: null,
    url: '',
  });

  useEffect(() => {
    fetchCoupon();
    fetchsource();
    fetchPartner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCoupon = async () => {
    setLoading(true);
    const token = cookies.get('token');
    try {
      const res = await DashboardAPI.CouponAPI.couponList(token, 10000);
      console.log(res);
      if (res.status === 200) {
        setRawCouponList(res.data.result);
        setCouponList(groupByJavascriptCoupon(res.data.result));
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const checkStore = async (id) => {
    const index = checkedStore.indexOf(id);
    console.log('checkStore', id, index, checkedStore);
    let tempCheck = Object.assign([], checkedStore);
    let tempCrawlList = Object.assign([], crawlList);
    if (index !== -1) {
      tempCheck = tempCheck.filter((e) => e !== id);
      tempCrawlList = tempCrawlList.filter((e) => e.storeId !== id);
      console.log('REMOVE', tempCheck, tempCrawlList);
    } else {
      tempCheck = [...tempCheck, id];
      tempCrawlList = [...tempCrawlList, source.filter((a) => a.storeId === id)[0]];
      console.log('ADD', tempCheck, tempCrawlList);
    }
    setCheckedStore(tempCheck);
    setCrawlList(tempCrawlList);
    const newData = source.map((res) => {
      return { ...res, checked: tempCheck };
    });
    setSource(newData);
  };

  const fetchsource = async () => {
    setLoading(true);
    const token = cookies.get('token');
    try {
      const res = await DashboardAPI.CouponAPI.sourceList(token);
      if (res.status === 200) {
        setRawSource(res.data.result);
        setSource(groupByJavascript(res.data.result));
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const fetchPartner = async () => {
    setLoading(true);
    const token = cookies.get('token');
    try {
      const res = await DashboardAPI.CouponAPI.partnerList(token);
      if (res.status === 200) {
        const dataPartner = res.data.result;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < dataPartner.length; i++) {
          dataPartner[i].value = dataPartner[i].id;
          dataPartner[i].label = dataPartner[i].name;
        }
        setPartnerList(dataPartner);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function groupByJavascript(input) {
    const result = [];
    input.map((data, i) => {
      const index = result.findIndex((e) => e.storeId === data.storeId);
      // console.log(index, result, data.id)
      const {
        id,
        storeId,
        storeName,
        storeImg,
        couponPartnerId,
        partnerName,
        url,
        lastcheck,
        selectorType,
        selectorTitle,
        selectorDiscount,
        selectorPrice,
        created,
        updated,
      } = data;
      if (index !== -1) {
        result[index].options.push({
          id,
          couponPartnerId,
          partnerName,
          url,
          lastcheck,
          selectorType,
          selectorTitle,
          selectorDiscount,
          selectorPrice,
          created,
          updated,
        });
      } else {
        // console.log(data);
        result.push({
          storeId,
          storeName,
          storeImg,
          updated,
          options: [
            {
              id,
              couponPartnerId,
              partnerName,
              url,
              lastcheck,
              selectorType,
              selectorTitle,
              selectorDiscount,
              selectorPrice,
              created,
              updated,
            },
          ],
        });
      }
    });
    // The result array is your desired result
    return result;
  }

  function groupByJavascriptCoupon(input) {
    const result = [];
    input.map((data, i) => {
      const index = result.findIndex((e) => e.storeId === data.storeId);
      // console.log(index, result, data.id)
      const {
        id,
        storeId,
        storeName,
        storeImg,
        couponPartnerId,
        partnerName,
        url,
        lastcheck,
        couponsourceId,
        couponpartnerName,
        value,
        price,
        discount,
        priceDiscount,
        selectorType,
        selectorTitle,
        selectorDiscount,
        selectorPrice,
        created,
        updated,
      } = data;
      if (index !== -1) {
        result[index].options.push({
          id,
          couponsourceId,
          couponpartnerName,
          url,
          value,
          price,
          discount,
          priceDiscount,
          created,
          updated,
        });
      } else {
        // console.log(data);
        result.push({
          storeId,
          storeName,
          storeImg,
          updated,
          options: [
            {
              id,
              couponsourceId,
              couponpartnerName,
              url,
              value,
              price,
              discount,
              priceDiscount,
              created,
              updated,
            },
          ],
        });
      }
    });
    // The result array is your desired result
    return result;
  }

  const columns = [
    {
      dataField: 'check',
      text: 'Checked?',
      formatter: (cell, row) => {
        return <CheckBox checked={checkedStore.includes(row.storeId)} id={row.storeId} onChange={() => checkStore(row.storeId)} />;
      },
    },
    {
      dataField: 'storeId',
      text: 'Store ID',
    },
    {
      dataField: 'storeName',
      text: 'Store Name',
    },
    {
      dataField: 'storeImg',
      text: 'Store img',
      formatter: (cell) => {
        return <img src={cell} style={{ width: 80, height: 80 }} alt={cell} />;
      },
    },
    {
      dataField: 'updated',
      text: 'Last Update?',
      formatter: (cell) => {
        return moment(cell.updated).format('DD-MMM-YYYY');
      },
    },
    {
      dataField: 'total',
      text: 'Total',
    },
  ];

  const expandRow = {
    renderer: (row) => (
      <div key={row.id}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th style={{ width: 70 }}>id</th>
              <th style={{ width: 100 }}>CouponPartnerId</th>
              <th>partnerName</th>
              <th>Url</th>
              <th>lastcheck</th>
              <th>created</th>
              <th>updated</th>
              <th style={{ width: 100 }}>Open</th>
            </tr>
          </thead>
          <tbody>
            {row.options.map((a, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={i}>
                  <td>{a.id}</td>
                  <td>{a.couponPartnerId}</td>
                  <td>{a.partnerName}</td>
                  <td>
                    <a href={a.url}>link</a>
                  </td>
                  <td>{moment(a.lastcheck).format('DD-MMM-YYYY')}</td>
                  <td>{moment(a.created).format('DD-MMM-YYYY')}</td>
                  <td>{moment(a.updated).format('DD-MMM-YYYY')}</td>
                  <td>
                    <Button variant="primary" onClick={null}>
                      Open
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return (
          <span className="sidebar-icon">
            <i className="fa fa-minus-square" style={{ color: '#30d151', fontSize: 18 }} />
          </span>
        );
      }
      return (
        <span className="sidebar-icon">
          <i className="fa fa-plus-square" style={{ color: '#30d151', fontSize: 18 }} />
        </span>
      );
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <span className="sidebar-icon">
            <i className="fa fa-minus-square" style={{ color: '#30d151', fontSize: 18 }} />
          </span>
        );
      }
      return (
        <span className="sidebar-icon">
          <i className="fa fa-plus-square" style={{ color: '#30d151', fontSize: 18 }} />
        </span>
      );
    },
  };

  const expandRowCoupon = {
    renderer: (row) => (
      <div key={row.id}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th style={{ width: 70 }}>id</th>
              <th style={{ width: 100 }}>couponsourceId</th>
              <th>partnerName</th>
              <th>Url</th>
              <th>Value</th>
              <th>Price</th>
              <th>Discount</th>
              <th>priceDiscount</th>
              <th>lastcheck</th>
              <th>created</th>
              <th>updated</th>
            </tr>
          </thead>
          <tbody>
            {row.options.map((a, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={i}>
                  <td>{a.id}</td>
                  <td>{a.couponsourceId}</td>
                  <td>{a.couponpartnerName}</td>
                  <td>
                    <a href={a.url}>link</a>
                  </td>
                  <td>{a.value}</td>
                  <td>{a.price}</td>
                  <td>{a.discount}</td>
                  <td>{a.priceDiscount}</td>
                  <td>{moment(a.lastcheck).format('DD-MMM-YYYY')}</td>
                  <td>{moment(a.created).format('DD-MMM-YYYY')}</td>
                  <td>{moment(a.updated).format('DD-MMM-YYYY')}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    ),
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return (
          <span className="sidebar-icon">
            <i className="fa fa-minus-square" style={{ color: '#30d151', fontSize: 18 }} />
          </span>
        );
      }
      return (
        <span className="sidebar-icon">
          <i className="fa fa-plus-square" style={{ color: '#30d151', fontSize: 18 }} />
        </span>
      );
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <span className="sidebar-icon">
            <i className="fa fa-minus-square" style={{ color: '#30d151', fontSize: 18 }} />
          </span>
        );
      }
      return (
        <span className="sidebar-icon">
          <i className="fa fa-plus-square" style={{ color: '#30d151', fontSize: 18 }} />
        </span>
      );
    },
  };

  const startCrawl = async () => {
    console.log(crawlList);
    const result = [];
    setLoading(true);
    const crawling = await Promise.all(
      crawlList.map(async (a) => {
        try {
          const res = await axios.post('http://localhost:3003/', {
            data: a.options,
          });
          console.log(res);
          result.push({
            storeId: a.storeId,
            storeName: a.storeName,
            storeImg: a.storeImg,
            results: res.data.result,
          });
          setLoading(false);
          return true;
        } catch (error) {
          console.log(error);
          setLoading(false);
          return false;
        }
      })
    );
    console.log(result);
    setLoading(false);
    setCrawlResult(result);
    if (crawlResult.length > 0) {
      checkToBePush();
    }
  };

  const checkToBePush = async () => {
    const update = [];
    const add = [];
    const inactive = [];
    crawlResult.map((store) => {
      return store.results.map((couponRes) => {
        const dbCouponRes = rawCouponList.filter((a) => a.couponsourceId === couponRes.couponsourceId);
        // SET INACTIVE TO NOT LOAD
        if (dbCouponRes.length > 0) {
          dbCouponRes.map((a) => {
            const setToInactive = couponRes.results.some((b) => b.value.includes(a.value));
            if (!setToInactive)
              return inactive.push({
                storeName: store.storeName,
                partnerName: couponRes.partnerName,
                ...a,
              });
            return true;
          });
        }
        return couponRes.results.map((a) => {
          // console.log('A', a.value, a.title, couponRes.couponsourceId);
          /* CHECK IF EXIST */
          const exist = dbCouponRes.filter((dbData) => {
            return dbData.value === a.value;
          });
          /* CHECK IF EXIST AND STATUS INACTIVE */
          if (exist.length > 0) {
            if (
              exist[0].active !== 1 ||
              exist[0].discount !== a.discount ||
              exist[0].price !== a.price ||
              exist[0].priceDiscount !== a.priceDiscount
            ) {
              console.log('dbCouponRes', dbCouponRes);
              console.log('UPDATEEEE', exist[0], a);
              update.push({
                storeName: store.storeName,
                partnerName: couponRes.partnerName,
                ...exist[0],
              });
            }
          } else {
            // console.log("NOT EXIST", dbCouponRes)
            add.push({
              storeName: store.storeName,
              partnerName: couponRes.partnerName,
              couponsourceId: couponRes.couponsourceId,
              ...a,
            });
          }
          return true;
        });
      });
    });
    console.log('UPDATE', update);
    console.log('ADD', add);
    console.log('INACTIVE', inactive);
    setToBeUpdated(update);
    setToBeAdded(add);
    setToBeInactive(inactive);
  };

  const resultUpdate = async (type = null, data) => {
    setLoading(true);
    const token = cookies.get('token');
    try {
      let res;
      if (type === 'add') {
        res = await DashboardAPI.CouponAPI.couponAdd(token, data);
      } else if (type === 'update') {
        res = await DashboardAPI.CouponAPI.couponUpdate(token, data);
      } else if (type === 'inactive') {
        res = await DashboardAPI.CouponAPI.couponSetInactive(token, data);
      } else {
        return Error('No data');
      }
      if (res.status === 200) {
        if (type === 'add') {
          setToBeAdded([]);
        } else if (type === 'update') {
          setToBeUpdated([]);
        } else if (type === 'inactive') {
          setToBeInactive([]);
        }
        fetchCoupon();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const addNewSource = async () => {
    const token = cookies.get('token');
    console.log(modalData);
    try {
      const res = await DashboardAPI.CouponAPI.sourceAdd(token, modalData);
      if (res.status !== 200) {
        throw new Error('Error status');
      } else {
        fetchsource();
        setModalShow(false);
        setModalData({
          storeId: null,
          couponpartnerId: null,
          url: '',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading === true && <div className="loading">Loading</div>}
      <div className="view" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: 10 }}>
        <ModalSource
          show={modalShow}
          modalType={modalType}
          modalData={modalData}
          storeList={props.storeList}
          sourcePartnerList={partnerList}
          setModalData={setModalData}
          onUpdate={addNewSource}
          onHide={() => setModalShow(false)}
        />
        <div className="mask rgba-gradient align-items-center">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12 col-xl-12 mb-0">
                <h2 style={{ textAlign: 'left' }}>Coupon</h2>
                <Button variant="primary" style={{ position: 'absolute', top: 0, right: 145 }} onClick={checkToBePush}>
                  + Generate to be update
                </Button>
                <Button variant="primary" style={{ position: 'absolute', top: 0, right: 15 }} onClick={startCrawl}>
                  + Start crawl
                </Button>
              </div>
              <div className="col-md-12 col-xl-12 mb-0">
                <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
                  <div className="card-body">
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            Coupon Source
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <Button variant="outline-secondary" style={{ float: 'right', marginBottom: 10 }} onClick={() => setModalShow(true)}>
                              + Add source
                            </Button>
                            <Button variant="outline-primary" style={{ float: 'right', marginBottom: 10, marginRight: 20 }} onClick={fetchsource}>
                              + Fetch source
                            </Button>
                            <BootstrapTable keyField="storeId" data={source} columns={columns} expandRow={expandRow} />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="1">
                            Coupon List
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <BootstrapTable keyField="storeId" data={couponList} columns={columns} expandRow={expandRowCoupon} />
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="2">
                            Update Result
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body>
                            <Row>
                              <Col>
                                <h3>To be added</h3>
                                <Button variant="outline-primary" onClick={() => resultUpdate('add', toBeAdded)}>
                                  + Start add
                                </Button>
                                <TableToBeUpdated data={toBeAdded} />
                              </Col>
                              <Col>
                                <h3>To be updated</h3>
                                <Button variant="outline-secondary" onClick={() => resultUpdate('update', toBeUpdated)}>
                                  + Start update
                                </Button>
                                <TableToBeUpdated data={toBeUpdated} />
                              </Col>
                              <Col>
                                <h3>To be inactive</h3>
                                <Button variant="outline-danger" onClick={() => resultUpdate('inactive', toBeInactive)}>
                                  + Start set inactive
                                </Button>
                                <TableToBeUpdated data={toBeInactive} />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ store }) => {
  const { storeLoading, storeError, storeList, storeLastFetched, storeListWeb } = store;
  return { storeLoading, storeError, storeList, storeLastFetched, storeListWeb };
};

export default connect(mapStateToProps)(withRouter(CouponScreen));
