import Cookies from 'universal-cookie';
import { fetchingAllPlaces, fetchingAllPlaceSuccess, fetchingAllPlaceFailed } from '../actions/placeActions';
import API from '../../service/api';

const cookies = new Cookies();

// eslint-disable-next-line import/prefer-default-export
export const fetchAllPlaces = () => {
  return async (dispatch, getState) => {
    const { placeLoading } = getState().place;
    if (!placeLoading) {
      dispatch(fetchingAllPlaces());
      try {
        const token = cookies.get('token');
        if (!token) {
          throw new Error('Invalid Token');
        }
        const result = await API.PlaceAPI.placeList();
        dispatch(fetchingAllPlaceSuccess(result || []));
        return result;
      } catch (error) {
        dispatch(fetchingAllPlaceFailed(error));
        throw error;
      }
    } else {
      return null;
    }
  };
};
