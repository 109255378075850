/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Image } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import Moment from 'react-moment';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import ModalStore from '../../../components/modal/modal-store';
import DashboardAPI from '../../../service/api';
import { fetchAllStore, fetchAllStoreWeb } from '../../../redux/dispatchers/storeDispatcher';
import { ButtonRegular } from '../../../components/button';
import ModalAddStore from './components/modalAddStore';
import Layout from '../../../components/layout';
import Content from '../../../components/content';
import { SpinnerOverlay } from '../../../components/molecules/spinner';

const cookies = new Cookies();

const dataTable = {
  columns: [
    {
      label: 'Id',
      field: 'id',
      width: 3,
    },
    {
      label: 'Name',
      field: 'title',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'Image',
      field: 'image',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'Description',
      field: 'description',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Created',
      field: 'created',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Total discount',
      field: 'totalDisc',
      sort: 'desc',
      width: 200,
    },
    {
      label: 'Total followers?',
      field: 'totalFollow',
      sort: 'desc',
      width: 150,
    },
    {
      label: 'Open?',
      field: 'open',
      sort: 'asc',
      width: 100,
    },
    {
      label: 'Places?',
      field: 'places',
      sort: 'asc',
      width: 100,
    },
  ],
};

class AddStoreScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataRows: [],
      modalVisible: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.renderStore();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.storeList !== this.props.storeList /* && this.props.storeList.length > 0 */) {
      this.updateStoreTable();
    }
  };

  renderStore = () => {
    const { storeList = [] } = this.props;
    if (storeList.length < 1) {
      this.props.fetchAllStore();
    }
    if (storeList.length > 0 && this.state.dataRows.length < 1) {
      this.updateStoreTable();
    }
  };

  updateStoreTable = () => {
    if (this.props.storeList.length > 1) {
      this.setState({ loading: true });
      const result = [];
      this.props.storeList.map((data) => {
        result.push({
          id: data.id,
          title: data.name,
          image: data.img ? <Image src={data.img} rounded style={{ width: 50, height: 50 }} /> : ` `,
          description: data.description || ` `,
          created: <Moment format="DD-MMM-YYYY">{data.created || ` `}</Moment>,
          totalDisc: data.totalDisc || `0`,
          totalFollow: data.totalFollow || `0`,
          open: <ButtonRegular onClick={() => this.openModal(data, 'discount')}>Open</ButtonRegular>,
          places: <ButtonRegular onClick={() => this.openModal(data, 'places')}>Places</ButtonRegular>,
        });
      });
      const resulttopush = [];
      resulttopush.columns = dataTable.columns;
      resulttopush.rows = result;
      this.setState({
        dataRows: resulttopush,
        loading: false,
      });
    }
  };

  openModal = async (data, type) => {
    console.log({ data, type });
    if (type !== 'places') {
      this.setState({
        modalVisible: true,
        modalId: data.id,
        modalName: data.name,
        modalCat: data.catId,
        modalImg: data.img,
        modalDesc: data.description,
        modalCreated: data.created,
      });
    } else {
      try {
        const token = cookies.get('token');
        const res = await DashboardAPI.StoreAPI.placeAssociation(data.id, token);
        console.log(res);
        if (res.status === 200) {
          const dataPlaces = res.data.result;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < dataPlaces.length; i++) {
            dataPlaces[i].value = dataPlaces[i].placeId;
            dataPlaces[i].label = dataPlaces[i].placeName;
            delete dataPlaces[i].placeId;
          }
          this.setState({
            modalPlaces: true,
            modalName: data.name,
            modalCat: data.catId,
            modalImg: data.img,
            modalDesc: data.description,
            modalCreated: data.created,
            modalPlacesStoreId: data.id,
            modalPlacesList: dataPlaces,
            // eslint-disable-next-line react/no-unused-state
            modalPlacesListRaw: dataPlaces,
          });
        }
      } catch (error) {
        alert('There are some error, please try again');
        console.log(error);
      }
    }
  };

  handleChange = (type, data) => {
    this.setState({ [type]: data });
  };

  render() {
    const {
      modalVisible,
      modalId,
      modalName,
      modalCat,
      modalImg,
      modalDesc,
      modalCreated,
      modalCreateNewStore,
      modalPlaces,
      modalPlacesAdd,
      modalPlacesDelete,
      modalPlacesList,
      modalPlacesStoreId,
    } = this.state;
    const { storeLoading } = this.props;
    return (
      <Layout level1="store" level1Href="/store">
        <SpinnerOverlay loading={storeLoading} />
        <ModalStore
          modalVisible={modalVisible}
          onHide={() => this.setState({ modalVisible: false })}
          modalDataPassed={{
            modalId,
            modalName,
            modalCat,
            modalImg,
            modalDesc,
            modalCreated,
          }}
        />
        <ModalStore
          type="places"
          modalVisible={modalPlaces}
          onHide={() => this.setState({ modalPlaces: false })}
          modalDataPassed={{
            modalId: modalPlacesStoreId,
            modalName,
            modalCat,
            modalImg,
            modalDesc,
            modalCreated,
            modalPlacesAdd,
            modalPlacesDelete,
            modalPlacesList,
          }}
          listplace={this.props.globalState.listplace}
        />
        <ModalAddStore show={modalCreateNewStore} onHide={() => this.handleChange('modalCreateNewStore', false)} />
        <div className="row mt-4">
          <div className="col-md-12 col-xl-12 mb-0">
            <h2 className="h3 text-left font-weight-bold mb-2">Store</h2>
            <ButtonRegular style={{ position: 'absolute', top: 0, right: 150 }} onClick={() => this.props.fetchAllStore()}>
              Update Store list
            </ButtonRegular>
            <ButtonRegular style={{ position: 'absolute', top: 0, right: 20 }} onClick={() => this.handleChange('modalCreateNewStore', true)}>
              + Add new store
            </ButtonRegular>
          </div>
          <Content>
            <MDBDataTable
              striped
              bordered
              hover
              /* columns={data.columns}
                            rows={this.state.dataRows} */
              data={this.state.dataRows}
              responsive
            />
          </Content>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ store }) => {
  const { storeLoading, storeError, storeList, storeLastFetched, storeListWeb } = store;
  return { storeLoading, storeError, storeList, storeLastFetched, storeListWeb };
};
export default connect(mapStateToProps, { fetchAllStore, fetchAllStoreWeb })(AddStoreScreen);
