/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import qs from 'qs';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { MDBDataTable } from 'mdbreact';
import Cookies from 'universal-cookie';
import Moment from 'react-moment';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Button } from 'antd';
import Search from 'antd/lib/input/Search';
import { SpinnerComponent, SpinnerOverlay } from '../../../components/molecules/spinner';
import Layout from '../../../components/layout';
import { ButtonRegular } from '../../../components/button';
import Content from '../../../components/content';
import ModalDetailDiscount from './components/modalDetailDiscount';
import { fetchAllDiscount, fetchDiscountSearch } from '../../../redux/dispatchers/discountDispatcher';
import { setDiscountSearch } from '../../../redux/actions/discountActions';

const cookies = new Cookies();
const data = {
  columns: [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: 3,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, data) => {
        return (
          <div>
            <p className="text-left">{text}</p>
            <p className="font-weight-bold text-left" style={{ fontSize: 11 }}>
              {`url: /${data.data.url}`}
            </p>
          </div>
        );
      },
      width: 200,
    },
    {
      title: 'Short Name',
      dataIndex: 'shortName',
      key: 'shortName',
      width: 50,
    },
    {
      title: 'Store Name',
      dataIndex: 'storeName',
      key: 'storeName',
      width: 50,
    },
    {
      title: 'Featured',
      dataIndex: 'featured',
      key: 'featured',
      sorter: (a, b) => a.featured - b.featured,
      sortDirections: ['descend', 'ascend'],
      width: 30,
    },
    {
      title: 'Popular',
      dataIndex: 'popular',
      key: 'popular',
      sorter: (a, b) => a.popular - b.popular,
      sortDirections: ['descend', 'ascend'],
      width: 30,
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (text) => <Moment format="DD-MMM-YYYY">{text}</Moment>,
      sorter: (a, b) => a.created - b.created,
      sortDirections: ['descend', 'ascend'],

      label: 'Created',
      field: 'created',
      sort: 'asc',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text) => <Moment format="DD-MMM-YYYY">{text}</Moment>,
      sorter: (a, b) => a.endDate - b.endDate,
      sortDirections: ['descend', 'ascend'],

      label: 'End Date',
      field: 'endDate',
      sort: 'asc',
    },
    {
      title: 'View',
      dataIndex: 'send',
      key: 'send',
      render: (text, data) => (
        <Button type="primary" shape="round" onClick={() => data.openModal(data.data, 'discount')}>
          View
        </Button>
      ),

      label: 'View',
      field: 'send',
      sort: 'asc',
    },
  ],
};

class ListDiscountAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      catSelected: [],
      dataBrand: [],
      newBrand: [],
      modal: false,
      modalTitle: '',
      modalShortName: '',
      modalTitleFixed: '',
      modalContent: '',
      modalId: '',
      modalType: '',
      modalImg: '',
      modalTnc: '',
      dataRows: [],
      notifyToFollowers: false,
      notifyToAll: false,
      pagination: {
        current: 1,
        pageSize: 10,
      },
      // eslint-disable-next-line react/no-unused-state
      filters: {},
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  openModal = (data, type) => {
    this.setState({
      modal: true,
      modalTitle: data.name,
      modalTitleFixed: data.name,
      modalImg: data.img,
      modalContent: data.description,
      modalShortName: data.shortName,
      modalTnc: data.tnc,
      modalId: data.id,
      modalType: type,
    });
  };

  componentDidMount() {
    this.renderDiscount();
    const { discountSearchText = '' } = this.props;
    console.log({ discountSearchText });
  }

  componentDidUpdate = (prevProps) => {
    const { discountList } = this.props;
    if (prevProps.discountList !== discountList) {
      this.buildTable(discountList);
    }
  };

  renderDiscount = async () => {
    const { discountList = [] } = this.props;
    // eslint-disable-next-line no-empty
    if (discountList.length < 1) {
      this.props.fetchAllDiscount();
    }
    if (discountList.length > 0 && this.state.dataRows.length < 1) {
      this.buildTable(discountList);
    }
  };

  renderSearch = async (keyword) => {
    try {
      const res = await this.props.fetchDiscountSearch(keyword);
      if (keyword.length >= 3) {
        this.buildTable(res.data.result, true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // eslint-disable-next-line no-unused-vars
  buildTable = (dataDiscount = [], search = false) => {
    // eslintalert('build table');
    // eslint-disable-next-line prefer-const
    let result = [];
    if (dataDiscount.length > 0) {
      // eslint-disable-next-line array-callback-return
      dataDiscount.map((data, index) => {
        result.push({
          key: index,
          id: data.id,
          data,
          title: data.name,
          shortName: data.shortName || ` `,
          storeName: data.storeName || ` `,
          featured: data.IsFeatured === 1 ? 'Yes' : ` `,
          popular: data.IsPopular === 1 ? 'Yes' : ` `,
          created: data.created || ` `,
          endDate: data.end || ` `,
          openModal: this.openModal,
        });
      });
      const resulttopush = {
        columns: data.columns,
        rows: result,
      };
      this.setState({
        dataRows: resulttopush,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      });
      this.setState({
        loading: false,
      });
    }
  };

  onSubmit(event) {
    event.preventDefault();
    console.log(this.state.dataBrand);
    console.log(this.state.newBrand);
    for (let i = 0; i < this.state.newBrand.length; i++) {
      if (this.state.dataBrand.find((x) => x.label === this.state.newBrand[i].label)) {
        console.log(`${this.state.newBrand[i].label} exist`);
      } else {
        const headers = new Headers({
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Access-Control-Allow-Origins': 'http://localhost:3000',
          'Content-Type': 'application/json',
        });
        const data = qs.stringify({
          storeName: this.state.dataBrand[i].label,
          cat: this.state.catSelected,
        });
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        axios
          .post(`${BASE_URL}api/addstore`, data, { headers })
          .then(function (response) {
            console.log(response);
            if (response.data.message === 'Success') {
              alert('Berhasil menambahkan store');
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }

  handleChange = (event, type) => {
    console.log('EVENT', event);
    this.setState({
      [type]: event,
    });
  };

  sendNotification = (data) => {
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Basic NWI3MWViMjAtZTFjYS00ZWI5LTg4YzEtMWI3MzA0ZGNhY2Rl',
    };

    const options = {
      host: 'onesignal.com',
      port: 443,
      path: '/api/v1/notifications',
      method: 'POST',
      headers,
    };

    // eslint-disable-next-line global-require
    const https = require('https');
    const req = https.request(options, function (res) {
      res.on('data', function (data) {
        console.log('Response:');
        console.log(JSON.parse(data));
        const dataRes = JSON.parse(data);
        if (dataRes.errors) {
          alert(dataRes.errors[0]);
        } else {
          alert(`Successfully sent to ${dataRes.recipients}`);
        }
      });
    });

    this.setState({ loading: false });

    req.on('error', function (e) {
      console.log('ERROR:');
      console.log(e);
    });
    req.write(JSON.stringify(data));
    req.end();
  };

  handleSendNotif = () => {
    const token = cookies.get('token');
    if (this.state.notifyToAll === true) {
      this.setState({ loading: true });
      // eslint-disable-next-line no-unused-vars
      const headers = new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Basic NWI3MWViMjAtZTFjYS00ZWI5LTg4YzEtMWI3MzA0ZGNhY2Rl',
      });

      if (this.state.notifyToAll === true) {
        const data = {
          app_id: '942046ea-d755-4f5c-9384-ea20410a0fb9',
          included_segments: ['All'],
          data: { type: this.state.modalType, id: this.state.modalId, notif: 'true', title: this.state.modalTitleFixed },
          big_picture: this.state.modalImg,
          large_icon: this.state.modalImg,
          headings: { en: this.state.modalTitle },
          contents: { en: this.state.modalContent },
        };
        this.sendNotification(data);
      }
    }
    if (this.state.notifyToFollowers === true) {
      const header = {
        Authorization: `Bearer ${token}`,
      };
      // eslint-disable-next-line no-unused-vars
      const data = {
        storeId: 0,
      };
      const BASE_URL = process.env.REACT_APP_BASE_URL;
      axios
        .get(`${BASE_URL}dashboard/storefollowers`, { headers: header })
        .then((res) => {
          console.log(res.data.result);
          const dataFollowers = {
            app_id: '942046ea-d755-4f5c-9384-ea20410a0fb9',
            include_external_ids: [res.data.result],
            data: {
              type: this.state.modalType,
              id: this.state.modalId,
              notif: 'true',
              title: this.state.modalTitleFixed,
            },
            big_picture: this.state.modalImg,
            large_icon: this.state.modalImg,
            headings: { en: this.state.modalTitle },
            contents: { en: this.state.modalContent },
          };
          console.log(dataFollowers);
        })
        .catch(function (error) {
          console.log(error);
        });
      /*  let dataFollowers= {
                app_id: '942046ea-d755-4f5c-9384-ea20410a0fb9',
                included_segments: [ 'Test' ],
                data: { type: this.state.modalType,
                    id: this.state.modalId,
                    notif: 'true',
                    title: this.state.modalTitleFixed },
                big_picture: this.state.modalImg,
                large_icon: this.state.modalImg,
                headings: { en: this.state.modalTitle },
                contents: { en: this.state.modalContent}
            }
            this.sendNotification(dataFollowers); */
    }
  };

  // eslint-disable-next-line no-unused-vars
  handleTableChange = (pagination, filters) => {
    // eslint-disable-next-line no-unused-vars
    const pager = { ...this.state.pagination };
    // if filters not changed, don't update pagination.current
    /* const filtersChanged = filters !== this.state.filters;
    if (!filtersChanged) {
      pager.current = pagination.current;
    } */
    this.setState(
      {
        pagination,
        // filters,
      },
      () => {}
    );
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { discountLoading, discountSearchLoading, discountSearchText } = this.props;
    const { modalId, loading, dataRows, pagination } = this.state;
    return (
      <Layout level1="discount" level1Href="/discount">
        <SpinnerOverlay loading={loading || discountLoading} />
        <div className="view" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: 10 }}>
          <ModalDetailDiscount
            visible={this.state.modal}
            onHide={() => this.handleChange(false, 'modal')}
            title={this.state.modalTitle}
            shortName={this.state.modalShortName}
            content={this.state.modalContent}
            tnc={this.state.modalTnc}
            id={modalId}
          />
          <div className="row mt-3">
            <div className="col-md-12 col-xl-12 mb-0">
              <h2 className="h3 text-left font-weight-bold mb-2">Discount & Promo</h2>
              <Link to="/dashboard/discount/submit">
                <ButtonRegular style={{ position: 'absolute', top: 0, right: 20 }}>+ Add new discount</ButtonRegular>
              </Link>
            </div>
            <Content>
              <SpinnerComponent loading={discountSearchLoading} />
              <Search
                className="d-flex mb-2"
                placeholder="Cari disini"
                onSearch={(value) => this.renderSearch(value)}
                style={{ width: 200 }}
                enterButton
              />
              <Table
                bordered
                className="thead-solid table-striped-rows"
                responsive
                columns={dataRows.columns}
                dataSource={dataRows.rows}
                pagination={pagination}
                onChange={this.handleTableChange}
                size="middle"
              />
            </Content>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ discount }) => {
  const { discountLoading, discountList, discountLastFetched, discountSearchText, discountSearchLoading, discountSearchList } = discount;
  return { discountLoading, discountList, discountLastFetched, discountSearchText, discountSearchLoading, discountSearchList };
};
export default connect(mapStateToProps, { setDiscountSearch, fetchDiscountSearch, fetchAllDiscount })(withRouter(ListDiscountAdmin));
