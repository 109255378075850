import React from 'react';
import { Spinner } from 'react-bootstrap';

export const SpinnerComponent = ({ loading = false }) => {
  if (!loading) return null;
  return (
    <Spinner animation="border" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  );
};

export const SpinnerOverlay = ({ loading = false }) => {
  if (!loading) return null;
  return <div className="loading">Loading</div>;
};
