import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

// eslint-disable-next-line no-unused-vars
async function partnerList(token, limit = 200) {
  const url = `${BASE_URL}dashboard/coupon/partner`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'GET',
    url,
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

// eslint-disable-next-line no-unused-vars
async function sourceList(token, limit = 200) {
  const url = `${BASE_URL}dashboard/coupon/source`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'GET',
    url,
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function sourceAdd(token, data) {
  const url = `${BASE_URL}dashboard/coupon/source`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'POST',
    url,
    data,
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function sourceUpdate(token, data) {
  const url = `${BASE_URL}dashboard/coupon/source`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'PATCH',
    url,
    data,
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function couponList(token, limit = 200) {
  const url = `${BASE_URL}dashboard/coupon/`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'GET',
    url,
    params: {
      limit,
    },
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function couponAdd(token, data) {
  const url = `${BASE_URL}dashboard/coupon/`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'POST',
    url,
    data: {
      data,
    },
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function couponUpdate(token, data) {
  const url = `${BASE_URL}dashboard/coupon/`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'PATCH',
    url,
    data: {
      data,
    },
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function couponSetInactive(token, data) {
  const url = `${BASE_URL}dashboard/coupon/inactive`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'PATCH',
    url,
    data: {
      data,
    },
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

const couponAPI = {
  partnerList,
  sourceList,
  sourceAdd,
  sourceUpdate,
  couponList,
  couponAdd,
  couponUpdate,
  couponSetInactive,
};

export default couponAPI;
