import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

function ModalDetailDiscount(props) {
  const { visible, onHide = () => null, title, shortName, content, tnc, id } = props;
  return (
    <Modal show={visible} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ marginTop: 20 }}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            <span style={{ fontWeight: 'bold', color: '#000' }}>Title:</span> {title}
          </p>
          <p>
            <span style={{ fontWeight: 'bold', color: shortName ? '#000' : 'red' }}>Short Title:</span> {shortName}
          </p>
          <hr />
          <p style={{ fontWeight: 'bold', color: '#000' }}>Description</p>
          <hr />
          <div dangerouslySetInnerHTML={{ __html: content }} style={{ maxHeight: 200, overflowY: 'scroll' }} />
          <hr />
          <p style={{ fontWeight: 'bold', color: tnc ? '#000' : 'red' }}>Terms and Condition</p>
          <hr />
          <div
            dangerouslySetInnerHTML={{ __html: `${tnc} <style>img { max-width: 200px; } p { font-size: 11px }</style>` }}
            style={{ maxHeight: 200, overflowY: 'scroll' }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.history.push(`/dashboard/edit-discount/${id}`)} variant="info">
          Edit
        </Button>
        <Link target="_blank" to={`/dashboard/edit-discount/${id}`}>
          <Button variant="info">Edit new tab</Button>
        </Link>
        <Button onClick={onHide} variant="danger">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default withRouter(ModalDetailDiscount);
