/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { hot } from 'react-hot-loader';
import Header from './screen/header';
import FooterScreen from './screen/footer';
import LoginBrandScreen from './screen/loginbrand';

import DashboardScreen from './screen/dashboard/dashboard';
import { Authenticated } from './service/auth';
import 'babel-polyfill';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@fortawesome/fontawesome-free/css/all.min.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'antd/dist/antd.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      /* token: cookies.get('token') || '' */
    };
  }

  addData = (val) => {
    this.setState((prevState) => ({
      data: [...prevState.data, val],
    }));
    // console.log(this.state.data)
  };

  render() {
    return (
      <Router props={this.state.data}>
        <div className="App" style={{ height: '100%' }}>
          <Header />
          <Switch>
            {/*  <Route path="/" exact component={() => <HomeScreen addData={this.addData} data={this.state.data}/>} />
            <Route path="/contact/" component={() => <PayLendScreen  data={this.state.data}/>} />
            <Route path="/submit-discount/" component={() => <AddDiscountScreen  data={this.state.data}/>} />
            <Route path="/submit-store-in-place/" component={() => <AddPlaceStoreScreen  data={this.state.data}/>} />
            <Route path="/confirmationemail/:id/:username" component={() => <ConfirmationEmailScreen  data={this.state.data}/>} /> */}
            <Route path="/login-brand/" component={() => <LoginBrandScreen data={this.state.data} />} />
            <Route
              path="/"
              /* onEnter={this.requireAuth} */ data="test"
              component={Authenticated(withRouter((props) => <DashboardScreen {...props} />))}
            />
            <Route
              path="/dashboard/"
              /* onEnter={this.requireAuth} */ data="test"
              component={Authenticated(withRouter((props) => <DashboardScreen {...props} />))}
            />
            <Route component={LoginBrandScreen} />
          </Switch>
          <FooterScreen />
        </div>
      </Router>
    );
  }
}

export default hot(module)(withCookies(App));
