/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable camelcase */
import moment from 'moment';
import axios from 'axios';
import Cookies from 'universal-cookie';
import converter from './converter';

const cookies = new Cookies();

const request = require('request');
const cheerio = require('cheerio');

const type = [
  { value: 1, label: 'Diskon' },
  { value: 2, label: 'Cashback' },
  { value: 3, label: 'Promosi' },
  { value: 4, label: 'Bonus' },
];

const payment = [
  { value: 169, label: 'Gopay' },
  { value: 171, label: 'OVO' },
  { value: 170, label: 'Dana' },
  { value: 605, label: 'LinkAja' },
  { value: 589, label: 'Kartu Debit' },
  { value: 588, label: 'Kartu Kredit' },
];

function crawlSite(fetchUrl, brandSelected, catSelected, setState, onlyDescAndImg = false) {
  if (fetchUrl) {
    setState('loading', true);
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0;

    /* xhr.open("GET",'https://cros-proxy-indo.herokuapp.com/'); // assuming you’re hosting it locally
        xhr.setRequestHeader("Content-type", 'application/json');
        let dataaa = {
        headers: {
            Accept: "application/json",
            Origin: "http://katalogpromosi.com",
            'Target-URL': "http://google.com"
        },
        method: 'GET'
        };
        xhr.send(JSON.stringify(dataaa)); */

    /* axios.get('https://cros-proxy-indo.herokuapp.com/', 
            { 
                headers: 
                { 
                    'Target-URL' : 'http://katalogpromosi.com/department-store/metro-department-store-promo-september-beauty-highlight-get-voucher-up-to-rp-100-000-with-minimum-purchase', 
                    'Access-Control-Allow-Origin': '*',
                    'Authorization' :'none'
                }
        })
        .then( res => {
            console.log(res)
        })
        .catch( err => {
            console.log(err)
        }) */

    request(
      {
        uri: fetchUrl,
        strictSSL: false,
        no_proxy: true,
        headers: {
          'Target-URL': fetchUrl,
          'Access-Control-Allow-Origin': '*',
          Authorization: 'none',
        },

        timeout: 10000,
      },
      async (error, response, html) => {
        // console.log(error, response, html)
        if (!error && response.statusCode === 200) {
          const $ = cheerio.load(html);
          const parsedResults = [];
          let desc = '';
          let title;
          let startDate;
          let endDate;

          /** ********************** TITLE  ***************** */
          const domain = fetchUrl.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
          console.log('SUCCESS 1');
          // console.log('LOGGG', $('.td-pb-padding-side .entry-title'))
          if (domain === 'katalogpromosi.com') {
            title = $('#main .entry-title')[0]?.firstChild?.data ?? '';
          } else if (domain === 'sipromo.co.id') {
            if ($('.container_front div div .col+ .col > div:nth-child(1)').length > 0)
              title = $('.container_front div div .col+ .col > div:nth-child(1)')[0]
                .firstChild.data.replace('                            ', '')
                .replace('                        ', '');
            else title = '';
            if ($('.loader+ div .tablecell img')[0].attribs) {
              setState('uploadWithUrl', true);
              const imageUri = await $('.loader+ div .tablecell img')[0].attribs.src;
              setState('imgUrl', imageUri);
              document.getElementById('imgUrl').value = imageUri;
            }
          } else if (domain === 'www.giladiskon.com') {
            title = $('.pt-md-0 h5')[0].firstChild.data;
            // eslint-disable-next-line no-irregular-whitespace
            if ($('.col-md-6 .img-fluid')[0].attribs) {
              setState('uploadWithUrl', true);
              const imageUri = `${$('.col-md-6 .img-fluid')[0].attribs.src}`;
              setState('imgUrl', imageUri);
              document.getElementById('imgUrl').value = imageUri;
            }
          } else if (domain === 'instagram.com' || domain === 'www.instagram.com') {
            setState('uploadWithUrl', true);
            alert('Crawling on instagram still in testing, please report if you face any issue');
            const endWith = fetchUrl.endsWith('/');
            const url = !endWith ? '/?__a=1' : '?__a=1';
            const cookieHeader = cookies.get('igcookie') || '';
            const res = await axios.get(`${fetchUrl}${url}`, { headers: { cookie: cookieHeader } });
            if (res.status === 200) {
              console.log('RESS', res.data.graphql.shortcode_media);
              const { edge_sidecar_to_children = {}, edge_media_to_caption = {} } = res.data.graphql.shortcode_media;
              const { edges = [] } = edge_sidecar_to_children;
              if (edges.length > 1) {
                edges.map((a, i) => {
                  if (i !== 0) {
                    desc = desc.concat(`<img src="${a.node.display_url}" />`);
                  }
                  return true;
                });
              }
              desc = desc.concat(edge_media_to_caption.edges[0].node.text.replace(/(?:\r\n|\r|\n)/g, '</br>') || null);
              const imageUri = res.data.graphql.shortcode_media.display_url;
              setState('imgUrl', imageUri);
              document.getElementById('imgUrl').value = imageUri;
              const dateFromDesc = await converter.startConvert(desc);
              if (dateFromDesc.length === 1) {
                console.log('dateFromDesc', dateFromDesc, dateFromDesc.length, dateFromDesc[0].endDateFormat);
                if (dateFromDesc[0].end !== undefined) {
                  const endDate = dateFromDesc[0].endDateFormat;
                  moment(endDate || '').isValid() && setState('endDate', endDate);
                }
              } else if (dateFromDesc.length === 2) {
                console.log('dateFromDesc', dateFromDesc.length);
                moment(dateFromDesc[0].endDateFormat || '').isValid() && setState('endDate', dateFromDesc[0].endDateFormat);
                moment(dateFromDesc[0].startDateFormat || '').isValid() && setState('startDate', dateFromDesc[0].startDateFormat);
              }
            }
          }
          // CHECK IF TITLE HAS WORDS PROMO / DISCOUNT / CASHBACK AND HAS GOPAY / OVO / DANA
          let result;
          if (title) {
            type.map((res) => {
              if (res.label) {
                if (!result && title.toLowerCase().includes(res.label.toLowerCase())) {
                  console.log(title.toLowerCase().includes(res));
                  result = res;
                  setState('typeSelected', result);
                }
              }
            });
            const paymentTemp = [];
            payment.map((res) => {
              if (res.label) {
                if (title.toLowerCase().includes(res.label.toLowerCase())) {
                  paymentTemp.push(res);
                }
              }
            });
            if (paymentTemp.length > 0) {
              setState('brandSelected', brandSelected.concat(paymentTemp));
              setState('catSelected', catSelected.concat([{ value: 14, label: 'Bank dan E-Money' }]));
            }
          }
          /** ********************** END TITLE  ***************** */

          /** ********************** DATE  ***************** */
          if (domain === 'sipromo.co.id') {
            console.log('LO DATEE', $('div b'), $('div b')[1].firstChild.data);
            startDate = await moment(moment($('div b')[1].firstChild.data).format('DD/MM/YYYY'), 'DD/MM/YYYY').toDate();
            endDate = await moment(moment($('div b')[2].firstChild.data).format('DD/MM/YYYY'), 'DD/MM/YYYY').toDate();
            setState('startDate', startDate);
            setState('endDate', endDate);
          }
          if (domain === 'katalogpromosi.com') {
            console.log('LO DATEE', $('div b'), $('div b')[1].firstChild.data);
            startDate = await moment(moment($('.updated font')[0]?.firstChild?.data ?? null).format('DD/MM/YYYY'), 'DD/MM/YYYY').toDate();
            endDate = await moment(moment($('.updated+ .updated b')[0]?.firstChild?.data ?? null).format('DD/MM/YYYY'), 'DD/MM/YYYY').toDate();
            // eslint-disable-next-line no-unused-expressions
            moment(startDate).isValid() && setState('startDate', startDate);
            // eslint-disable-next-line no-unused-expressions
            moment(endDate).isValid() && setState('endDate', endDate);
          }
          if (domain === 'www.giladiskon.com') {
            // console.log('LO DATEE', moment($('.expired')[0].firstChild.next.data.replace(`  Hingga `, '')));
            // eslint-disable-next-line no-irregular-whitespace
            // startDate = await moment(moment($('.start')[0].firstChild.next.data.replace(`  Mulai `, '')).format('DD/MM/YYYY'), 'DD/MM/YYYY').toDate();
            const endDateDom = $('.text-gd-primary.gd-font-sm')[0].firstChild.data;
            if (endDateDom) {
              endDate = await moment(
                moment($('.text-gd-primary.gd-font-sm')[0].firstChild.data.replace(`Berlaku Hingga : `, '')).format('DD/MM/YYYY'),
                'DD/MM/YYYY'
              ).toDate();
              // setState('startDate', startDate);
              setState('endDate', endDate);
            }
          }
          !onlyDescAndImg && (await setState('name', title));

          /** ************ START FETCH DESC  ******************** */
          if (domain === 'katalogpromosi.com') {
            const data = $('.entry-content');
            let start = 0;
            let end = false;
            $('.style-one~ p')
              .children()
              .each(function (i, element) {
                console.log('ELEMENT', element, $(element)[0].name !== 'script');
                if (i >= 2 && $(element)[0].name !== 'script') {
                  // console.log('3', $(element)[0])
                  if ($(element)[0].name === 'div' && $(element)[0].attribs.align === 'center') {
                    end = true;
                  } else if (end === false) {
                    desc = desc.concat($.html(element));
                  }
                }
                if ($(element)[0].name === 'hr') {
                  start += 1;
                }
              });
          }
          if (domain === 'sipromo.co.id') {
            // desc = $('.content_desc')[0].firstChild.data.concat($('.content_desc_tnc')[1].firstChild.data)
            console.log('DESC', $('.content_desc_tnc')[1]);
            desc = $('.content_desc').html().replace('                            ', '');
            if ($('.content_desc_tnc').length > 1) {
              let tnc = '';
              $('.content_desc_tnc')[1].children.map((element, i) => {
                tnc = tnc.concat($.html(element));
              });
              setState('tnc', tnc);
            }
          }
          if (domain === 'www.giladiskon.com') {
            // desc = $('.content_desc')[0].firstChild.data.concat($('.content_desc_tnc')[1].firstChild.data)
            desc = $('.gd-font-sm > p').html();
            /*  desc = $('.content_desc').html().replace('                            ', '');
            if ($('.content_desc_tnc').length > 1) {
              let tnc = '';
              $('.content_desc_tnc')[1].children.map((element, i) => {
                tnc = tnc.concat($.html(element));
              });
              setState('tnc', tnc);
            } */
          }
          await setState('description', desc);
          setState('loading', false);
        } else {
          console.log('ERROR', error, response);
          alert(JSON.stringify(error));
          setState('loading', false);
        }
      }
    );
  } else {
    alert('Filled the url');
  }
}

function formatDate(date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

function truncate(str, no_words) {
  return str.split(' ').splice(0, no_words).join(' ');
}

function removeArray(arr) {
  let what;
  // eslint-disable-next-line prefer-rest-params
  const a = arguments;
  let L = a.length;
  let ax;
  while (L > 1 && arr.length) {
    // eslint-disable-next-line no-plusplus
    what = a[--L];
    // eslint-disable-next-line no-cond-assign
    while ((ax = arr.indexOf(what)) !== -1) {
      arr.splice(ax, 1);
    }
  }
  return arr;
}

function debounce(fn, wait) {
  let timeout;
  return function () {
    clearTimeout(timeout);
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    timeout = setTimeout(function () {
      fn.apply(this, args);
    }, wait || 1);
  };
}

const DashboardFunction = {
  crawlSite,
  formatDate,
  truncate,
  removeArray,
  debounce,
};

export default DashboardFunction;
