import {
  FETCH_LATEST_PROMO,
  FETCH_LATEST_PROMO_FAILED,
  FETCH_LATEST_PROMO_SUCCESS,
  FETCH_SEARCH_PROMO,
  FETCH_SEARCH_PROMO_FAILED,
  FETCH_SEARCH_PROMO_SUCCESS,
  REMOVE_PROMO_SUCCESS,
} from '../actions/thirdPartyactions';

const storeInitialState = {
  thirdPartyPromoLoading: false,
  thirdPartyPromoError: null,
  thirdPartyPromo: [],
  thirdPartyPromoHot: [],
  thirdPartyPromoSearch: [],

  thirdPartyLastFetched: null,
};

export default (state = storeInitialState, action) => {
  switch (action.type) {
    case FETCH_LATEST_PROMO:
      return { ...state, thirdPartyPromoLoading: true, thirdPartyPromoError: null };
    case FETCH_LATEST_PROMO_SUCCESS:
      return { ...state, thirdPartyPromoLoading: false, thirdPartyPromoError: null, thirdPartyPromo: action.payload, storeLastFetched: action.date };
    case FETCH_LATEST_PROMO_FAILED:
      return { ...state, thirdPartyPromoLoading: false, thirdPartyPromoError: action.payload };
    case FETCH_SEARCH_PROMO:
      return { ...state, thirdPartyPromoLoading: true, thirdPartyPromoError: null };
    case FETCH_SEARCH_PROMO_SUCCESS:
      return { ...state, thirdPartyPromoLoading: false, thirdPartyPromoError: null, thirdPartyPromo: action.payload, storeLastFetched: action.date };
    case FETCH_SEARCH_PROMO_FAILED:
      return { ...state, thirdPartyPromoLoading: false, thirdPartyPromoError: action.payload };
    case REMOVE_PROMO_SUCCESS:
      return { ...state, thirdPartyPromoLoading: false, thirdPartyPromo: action.payload };
    default:
      return state;
  }
};
