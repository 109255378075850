/* eslint-disable no-shadow */
import React from 'react';
import { Modal, Table, Button } from 'react-bootstrap';
import Moment from 'react-moment';

const BrandDiscountModal = ({ visible, data, hide }) => {
  return (
    <Modal show={visible} onHide={hide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">List Discount Brands</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Title</th>
              <th>Valid Until?</th>
              <th>Brand</th>
              <th>Expired?</th>
            </tr>
          </thead>
          <tbody>
            {data.map((data, index) => {
              return (
                <tr key={data.id}>
                  <td>{index}</td>
                  <td>
                    <img src={data.img} alt={data.name} width="100px" />
                  </td>
                  <td>{data.name}</td>
                  <td>
                    <Moment format="DD-MMM-YY">{data.end}</Moment>
                  </td>
                  <td>{data.storeName}</td>
                  <td style={{ color: '#d21e1e', fontWeight: 'bold' }}>{data.DateDiff < 0 ? 'Expired' : ''}</td>
                  <td>{data.id}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BrandDiscountModal;
