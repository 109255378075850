/* eslint-disable react/no-unused-state */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
// eslint-disable-next-line import/order
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import qs from 'qs';
import { Modal, Button, Table } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import ReactQuill from 'react-quill';
import Moment from 'react-moment';
import moment from 'moment';
import $ from 'jquery';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DashboardAPI from '../../service/api';
import DashboardFunction from '../../service/function';
import CheckboxComponent from '../../components/form/checkbox';
import SelectComponent from '../../components/form/select';
import Layout from '../../components/layout';
import Content from '../../components/content';
import { fetchAllStore } from '../../redux/dispatchers/storeDispatcher';
import { fetchAllPlaces } from '../../redux/dispatchers/placeDispatcher';
import { removeAmpInDesc } from '../../service/prePostDiscount';
import ModalNotificationV2 from '../../components/modal/modal-noficationv2';

const cookies = new Cookies();

const cat = [
  { value: 1, label: 'Makanan' },
  { value: 2, label: 'Fashion' },
  { value: 3, label: 'Hiburan' },
  { value: 4, label: 'Travel' },
  { value: 5, label: 'Elektronik' },
  { value: 6, label: 'Kecantikan' },
  { value: 7, label: 'Otomotif' },
  { value: 8, label: 'Kesehatan' },
  { value: 9, label: 'Peliharaan' },
  { value: 10, label: 'Layanan' },
  { value: 11, label: 'Katalog' },
  { value: 12, label: 'Online' },
  { value: 13, label: 'Transportasi' },
  { value: 14, label: 'Bank dan E-Money' },
  { value: 15, label: 'Marketplace' },
  { value: 16, label: 'Hobi' },
  { value: 17, label: 'Event' },
];

const type = [
  { value: 1, label: 'Diskon' },
  { value: 2, label: 'Cashback' },
  { value: 3, label: 'Promosi' },
  { value: 4, label: 'Bonus' },
];

const BrandDiscountModal = ({ data, hide }) => {
  const { brandDiscountList = [] } = data;
  return (
    <Modal show={data.brandDiscountPopUp} onHide={hide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">List Discount Brands</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Title</th>
              <th>Valid Until?</th>
              <th>Brand</th>
              <th>Expired?</th>
            </tr>
          </thead>
          <tbody>
            {brandDiscountList.map((item, index) => {
              return (
                <tr>
                  <td>{index}</td>
                  <td>
                    <img src={item.img} alt={item.name} width="100px" />
                  </td>
                  <td>{item.name}</td>
                  <td>
                    <Moment format="DD-MMM-YY">{item.end}</Moment>
                  </td>
                  <td>{item.storeName}</td>
                  <td style={{ color: '#d21e1e', fontWeight: 'bold' }}>{item.DateDiff < 0 ? 'Expired' : ''}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

class EditDiscountScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
      name: '',
      shortName: '',
      description: '',
      tnc: '',
      link: '',
      brandSelected: [],
      typeSelected: [],
      catSelected: [],
      loading: false,
      notifyToAll: false,
      notifyToFollowers: false,
      uploadWithUrl: false,
      imgUrl: '',
      isPopular: 0,
      isFeatured: 0,
      notify: 0,
      dataTemp: [],
      typeSelectedTemp: [],
      brandSelectedTemp: [],
      catSelectedTemp: [],

      titleChange: false,
      shortChange: false,
      startChange: false,
      endChange: false,
      descChange: false,
      tncChange: false,
      brandChange: false,
      catChange: false,
      typeChange: false,
      popularChange: false,
      featuredChange: false,
      imgChange: false,
      pushToTop: false,

      editDate: false,
      dateNotConfirmed: false,
      onlySelectedPlace: false,
      onlySelectedPlaceData: [],

      modalNotif: false,
      modalTitle: '',
      modalTitleFixed: '',
      modalContent: '',
      modalId: '',
      modalType: '',
      modalImg: '',
      modalSelectedBrand: [],
      brandFollowers: [],

      fetchUrl: '',
      crawl: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.renderBrand = this.renderBrand.bind(this);
    this.onSubmitWithUrl = this.onSubmitWithUrl.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
  }

  componentDidMount() {
    DashboardFunction.debounce(this.renderBrand(), 500);
  }

  renderBrand = async () => {
    this.setState({ loading: true });
    const { updateGlobal, globalState } = this.props;
    const { liststore = [] } = globalState;
    if (globalState.loadingGlobal && liststore.length < 1) {
      return;
    }

    if (liststore.length < 1) {
      try {
        const token = cookies.get('token');
        const res = await DashboardAPI.StoreAPI.storeList(token, 4000);
        const { result = [], status } = res.data;
        if (status === true) {
          const newRes = result.map((a) => ({
            ...a,
            label: a.name,
            value: a.id,
          }));
          updateGlobal('liststore', newRes);
          DashboardFunction.debounce(this.loadDiscountData(), 300);
        } else {
          throw new Error('Failed to load discount');
        }
      } catch (error) {
        alert('Terjadi kesalaahn, silahkan cek internet anda atau coba beberapa saat lagi');
        this.setState({ loading: false });
        console.log(error);
      }
    } else {
      this.setState({ loading: false });
      DashboardFunction.debounce(this.loadDiscountData(), 300);
    }
  };

  // eslint-disable-next-line no-shadow
  handleChange = (type, event) => {
    console.log(event);
    this.setState({
      [type]: event,
    });
  };

  handleChangeDate = (event) => {
    this.setState({
      startDate: event,
    });
  };

  handleChangeDate2 = (event) => {
    this.setState({
      endDate: event,
    });
  };

  handleChangeOpt = (selectedOption) => {
    this.setState({ brandSelected: selectedOption });
    console.log(`Option selected:`, selectedOption, this.state.brandSelected);
  };

  handleChangeOpt2 = (selectedOption) => {
    this.setState({ typeSelected: selectedOption });
    console.log(`Option selected:`, selectedOption);
  };

  handleChangeOpt3 = (selectedOption) => {
    console.log(selectedOption);
    this.setState({ catSelected: selectedOption });
    console.log(`Option selected:`, selectedOption);
  };

  onSubmitWithUrl = async (event) => {
    event.preventDefault();
    if (this.state.notifyToAll === true) {
      alert('true');
    }
    const token = cookies.get('token');
    const header = {
      Authorization: `Bearer ${token}`,
      /* 'uploadWithUrl' : this.state.uploadWithUrl */
    };
    this.setState({ loading: true });
    const isAllow = await this.checkWhatChanges();
    if (/* this.state.uploadWithUrl &&  */ isAllow) {
      if (this.state.brandSelected.length > 0 && this.state.catSelected.length > 0 && this.state.typeSelected) {
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        axios({
          method: 'post',
          url: `${BASE_URL}dashboard/updatediscount`,
          data: qs.stringify({
            discountId: this.props.match.params.id,
            imgOld: this.state.dataTemp.img,

            name: this.state.name,
            shortName: this.state.shortName,
            typeId: this.state.type,
            description: removeAmpInDesc(this.state.description),
            tnc: this.state.tnc,
            imgUrl: this.state.imgUrl,
            link: this.state.link ? this.state.link : 'NULL',
            cat: this.state.catSelected.map((data) => data.value),
            store: this.state.brandSelected.map((data) => data.value),
            type: this.state.typeSelected.value,
            isFeatured: document.getElementById('isFeatured').checked === true ? 'on' : 'off',
            isPopular: document.getElementById('isPopular').checked === true ? 'on' : 'off',
            startDate: DashboardFunction.formatDate(this.state.startDate),
            endDate: DashboardFunction.formatDate(this.state.endDate),

            titleChange: this.state.titleChange,
            shortChange: this.state.shortChange,
            startChange: this.state.startChange,
            endChange: this.state.endChange,
            descChange: this.state.descChange,
            tncChange: this.state.tncChange,
            brandChange: this.state.brandChange,
            catChange: this.state.catChange,
            typeChange: this.state.typeChange,
            popularChange: this.state.popularChange,
            featuredChange: this.state.featuredChange,
            imgChange: this.state.imgChange,
            pushToTop: this.state.pushToTop === true ? 1 : '',
          }),
          headers: header,
        })
          .then((response) => {
            if (this.state.notify) {
              console.log('lolll', this.state.notify);
              this.checkNotif(this.props.match.params.id);
            } else if (response.data.status === true) {
              this.props.history.goBack();
            }
            this.handleLoading();
          })
          .catch((error) => {
            this.handleLoading();
            if (error) {
              alert('Gagal, Silahkan Coba lagi');
              console.log('ERROR', error);
            }
          });
      } else {
        this.handleLoading();
        alert('Pastikan sudah pilih brand, kategori & tipe');
      }
    } else {
      this.handleLoading();
    }
  };

  checkNotif = async (id) => {
    const data = qs.stringify({
      id,
    });
    try {
      const BASE_URL = process.env.REACT_APP_BASE_URL;
      const res = await axios.post(`${BASE_URL}api/discountdetail`, data);
      if (res.data.status === true) {
        this.setState({
          modalNotif: true,
          modalTitle: res.data.data[0].name,
          modalTitleFixed: res.data.data[0].name,
          modalContent: $(`<p>${DashboardFunction.truncate(res.data.data[0].description, 20)}</p>`).text(),
          modalId: id,
          modalType: 'discount',
          modalImg: res.data.data[0].img,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleLoading = () => {
    this.setState({ loading: false });
  };

  checkBrandDiscount = async () => {
    // eslint-disable-next-line camelcase
    const result_array = [];
    this.state.brandSelected.map((data) => {
      result_array.push(data.value.toString());
    });
    const token = cookies.get('token');
    const listBrand = result_array.toString().split(',').join("','");
    try {
      const response = await DashboardAPI.DiscountAPI.multipleBrandDiscount(token, listBrand);
      console.log('response', response);
      if (response.data.status === true) {
        this.setState({ brandDiscountList: response.data.result });
        this.setState({ brandDiscountPopUp: true });
      } else {
        alert('Terjadi kesalaahn, silahkan cek internet anda atau coba beberapa saat lagi');
      }
    } catch (error) {
      console.log(error);
      alert('Terjadi kesalaahn, silahkan cek internet anda atau coba beberapa saat lagi');
    }
  };

  loadDiscountData = async () => {
    const token = cookies.get('token');
    try {
      this.setState({ loading: true });
      const response = await DashboardAPI.DiscountAPI.detail(token, this.props.match.params.id);
      if (response.data.status === true && !this.state.name) {
        const dataTemp = response?.data?.result[0] || {};
        const {
          name,
          IsFeatured,
          IsPopular,
          description,
          discountCat,
          end,
          img,
          shortName,
          start,
          storeName,
          tnc,
          typeId,
          dateNotConfirmed,
        } = dataTemp;

        let disCategories = discountCat;
        disCategories = `[${disCategories}]`;
        disCategories = JSON.parse(disCategories);

        const catSelected = [];
        disCategories.map((item) => {
          catSelected.push(cat.filter((data) => data.value === item)[0]);
        });

        let brandLists = storeName;
        brandLists = `[${brandLists}]`;
        brandLists = JSON.parse(brandLists);

        const brandSelected = [];
        const { liststore = [] } = this.props.globalState;
        brandLists.map((item) => {
          brandSelected.push(liststore.filter((data) => data.value === item)[0]);
        });
        setTimeout(() => {
          if (!this.state.description) {
            const startDate = start === '0000-00-00 00:00:00' ? null : start;
            const endDate = end === '0000-00-00 00:00:00' ? null : end;
            this.setState({
              dataTemp,
              name,
              shortName,
              description,
              tnc,
              startDate,
              endDate,
              isPopular: IsPopular,
              isFeatured: IsFeatured,
              typeSelected: type.filter((data) => data.value === typeId),
              catSelected,
              brandSelected,
              imgUrl: img,
              typeSelectedTemp: type.filter((data) => data.value === typeId),
              catSelectedTemp: catSelected,
              brandSelectedTemp: brandSelected,
              loading: false,
              dateNotConfirmed,
            });
          }
        }, 3000);
      } else {
        this.setState({ loading: false });
        alert('Terjadi kesalaahn, silahkan cek internet anda atau coba beberapa saat lagi');
      }
    } catch (error) {
      console.log(error);
      alert('Terjadi kesalaahn, silahkan cek internet anda atau coba beberapa saat lagi');
    }
  };

  // eslint-disable-next-line no-unused-vars
  checkWhatChanges = async (event) => {
    const {
      dataTemp,
      name,
      shortName,
      description,
      typeSelectedTemp,
      tnc,
      brandSelected,
      brandSelectedTemp,
      catSelected,
      catSelectedTemp,
      typeSelected,
      isPopular,
      isFeatured,
      imgUrl,
    } = this.state;

    if (dataTemp.name !== name) {
      await this.setState({ titleChange: true });
    }
    if (dataTemp.shortName !== shortName) {
      await this.setState({ shortChange: true });
    }
    if (dataTemp.description !== description) {
      await this.setState({ descChange: true });
    }
    if (dataTemp.tnc !== tnc) {
      console.log(dataTemp.tnc);
      console.log(tnc);
      await this.setState({ tncChange: true });
    }
    console.log('IS TYPE DIFFERENT', typeSelectedTemp, typeSelected);
    if (typeSelectedTemp.value !== typeSelected.value) {
      await this.setState({ typeChange: true });
    }
    if (catSelectedTemp !== catSelected) {
      await this.setState({ catChange: true });
    }
    if (brandSelectedTemp !== brandSelected) {
      await this.setState({ brandChange: true });
    }
    if (dataTemp.IsPopular !== isPopular) {
      console.log(dataTemp, isPopular);
      await this.setState({ popularChange: true });
    }
    if (dataTemp.IsFeatured !== isFeatured) {
      await this.setState({ featuredChange: true });
    }
    if (dataTemp.img !== imgUrl) {
      await this.setState({ imgChange: true });
    }
    if (
      window.confirm(
        `You want to change only on\n${this.state.pushToTop === true ? '- Push to top new\n' : ''}${
          this.state.titleChange === true ? '- Title\n' : ''
        }${this.state.shortChange === true ? '- Short Title\n' : ''}${this.state.descChange === true ? '- Description\n' : ''}${
          this.state.tncChange === true ? '- Terms and Condition\n' : ''
        }${this.state.typeChange === true ? '- Type\n' : ''}${this.state.catChange === true ? '- Categories\n' : ''}${
          this.state.brandChange === true ? '- Brands\n' : ''
        }${this.state.featuredChange === true ? '- Featured\n' : ''}${this.state.popularChange === true ? '- Popular\n' : ''}${
          this.state.imgChange === true ? '- Image\n' : ''
        }`
      )
    ) {
      return true;
    }
    return false;
  };

  showDate = () => {
    const { editDate } = this.state;
    this.setState({ editDate: !editDate });
  };

  setStateFunction = (variable, value) => {
    this.setState({ [variable]: value });
  };

  render() {
    const {
      editDate,
      endDate,
      startDate,
      brandSelected,
      crawl,
      fetchUrl,
      dateNotConfirmed,
      modalNotif,
      modalContent,
      modalId,
      modalImg,
      modalTitle,
      modalTitleFixed,
      notifyToAll,
      notifyToFollowers,
      loading,
    } = this.state;
    const { liststore = [] } = this.props.globalState;
    return (
      <Layout level1="edit-discount" level1Href="/edit-discount">
        <ModalNotificationV2
          modalVisible={modalNotif}
          modalId={modalId}
          modalTitle={modalTitle}
          changeModalTitle={(text) => this.setState({ modalTitle: text.target.value })}
          modalImg={modalImg}
          modalTitleFixed={modalTitleFixed}
          modalType="discount"
          modalContent={modalContent}
          changeModalContent={(text) => this.setState({ modalContent: text.target.value })}
          notifyToAll={notifyToAll}
          notifyToAllToggle={() => this.setState({ notifyToAll: !notifyToAll })}
          notifyToFollowers={notifyToFollowers}
          notifyToFollowersToggle={() => this.setState({ notifyToFollowers: !notifyToFollowers })}
          loading={loading}
          onHide={() => this.setState({ modalNotif: false })}
          liststore={liststore}
          brandSelected={brandSelected}
          extend
        />
        {this.state.loading === true && <div className="loading">Loading&#8230;</div>}
        <div className="row mt-4">
          <div className="col-md-12 col-xl-12 mb-0">
            <h2 className="h3 text-left font-weight-bold mb-2">Edit Disqon</h2>
          </div>
          <Content>
            <form
              onSubmit={/* this.state.uploadWithUrl === true ? */ this.onSubmitWithUrl /*  : this.onSubmit */}
              method="post"
              encType="multipart/form-data"
              id="formPost"
            >
              <div className="row mt-3">
                <div className="col-md-8 col-xl-8 mb-4">
                  <div className="col-md-12 mx-auto">
                    <div className="form-group pb-3 row">
                      <div className="col-4 p-0">
                        <div className="form-group pb-1">
                          <label className="switch" style={{ float: 'left' }}>
                            <input
                              type="checkbox"
                              name="crawl"
                              onClick={() => this.setState((prevState) => ({ crawl: !prevState.crawl }))}
                              checked={this.state.crawl}
                            />
                            <span className="slider round"> </span>
                          </label>
                          <span style={{ fontSize: 18, fontWeight: 'bold' }}>Crawl?</span>
                        </div>
                      </div>
                      {crawl === true && (
                        <>
                          <div className="col-6">
                            <input
                              type="text"
                              name="fetchurl"
                              id="fetchurl"
                              className="form-control"
                              value={this.state.fetchUrl}
                              onChange={(event) => this.setState({ fetchUrl: event.target.value })}
                            />
                            <label className="form-control-placeholder" htmlFor="fetchurl">
                              <i className="fas fa-envelope-square prefix white-text active pr-2" /> Url
                            </label>
                          </div>
                          <div className="col-2">
                            <button
                              type="button"
                              className="btn btn-warning btn-block"
                              onClick={() => DashboardFunction.crawlSite(fetchUrl, brandSelected, [], this.setStateFunction, true)}
                              style={{ backgroundColor: '#27A4DD', color: '#fff' }}
                            >
                              Submit
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-md-12 mx-auto">
                      <div className="form-group pb-3">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          value={this.state.name}
                          onChange={(event) => this.setState({ name: event.target.value })}
                          required
                        />
                        <label className="form-control-placeholder" htmlFor="name">
                          <i className="fas fa-envelope-square prefix white-text active pr-2" /> Judul
                        </label>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          name="shortName"
                          id="shortName"
                          className="form-control"
                          maxLength={40}
                          value={this.state.shortName}
                          onChange={(event) => this.setState({ shortName: event.target.value })}
                          required
                        />
                        <label className="form-control-placeholder" htmlFor="shortName">
                          <i className="fas fa-tag prefix white-text active pr-2" /> Judul Pendek eg: Burger King Cashback 20%
                        </label>
                        <p style={{ fontSize: 12, width: '100%', textAlign: 'left' }}>format: brand+tipe+potongan</p>
                      </div>
                      <CheckboxComponent
                        name="editDate"
                        id="editDate"
                        checked={editDate}
                        param="editDate"
                        setState={this.setStateFunction}
                        title="Edit date?"
                      />
                      {editDate && (
                        <div className="row">
                          <div className="form-group pb-1 col-6">
                            <label>Tanggal Mulai</label>
                            <DatePicker
                              id="startDate"
                              value={startDate}
                              selected={moment(startDate).isValid() ? moment(startDate).toDate() : null}
                              onChange={(e) => this.handleChangeDate(e)}
                              isClearable
                              placeholderText="Biarkan jika tidak ada!"
                            />
                          </div>
                          <div className="form-group pb-1 col-6">
                            <label>Tanggal Selesai</label>
                            <DatePicker
                              id="endDate"
                              value={endDate}
                              selected={moment(endDate).isValid() ? moment(endDate).toDate() : null}
                              onChange={(e) => this.handleChangeDate2(e)}
                              isClearable
                              placeholderText="Biarkan jika tidak ada!"
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group pb-3">
                        <label htmlFor="amount"> Deskripsi</label>
                        <ReactQuill value={this.state.description} name="description" onChange={(event) => this.handleChange('description', event)} />
                      </div>
                      <div className="form-group pb-3">
                        <label htmlFor="amount"> Syarat dan Ketentuan</label>
                        <ReactQuill
                          value={this.state.tnc}
                          name="Terms and Condition"
                          onChange={(event) => this.handleChange('tnc', event)}
                          style={{ height: 170 }}
                          className="tncquill"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-xl-4 mb-4">
                  <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0', padding: 10, paddingTop: 40 }}>
                    <div className="form-group pb-3">
                      <CheckboxComponent
                        name="dateNotConfirmed"
                        id="dateNotConfirmed"
                        checked={dateNotConfirmed}
                        param="dateNotConfirmed"
                        setState={this.setStateFunction}
                        title="End date not confirmed yet?"
                      />
                      <SelectComponent
                        isMulti
                        name="store"
                        id="store"
                        options={liststore || []}
                        value={brandSelected}
                        onChange={this.handleChangeOpt}
                        param="brandSelected"
                        iClassName="fas fa-user prefix white-text active pr-2"
                        title=" Brand"
                      />
                      {liststore.length < 1 && (
                        <Button onClick={() => this.renderBrand()} variant="danger" block className="mt-2">
                          Refresh brand?
                        </Button>
                      )}
                      <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', top: '-30px', fontSize: 12 }}>
                        <i className="fas fa-user prefix white-text active pr-2" /> Brand
                      </label>
                      <Button onClick={() => this.checkBrandDiscount()} variant="warning" block className="mt-2">
                        Cek diskon dari brand?
                      </Button>
                      <BrandDiscountModal data={this.state} hide={() => this.setState({ brandDiscountPopUp: false })} />
                    </div>
                    <div className="form-group pb-3">
                      <SelectComponent
                        name="cat"
                        id="cat"
                        isMulti
                        options={cat}
                        value={this.state.catSelected}
                        onChange={this.handleChangeOpt3}
                        param="catSelected"
                        iClassName="fas fa-user prefix white-text active pr-2"
                        title=" Kategori"
                      />
                    </div>
                    <div className="form-group pb-3">
                      <SelectComponent
                        name="type"
                        id="type"
                        options={type}
                        value={this.state.typeSelected}
                        onChange={this.handleChangeOpt2}
                        param="typeSelected"
                        iClassName="fas fa-user prefix white-text active pr-2"
                        title=" Tipe"
                      />
                    </div>
                    <div className="form-group pb-3">
                      <input
                        type="text"
                        id="link"
                        name="link"
                        className="form-control"
                        value={this.state.link}
                        onChange={(text) => this.setState({ link: text.nativeEvent.data })}
                      />
                      <label className="form-control-placeholder" htmlFor="link">
                        <i className="fas fa-envelope-square prefix white-text active pr-2" /> Link (optional)
                      </label>
                    </div>
                    {/* <div className="form-group pb-1">
                      <CheckboxComponent
                        name="onlySelectedPlace"
                        id="onlySelectedPlace"
                        checked={onlySelectedPlace}
                        param="onlySelectedPlace"
                        setState={this.setStateFunction}
                        title="Only on selected place/s?"
                      />
                    </div>
                    {onlySelectedPlace && (
                      <div className="form-group pb-1">
                        <SelectComponent
                          name="onlySelectedPlaceData"
                          id="onlySelectedPlaceData"
                          isMulti
                          options={placeList}
                          value={onlySelectedPlaceData}
                          onChange={this.handleChangeOptions}
                          param="onlySelectedPlaceData"
                          title="Place (If the name not exist, let it empty)"
                        />
                      </div>
                    )} */}
                    <div className="form-group pb-1">
                      <label className="switch" style={{ float: 'left' }}>
                        <input
                          type="checkbox"
                          name="pushToTop"
                          onClick={() => this.setState({ pushToTop: !this.state.pushToTop })}
                          checked={this.state.pushToTop}
                        />
                        <span className="slider round"> </span>
                      </label>
                      <span style={{ fontSize: 18, fontWeight: 'bold' }}>Push to New?</span>
                    </div>
                    <div className="form-group pb-1">
                      <label className="switch" style={{ float: 'left' }}>
                        <input
                          type="checkbox"
                          name="isFeatured"
                          id="isFeatured"
                          checked={this.state.isFeatured === 1}
                          onClick={() => this.setState({ isFeatured: this.state.isFeatured === 1 ? 0 : 1 })}
                        />
                        <span className="slider round"> </span>
                      </label>
                      <span style={{ fontSize: 18, fontWeight: 'bold' }}>Add to Featured?</span>
                    </div>
                    <div className="form-group pb-1">
                      <label className="switch" style={{ float: 'left' }}>
                        <input
                          type="checkbox"
                          name="isPopular"
                          id="isPopular"
                          checked={this.state.isPopular === 1}
                          onClick={() => this.setState({ isPopular: this.state.isPopular === 1 ? 0 : 1 })}
                        />
                        <span className="slider round"> </span>
                      </label>
                      <span style={{ fontSize: 18, fontWeight: 'bold' }}>Add to popular?</span>
                    </div>
                    <div className="form-group pb-1">
                      <label className="switch" style={{ float: 'left' }}>
                        <input
                          type="checkbox"
                          name="notify"
                          id="notify"
                          checked={this.state.notify === 1}
                          onClick={() => this.setState({ notify: this.state.notify === 1 ? 0 : 1 })}
                        />
                        <span className="slider round"> </span>
                      </label>
                      <span style={{ fontSize: 18, fontWeight: 'bold' }}>Notify?</span>
                    </div>
                    <div className="form-group pb-1">
                      <label className="switch" style={{ float: 'left' }}>
                        <input
                          type="checkbox"
                          name="uploadWithUrl"
                          onClick={() => this.setState({ uploadWithUrl: !this.state.uploadWithUrl })}
                          checked={this.state.uploadWithUrl}
                        />
                        <span className="slider round"> </span>
                      </label>
                      <span style={{ fontSize: 18, fontWeight: 'bold' }}>Change Image?</span>
                    </div>
                    {this.state.uploadWithUrl === true && (
                      <div className="form-group">
                        <input
                          type="text"
                          name="imgUrl"
                          id="imgUrl"
                          className="form-control"
                          value={this.state.imgUrl}
                          onChange={(event) => this.setState({ imgUrl: event.target.value })}
                          required
                        />
                        <label className="form-control-placeholder" htmlFor="imgUrl">
                          <i className="fas fa-image prefix white-text active pr-2" /> Image url
                        </label>
                      </div>
                    )}
                    <h2>Old Image</h2>
                    <img src={this.state.dataTemp.img} style={{ width: '100%', height: 100, margin: '20px 0' }} alt="asd" />
                    <button className="btn btn-primary btn-block" type="submit" style={{ backgroundColor: '#27A4DD', color: '#fff' }}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Content>
        </div>
      </Layout>
    );
  }
}
const mapStateToProps = ({ store, place }) => {
  const { storeLoading, storeError, storeList, storeLastFetched, storeListWeb } = store;
  const { placeList } = place;
  return { storeLoading, storeError, storeList, storeLastFetched, storeListWeb, placeList };
};
export default connect(mapStateToProps, { fetchAllPlaces, fetchAllStore })(withRouter(EditDiscountScreen));
