import axios from 'axios';
import qs from 'qs';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const checkFollowers = async (modalStore) => {
  const resultArray = [];
  console.log(modalStore);
  // eslint-disable-next-line array-callback-return
  modalStore.map((data) => {
    resultArray.push(data.toString());
  });
  const token = cookies.get('token');
  const header = {
    Authorization: `Bearer ${token}`,
  };
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const result = await axios.get(`${BASE_URL}dashboard/v2/storefollowers`, {
    params: {
      storeId: resultArray.toString().split(',').join(','),
    },
    headers: header,
  });
  console.log('RESP', result);
  const tempData = [];
  if (result.status === 200) {
    const data = result.data.result;
    if (data.length > 0) {
      data.map((d) => tempData.push(d.id));
      return tempData;
    }
    alert('No Followers');
    return null;
  }
  alert('There are some error, please try again');
  return null;
};

const sendNotifOnesignal = async (data) => {
  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: 'Basic NWI3MWViMjAtZTFjYS00ZWI5LTg4YzEtMWI3MzA0ZGNhY2Rl',
  };
  console.log({
    data,
    dateFixed: {
      ...data,
      ...(data.include_external_user_ids ? { include_external_user_ids: data.include_external_user_ids.map((a) => `${a}`) } : {}),
    },
  });
  axios
    .post(
      'https://onesignal.com/api/v1/notifications',
      {
        ...data,
        ...(data.include_external_user_ids ? { include_external_user_ids: data.include_external_user_ids.map((a) => `${a}`) } : {}),
      },
      {
        headers,
      }
    )
    .then((res) => {
      console.log('RESSS', res);
      alert(`Sent to ${res.data.recipients}`);
    })
    .catch((err) => {
      console.log('ERROR', err);
    });
};

// eslint-disable-next-line no-shadow
const postNotif = async (type, dataPass, brandFollowers, modalId) => {
  let notifiers = [];
  let pass = false;
  if (type === 'all') {
    notifiers = '[1]';
    pass = true;
  } else if (type === 'followers' && brandFollowers.length > 0) {
    notifiers = `[${brandFollowers.toString()}]`;
    pass = true;
  } else if (type === 'followers' && brandFollowers.length < 1) {
    alert('No followers');
  }
  if (pass === true) {
    const data = qs.stringify({
      entityType: 1,
      entityId: modalId,
      notifiers,
      actorId: 8,
    });
    const token = cookies.get('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      console.log('dataPass', dataPass);
      const BASE_URL = process.env.REACT_APP_BASE_URL;
      const response = await axios.post(`${BASE_URL}dashboard/addnotification`, data, {
        headers,
      });
      if (response.status === 200) {
        if (response.data.status === true) {
          console.log('SEND IN FUNCTION');
          console.log('RESPP', response);
          sendNotifOnesignal(dataPass);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
};

const sendNotif = async ({ notifyToAll, notifyToFollowers, modalImg, modalTitle, modalId, modalType, modalTitleFixed, modalContent, modalStore }) => {
  if (modalImg) {
    if (notifyToAll === true) {
      const data = {
        app_id: '942046ea-d755-4f5c-9384-ea20410a0fb9',
        included_segments: ['All'],
        data: { type: modalType, id: modalId, notif: 'true', title: modalTitleFixed },
        big_picture: modalImg,
        large_icon: modalImg,
        headings: { en: modalTitle },
        contents: { en: modalContent },
        filters: [{ field: 'tag', key: 'pushnotif', relation: '!=', value: '0' }],
      };
      console.log('DATA NOTIF ALL', data);
      postNotif('all', data, [], modalId);
    } else if (notifyToFollowers === true) {
      const brandFollowers = await checkFollowers(modalStore);
      const data = {
        app_id: '942046ea-d755-4f5c-9384-ea20410a0fb9',
        include_external_user_ids: brandFollowers.map((a) => `${a}`),
        data: { type: modalType, id: modalId, notif: 'true', title: modalTitleFixed },
        big_picture: modalImg,
        large_icon: modalImg,
        headings: { en: modalTitle },
        contents: { en: modalContent },
      };
      postNotif('followers', data, brandFollowers, modalId);
    } else {
      console.log('SEND TO NONE');
    }
  }
};

export default {
  sendNotif,
  postNotif,
  sendNotifOnesignal,
};
