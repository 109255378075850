export const FETCH_ALL_STORE = 'FETCH_ALL_STORE';
export const FETCH_ALL_STORE_SUCCESS = 'FETCH_ALL_STORE_SUCCESS';
export const FETCH_ALL_STORE_FAILED = 'FETCH_ALL_STORE_FAILED';

export const UPDATE_STORE = 'UPDATE_STORE';
export const UPDATE_STORE_SUCCESS = 'UPDATE_STORE_SUCCESS';
export const UPDATE_STORE_FAILED = 'UPDATE_STORE_FAILED';

export const FETCH_ALL_STORE_WEB = 'FETCH_ALL_STORE_WEB';
export const FETCH_ALL_STORE_WEB_SUCCESS = 'FETCH_ALL_STORE_WEB_SUCCESS';
export const FETCH_ALL_STORE_WEB_FAILED = 'FETCH_ALL_STORE_WEB_FAILED';

export const fetchingAllStores = () => {
  return {
    type: FETCH_ALL_STORE,
  };
};

export const fetchingAllStoreSuccess = (store) => {
  const today = new Date();
  return {
    type: FETCH_ALL_STORE_SUCCESS,
    payload: store,
    date: today,
  };
};

export const fetchingAllStoreFailed = (error) => {
  return {
    type: FETCH_ALL_STORE_SUCCESS,
    payload: error,
  };
};

export const fetchingAllStoresWeb = () => {
  return {
    type: FETCH_ALL_STORE_WEB,
  };
};

export const fetchingAllStoreWebSuccess = (store) => {
  const today = new Date();
  return {
    type: FETCH_ALL_STORE_WEB_SUCCESS,
    payload: store,
    date: today,
  };
};

export const fetchingAllStoreWebFailed = (error) => {
  return {
    type: FETCH_ALL_STORE_WEB_FAILED,
    payload: error,
  };
};

export const updateStoreSuccess = (store) => {
  return {
    type: UPDATE_STORE_SUCCESS,
    payload: store,
  };
};
