export const FETCH_LATEST_PROMO = 'FETCH_LATEST_PROMO';
export const FETCH_LATEST_PROMO_SUCCESS = 'FETCH_LATEST_PROMO_SUCCESS';
export const FETCH_LATEST_PROMO_FAILED = 'FETCH_LATEST_PROMO_FAILED';

export const FETCH_SEARCH_PROMO = 'FETCH_SEARCH_PROMO';
export const FETCH_SEARCH_PROMO_SUCCESS = 'FETCH_SEARCH_PROMO_SUCCESS';
export const FETCH_SEARCH_PROMO_FAILED = 'FETCH_SEARCH_PROMO_FAILED';

export const REMOVE_PROMO = 'REMOVE_PROMO';
export const REMOVE_PROMO_SUCCESS = 'REMOVE_PROMO_SUCCESS';
export const REMOVE_PROMO_FAILED = 'REMOVE_PROMO_FAILED';

export const fetchingAllLatestPromo = () => {
  return {
    type: FETCH_LATEST_PROMO,
  };
};

export const fetchingAllLatestPromoSuccess = (promo) => {
  const today = new Date();
  return {
    type: FETCH_LATEST_PROMO_SUCCESS,
    payload: promo,
    date: today,
  };
};

export const fetchingAllLatestPromoFailed = (error) => {
  return {
    type: FETCH_LATEST_PROMO_FAILED,
    payload: error,
  };
};

export const fetchingAllSearchPromo = () => {
  return {
    type: FETCH_SEARCH_PROMO,
  };
};

export const fetchingAllSearchPromoSuccess = (promo) => {
  const today = new Date();
  return {
    type: FETCH_SEARCH_PROMO_SUCCESS,
    payload: promo,
    date: today,
  };
};

export const fetchingAllSearchPromoFailed = (error) => {
  return {
    type: FETCH_SEARCH_PROMO_FAILED,
    payload: error,
  };
};

export const removeLatestPromoThirdParty = (promo) => {
  const today = new Date();
  return {
    type: REMOVE_PROMO_SUCCESS,
    payload: promo,
    date: today,
  };
};
