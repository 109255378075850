/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import authService from './authService';

// eslint-disable-next-line import/prefer-default-export
export function Authenticated(BaseComponent) {
  class AuthenticatedComponent extends React.Component {
    componentDidMount() {
      this.checkAuthentication(this.props);
    }

    componentDidUpdate(nextProps) {
      // eslint-disable-next-line react/destructuring-assignment
      if (nextProps.location !== this.props.location) {
        this.checkAuthentication(nextProps);
      }
    }

    // eslint-disable-next-line consistent-return
    // eslint-disable-next-line class-methods-use-this
    async isLoggedIn() {
      try {
        await authService
          .loadJWT()
          .then((res) => {
            return res;
          })
          .catch(() => {
            return false;
          });
      } catch (error) {
        return false;
      }
    }

    // eslint-disable-next-line class-methods-use-this
    async checkAuthentication(params) {
      const { history } = params;
      try {
        await authService
          .loadJWT()
          .then((res) => {
            if (res === false) {
              history.replace({ pathname: '/login-brand' });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((_err) => {
            history.replace({ pathname: '/login-brand' });
          });
      } catch (error) {
        history.replace({ pathname: '/login-brand' });
      }
    }

    render() {
      return <BaseComponent {...this.props} />;
    }
  }

  return withRouter(AuthenticatedComponent);
}
