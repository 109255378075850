/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Breadcrumb } from 'antd';

function Layout(props) {
  const { level1 = '', level1Href = '', level2, level2Href } = props;
  return (
    <div>
      <div
        className="view"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          paddingTop: 10,
          backgroundColor: '#F0F2F5',
        }}
      >
        <div className="container mask rgba-gradient align-items-center">
          <div className="d-flex align-items-start mt-2">
            <Breadcrumb>
              <Breadcrumb.Item href="/dashboard">
                <span>Dashboard</span>
              </Breadcrumb.Item>
              <Breadcrumb.Item href={level1Href}>
                <span>{level1}</span>
              </Breadcrumb.Item>
              {!!level2 && (
                <Breadcrumb.Item href={level2Href}>
                  <span>{level2}</span>
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default Layout;
