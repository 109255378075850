import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

// eslint-disable-next-line no-shadow
async function post(header, data, update = false) {
  const url = `${BASE_URL}dashboard/news`;
  const response = await axios({
    method: update ? 'patch' : 'post',
    url,
    data,
    headers: header,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function update(header, data) {
  const url = `${BASE_URL}dashboard/news`;
  const response = await axios({
    method: 'patch',
    url,
    data,
    headers: header,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function list(token, limit = '') {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const url = `${BASE_URL}dashboard/news/`;
  const response = await axios({
    method: 'get',
    url,
    headers,
    params: {
      limit: limit || 100,
    },
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

const NewsAPI = {
  list,
  post,
  update,
};

export default NewsAPI;
