/* eslint-disable no-restricted-syntax */
/* eslint-disable no-labels */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import moment from 'moment';

const months = [
  {
    name: 'januari',
    num: 1,
  },
  {
    name: 'jan',
    num: 1,
  },
  {
    name: 'februari',
    num: 2,
  },
  {
    name: 'february',
    num: 2,
  },

  {
    name: 'feb',
    num: 2,
  },

  {
    name: 'march',
    num: 3,
  },

  {
    name: 'maret',
    num: 3,
  },

  {
    name: 'mar',
    num: 4,
  },

  {
    name: 'april',
    num: 4,
  },

  {
    name: 'apr',
    num: 4,
  },

  {
    name: 'mei',
    num: 5,
  },

  {
    name: 'may',
    num: 5,
  },

  {
    name: 'juni',
    num: 6,
  },

  {
    name: 'june',
    num: 6,
  },

  {
    name: 'jun',
    num: 6,
  },

  {
    name: 'juli',
    num: 7,
  },

  {
    name: 'jul',
    num: 7,
  },

  {
    name: 'july',
    num: 7,
  },

  {
    name: 'agustus',
    num: 8,
  },

  {
    name: 'august',
    num: 8,
  },

  {
    name: 'aug',
    num: 8,
  },

  {
    name: 'ags',
    num: 8,
  },

  {
    name: 'september',
    num: 9,
  },

  {
    name: 'sept',
    num: 9,
  },

  {
    name: 'oktober',
    num: 10,
  },

  {
    name: 'october',
    num: 10,
  },

  {
    name: 'oct',
    num: 10,
  },

  {
    name: 'november',
    num: 11,
  },

  {
    name: 'nov',
    num: 11,
  },

  {
    name: 'desember',
    num: 12,
  },

  {
    name: 'december',
    num: 12,
  },

  {
    name: 'dec',
    num: 12,
  },

  {
    name: 'des',
    num: 12,
  },
];
const dateKeyword = [
  'januari',
  'jan',
  'februari',
  'february',
  'feb',
  'march',
  'maret',
  'mar',
  'april',
  'apr',
  'mei',
  'may',
  'juni',
  'june',
  'jun',
  'juli',
  'jul',
  'july',
  'agustus',
  'august',
  'aug',
  'ags',
  'september',
  'sept',
  'oktober',
  'october',
  'oct',
  'november',
  'nov',
  'desember',
  'december',
  'dec',
  'des',
];
const d = new Date();
const thisyear = d.getFullYear();

const converter = {
  async startConvert(content) {
    console.log('START', content);
    const date = await this.findExpiredByContent(content);
    return date;
  },
  async findExpiredByContent(content) {
    const strippedString = content.replace(/(<([^>]+)>)/gi, ' ').toLowerCase();
    const words = strippedString.split(/[ .]+/);
    const wordsTemp = words;
    const result = [];
    // console.log('strippedString', strippedString, words)

    // eslint-disable-next-line array-callback-return
    dateKeyword.some((r) => {
      const numberIndex = words.indexOf(r);
      if (numberIndex !== -1) {
        const nextWord = `${words[numberIndex + -3]} ${words[numberIndex + -2]} ${words[numberIndex + -1]} ${words[numberIndex + 0]} ${
          words[numberIndex + 1]
        } ${words[numberIndex + 2]} ${words[numberIndex + 3]} ${words[numberIndex + 4]}`;
        console.log('DATE 1', nextWord);
        result.push(nextWord);
      }
    });
    if (result.length >= 1) {
      // Two times checked
      // eslint-disable-next-line array-callback-return
      dateKeyword.some((r) => {
        const numberIndex = wordsTemp.indexOf(r);
        if (numberIndex !== -1) {
          const nextWord = `${words[numberIndex + -3]} ${words[numberIndex + -2]} ${words[numberIndex + -1]} ${words[numberIndex + 0]} ${
            words[numberIndex + 1]
          } ${words[numberIndex + 2]} ${words[numberIndex + 3]} ${words[numberIndex + 4]}`;
          console.log('DATE 2', nextWord);
          wordsTemp.splice(numberIndex, 1);
          result.push(nextWord);
        }
      });
    }
    return this.getDateFromContent(result);
  },
  monthNumToName(monthname) {
    return months.find((val) => val.name === monthname) ? months.find((val) => val.name === monthname).num : false;
  },
  isNormalInteger(str) {
    return /^\+?(0|[1-9]\d*)$/.test(str);
  },
  async getDateFromContent(data) {
    console.error('getDateFromContent');
    console.log(data);
    const dash = ['-', '–', '—', '–'];
    const results = [];
    const tempRes = [];
    // const today = moment(new Date()).format('DD-M-YYYY');
    // Check each sentence
    console.log('getDateFromContent', data);
    dance: for (let j = 0; j < data.length; j++) {
      const words = data[j].replace('&nbsp;', ' ').toLowerCase().replace('-', ' - ').split(/[ .]+/);
      let withdash = false;
      // Check Dash
      for (let x = 0; x < dash.length; x++) {
        let numberIndex = words.indexOf(dash[x]);
        if (numberIndex !== -1) {
          const end = `${words[numberIndex + 1]}-${this.monthNumToName(words[numberIndex + 2])}${
            this.isNormalInteger(words[numberIndex + 3]) === true ? `-${words[numberIndex + 3]}` : `-${thisyear}`
          }`;
          let start =
            (this.isNormalInteger(words[numberIndex - 3]) !== false ? `${words[numberIndex - 3]}-` : '') +
            (this.monthNumToName(words[numberIndex - 2]) !== false
              ? this.monthNumToName(words[numberIndex - 2])
              : this.isNormalInteger(words[numberIndex - 2]) === true
              ? `${words[numberIndex - 2]}-`
              : '') +
            (this.monthNumToName(words[numberIndex - 1]) !== false
              ? this.monthNumToName(words[numberIndex - 1])
              : this.isNormalInteger(words[numberIndex - 1]) === true
              ? words[numberIndex - 1]
              : '');
          /* if(moment(end, 'DD-M-YYYY') >= moment(today, 'DD-M-YYYY')){ */
          if (start.length === 0) {
            results.push({ end });
          } else {
            if (start.split('-').length <= 2) {
              start = `${start}-${start.split('-').length === 1 ? `${end.split('-')[1]}-` : ''}${end.split('-')[2]}`;
            }
            // console.log('end',end)
            // console.log('start',start)
            let startDateFormat = moment(moment(start).format('M/D/YYYY'), 'M/D/YYYY').toDate();
            if (start !== undefined) {
              const splitted = start.split('-');
              startDateFormat = moment(moment(`${splitted[1]}/${splitted[0]}/${splitted[2]}`).format('M/D/YYYY'), 'M/D/YYYY').toDate();
            }
            let endDateFormat = moment(moment(end).format('M/D/YYYY'), 'M/D/YYYY').toDate();
            if (end !== undefined) {
              const splitted = end.split('-');
              endDateFormat = moment(moment(`${splitted[1]}/${splitted[0]}/${splitted[2]}`).format('M/D/YYYY'), 'M/D/YYYY').toDate();
            }
            results.push(
              {
                end,
                endDateFormat,
              },
              {
                start,
                startDateFormat,
              }
            );
          }
          /* }else{
              return 'expired'
            } */
          withdash = true;
          break dance;
        }

        // Check without dash
        if (x === dash.length - 1 && withdash === false) {
          console.log(`no dash ${true}`);
          for (let i = 0; i < dateKeyword.length; i++) {
            numberIndex = words.indexOf(dateKeyword[i]);
            const result = [];
            if (numberIndex !== -1) {
              const res = `${words[numberIndex + -1]}-${this.monthNumToName(words[numberIndex])}${
                this.isNormalInteger(words[numberIndex + 1]) === true ? `-${words[numberIndex + 1]}` : `-${thisyear}`
              }`;
              console.log(res);
              tempRes.push(res);
              break;
            }
          }
        }
      }
    }
    if (results.length < 1) {
      console.log('START SORTING');
      const sortedArray = tempRes.sort((a, b) => a.valueOf() === b.valueOf());
      console.log('sortedArray', sortedArray);
      const unique = [...new Set(sortedArray)];
      console.log(unique);
      if (unique.length > 1) {
        let startDateFormat = moment(moment(unique[0]).format('M/D/YYYY'), 'M/D/YYYY').toDate();
        if (unique[0] !== undefined) {
          const splitted = unique[0].split('-');
          startDateFormat = moment(moment(`${splitted[1]}/${splitted[0]}/${splitted[2]}`).format('M/D/YYYY'), 'M/D/YYYY').toDate();
        }
        let endDateFormat = moment(moment(unique[unique.length - 1]).format('M/D/YYYY'), 'M/D/YYYY').toDate();
        if (unique[unique.length - 1] !== undefined) {
          const splitted = unique[unique.length - 1].split('-');
          endDateFormat = moment(moment(`${splitted[1]}/${splitted[0]}/${splitted[2]}`).format('M/D/YYYY'), 'M/D/YYYY').toDate();
        }
        const start = {
          start: unique[0],
          startDateFormat,
        };
        const end = {
          end: unique[unique.length - 1],
          endDateFormat,
        };
        results.push(end, start);
      } else {
        let endDateFormat = moment(moment(unique[0]).format('M/D/YYYY'), 'M/D/YYYY').toDate();
        if (unique[0] !== undefined) {
          const splitted = unique[0].split('-');
          console.log('NOT UNDEFINED', splitted, `${splitted[1]}/${splitted[0]}/${splitted[2]}`);
          endDateFormat = moment(moment(`${splitted[1]}/${splitted[0]}/${splitted[2]}`).format('M/D/YYYY'), 'M/D/YYYY').toDate();
        }
        results.push({
          end: unique[0],
          endDateFormat,
        });
      }
    }
    console.log(results);
    return results;
  },
};

export default converter;
