/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Image } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import Cookies from 'universal-cookie';
// eslint-disable-next-line no-unused-vars
import $ from 'jquery';
import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';
import DashboardAPI from '../../../service/api';
import { SpinnerComponent, SpinnerOverlay } from '../../../components/molecules/spinner';

const cookies = new Cookies();
const data = {
  columns: [
    {
      label: 'Id',
      field: 'id',
      sort: 'asc',
      width: 3,
    },
    {
      label: 'Title',
      field: 'title',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'Image',
      field: 'img',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Source',
      field: 'source',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Created',
      field: 'created',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Update',
      field: 'update',
      sort: 'asc',
      width: 100,
    },
  ],
};

// eslint-disable-next-line no-unused-vars
const doType = [
  { value: 'notifadd', label: 'New Discount Notification' },
  { value: 'notifhistory', label: 'Check Notification History' },
];

class NewsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataRows: [],
      loadingSearch: false,
    };
  }

  // eslint-disable-next-line camelcase
  // eslint-disable-next-line class-methods-use-this
  truncate(str, no_words) {
    return str.split(' ').splice(0, no_words).join(' ');
  }

  componentDidMount() {
    this.renderNews();
  }

  renderNews = async () => {
    this.setState({ loading: true });
    const token = cookies.get('token');
    try {
      const res = await DashboardAPI.NewsAPI.list(token, 50);
      console.log('FROM FETCH', res.data);
      if (res.data.status === true) {
        this.buildTable(res.data.result);
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  buildTable = (dataNews, search = false) => {
    const result = [];
    dataNews.map((data) => {
      return result.push({
        id: data.id,
        title: data.title,
        img: data.img ? <Image src={data.img} rounded style={{ width: 50, height: 50 }} /> : ` `,
        source: data.source || '',
        created: <Moment format="DD-MMM-YYYY">{data.created || ` `}</Moment>,
        update: (
          <Button
            color="default"
            rounded
            size="sm"
            style={{ backgroundColor: '#5EC5CC', color: '#fff' }}
            onClick={() => this.props.history.push(`/dashboard/news/update/${data.id}`, data)}
          >
            Update
          </Button>
        ),
      });
    });
    const resulttopush = [];
    resulttopush.columns = data.columns;
    resulttopush.rows = result;
    console.log(resulttopush);
    this.setState({
      dataRows: resulttopush,
      loading: false,
    });
    if (search) this.setState({ loadingSearch: false });
  };

  handleChange = (event, type) => {
    console.log('EVENT', event);
    this.setState({
      [type]: event,
    });
  };

  render() {
    const { loadingSearch, loading } = this.state;
    return (
      <div>
        <SpinnerOverlay loading={loading} />
        <div className="view" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: 10 }}>
          <div className="mask rgba-gradient align-items-center">
            <div className="container">
              <div className="row mt-4">
                <div className="col-md-12 col-xl-12 mb-0">
                  <h2 className="text-left font-weight-bold mb-2">News</h2>
                  <Button
                    variant="primary"
                    style={{ position: 'absolute', top: 0, right: 20 }}
                    onClick={() => this.props.history.push('/dashboard/news/add')}
                  >
                    + Add new news
                  </Button>
                </div>
                <div className="col-md-12 col-xl-12 mb-0">
                  <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
                    <div className="card-body">
                      <SpinnerComponent loading={loadingSearch} />
                      <MDBDataTable striped bordered hover data={this.state.dataRows} responsive />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NewsList);
