import Cookies from 'universal-cookie';
import {
  fetchingAllDiscounts,
  fetchingAllDiscountSuccess,
  fetchingAllDiscountFailed,
  setDiscountSearchSuccess,
  setDiscountSearchFailed,
  setDiscountSearch,
  clearDiscountSearch,
} from '../actions/discountActions';
import API from '../../service/api';

const cookies = new Cookies();

// eslint-disable-next-line import/prefer-default-export
export const fetchAllDiscount = () => {
  return async (dispatch, getState) => {
    const { discountLoading } = getState().discount;
    if (!discountLoading) {
      dispatch(fetchingAllDiscounts());
      try {
        const token = cookies.get('token');
        if (!token) {
          throw new Error('Invalid Token');
        }
        const response = await API.DiscountAPI.listdiscount(token, 50);
        const { data } = response;
        if (data.status === true) {
          dispatch(fetchingAllDiscountSuccess(data.data || []));
          return response;
        }
        throw response;
      } catch (error) {
        const { message } = error?.data || {};
        if (message === 'Token Expired1' || message === 'Token Expired') {
          // eslint-disable-next-line no-alert
          alert('Sesi anda berakhir, silahkan coba login lagi');
          cookies.remove('token');
        }
        dispatch(fetchingAllDiscountFailed(error));
        throw error;
      }
    } else {
      return null;
    }
  };
};

export const fetchDiscountSearch = (keyword) => {
  return async (dispatch) => {
    dispatch(setDiscountSearch(keyword));
    if (keyword.length >= 3) {
      try {
        const token = cookies.get('token');
        if (!token) {
          throw new Error('Invalid Token');
        }
        const response = await API.DiscountAPI.search(token, keyword, 20);
        const { data } = response;
        if (data.status === true) {
          dispatch(setDiscountSearchSuccess(data.result || []));
          return response;
        }
        throw response;
      } catch (error) {
        dispatch(setDiscountSearchFailed(error));
        throw error;
      }
    } else if (keyword.length === 0) {
      console.log('here');
      dispatch(clearDiscountSearch());
      return null;
    } else {
      dispatch(setDiscountSearchFailed(null));
      throw new Error('Not enough data');
    }
  };
};
