import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

async function list(token, limit) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const url = `${BASE_URL}dashboard/polling/list`;
  const response = await axios({
    method: 'get',
    url,
    headers,
    params: {
      limit: limit || 100,
    },
  });
  console.log(response);
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function add(token, content, options, onTimeline = 1, pollingTypeId = 1, isFeatured = 1) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const url = `${BASE_URL}dashboard/polling`;
  const data = {
    onTimeline,
    content,
    pollingTypeId,
    isFeatured,
    options,
  };
  const response = await axios({
    method: 'post',
    url,
    data,
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function addJson(token, data) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const url = `${BASE_URL}dashboard/polling`;
  const response = await axios({
    method: 'post',
    url,
    data,
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

const PollingAPI = {
  list,
  add,
  addJson,
};

export default PollingAPI;
