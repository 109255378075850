/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

async function chartHome(type, header) {
  const response = await axios({
    method: 'GET',
    url:
      type == 1
        ? `${BASE_URL}dashboard/totaluser`
        : type == 2
        ? `${BASE_URL}dashboard/totaldiscount`
        : type == 3
        ? `${BASE_URL}dashboard/activediscount`
        : type == 4
        ? `${BASE_URL}dashboard/totalpost`
        : type == 6
        ? `${BASE_URL}dashboard/listpost`
        : type == 7
        ? `${BASE_URL}dashboard/listregister`
        : `${BASE_URL}dashboard/totalcomment`,
    data: {
      limit: 5,
    },
    headers: header,
  });
  console.log('Chart', response);
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  }
  if (response.data.status === true) return response;

  throw new Error('There are some error');
}

const ChartAPI = {
  chartHome,
};

export default ChartAPI;
