import React from 'react';
import Select, { createFilter } from 'react-select';

class SelectComponent extends React.PureComponent {
  render() {
    const {
      name = '',
      id = '',
      options = [],
      value = [],
      onChange,
      isMulti = false,
      title,
      param,
      required = true,
      iClassName = 'fas fa-user prefix white-text active pr-2',
      classNameSelect = '',
    } = this.props;
    return (
      <>
        <Select
          name={name}
          id={id}
          required={required}
          isMulti={isMulti}
          options={options}
          value={value}
          onChange={(e) => onChange(e, param)}
          className={`basic-multi-select ${classNameSelect}`}
          classNamePrefix="select"
          filterOption={createFilter({ ignoreAccents: false })}
        />
        <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', top: '-30px', fontSize: 12 }}>
          <i className={iClassName} /> {title}
        </label>
      </>
    );
  }
}

export default SelectComponent;
