import {
  FETCH_ALL_STORE,
  FETCH_ALL_STORE_FAILED,
  FETCH_ALL_STORE_SUCCESS,
  FETCH_ALL_STORE_WEB_SUCCESS,
  FETCH_ALL_STORE_WEB_FAILED,
  FETCH_ALL_STORE_WEB,
  UPDATE_STORE_SUCCESS,
} from '../actions/storeActions';

const storeInitialState = {
  storeLoading: false,
  storeError: null,
  storeList: [],
  storeLastFetched: null,
  currentStore: null,

  storeListWeb: [],
  storeWebLoading: false,
  storeListWebLastFetched: null,
};

export default (state = storeInitialState, action) => {
  switch (action.type) {
    case FETCH_ALL_STORE:
      return { ...state, storeLoading: true, storeError: null };
    case FETCH_ALL_STORE_SUCCESS:
      return { ...state, storeLoading: false, storeError: null, storeList: action.payload, storeLastFetched: action.date };
    case FETCH_ALL_STORE_FAILED:
      return { ...state, storeLoading: false, storeError: action.payload };
    case FETCH_ALL_STORE_WEB:
      return { ...state, storeWebLoading: true, storeError: null };
    case FETCH_ALL_STORE_WEB_SUCCESS:
      return { ...state, storeWebLoading: false, storeError: null, storeListWeb: action.payload, storeListWebLastFetched: action.date };
    case FETCH_ALL_STORE_WEB_FAILED:
      return { ...state, storeWebLoading: false, storeError: action.payload };
    case UPDATE_STORE_SUCCESS:
      return { ...state, storeList: action.payload };
    default:
      return state;
  }
};
