/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import '../assets/style/all.css';
import authService from '../service/authService';
import DashboardAPI from '../service/api';

class LoginBrandScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    this.checkAuthentication();
  }

  async checkAuthentication() {
    try {
      await authService
        .loadJWT()
        .then((res) => {
          if (res !== false) {
            this.props.history.replace({ pathname: '/dashboard' });
          }
        })
        .catch((err) => {});
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  async login(event) {
    event.preventDefault();
    const { username, password } = this.state;
    if (password && username) {
      this.setState({ error: '', loading: true });
      try {
        const response = await DashboardAPI.AuthAPI.login(username, password);
        this.setState({ loading: false });
        if (response.data.status === true) {
          alert('Berhasil masuk');
          authService.saveItem(response.data.token);
          authService.saveCookies('userData', response.data);
          this.props.history.push('/dashboard', { data: response.data });
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        this.setState({ loading: false });
        const { status = {} } = error;
        if (!status) {
          alert('Ada error, mohon ulangi beberapa saat lagi');
        } else {
          alert('Autentikasi gagal!');
        }
      }
    } else {
      alert('Isi semua data');
    }
  }

  render() {
    return (
      <div className="body" style={{ marginTop: 70 }}>
        {this.state.loading === true && <div className="loading">Loading&#8230;</div>}
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xl-9 mx-auto">
              <div className="card card-signin flex-row my-5">
                <div className="card-img-left d-none d-md-flex" />
                <div className="card-body">
                  <h5 className="card-title text-center">Register</h5>
                  <form className="form-signin" onSubmit={this.login} method="post">
                    <div className="form-label-group">
                      <input
                        type="text"
                        id="inputUserame"
                        className="form-control"
                        placeholder="Username"
                        onChange={(event) => this.setState({ username: event.target.value })}
                        value={this.state.username}
                        required
                      />
                      <label htmlFor="inputUserame">Username</label>
                    </div>

                    <hr />

                    <div className="form-label-group">
                      <input
                        type="password"
                        id="inputPassword"
                        className="form-control"
                        placeholder="Password"
                        onChange={(event) => this.setState({ password: event.target.value })}
                        value={this.state.password}
                        required
                      />
                      <label htmlFor="inputPassword">Password</label>
                    </div>

                    <button className="btn btn-lg btn-primary btn-block text-uppercase" type="submit">
                      Sign In
                    </button>
                    <a className="d-block text-center mt-2 small" href="#">
                      Register
                    </a>
                    <hr className="my-4" />
                    {/* <button className="btn btn-lg btn-google btn-block text-uppercase" type="submit"><i className="fab fa-google mr-2"></i> Sign up with Google</button>
                                <button className="btn btn-lg btn-facebook btn-block text-uppercase" type="submit"><i className="fab fa-facebook-f mr-2"></i> Sign up with Facebook</button> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginBrandScreen);
