import Cookies from 'universal-cookie';
import {
  fetchingAllStores,
  fetchingAllStoreSuccess,
  fetchingAllStoreFailed,
  fetchingAllStoreWebSuccess,
  fetchingAllStoreWebFailed,
  fetchingAllStoresWeb,
  updateStoreSuccess,
} from '../actions/storeActions';
import API from '../../service/api';

const cookies = new Cookies();

// eslint-disable-next-line import/prefer-default-export
export const fetchAllStore = () => {
  return async (dispatch, getState) => {
    const { storeLoading } = getState().store;
    if (!storeLoading) {
      dispatch(fetchingAllStores());
      try {
        const token = cookies.get('token');
        if (!token) {
          throw new Error('Invalid Token');
        }
        const response = await API.StoreAPI.storeList(token, 4000);
        const { data } = response;
        const newRes = data.result.map((a) => ({
          ...a,
          label: a.name,
          value: a.id,
        }));
        dispatch(fetchingAllStoreSuccess(newRes || []));
        return response;
      } catch (error) {
        dispatch(fetchingAllStoreFailed(error));
        throw error;
      }
    } else {
      return null;
    }
  };
};

export const fetchAllStoreWeb = () => {
  return async (dispatch, getState) => {
    const { storeWebLoading } = getState().store;
    if (!storeWebLoading) {
      dispatch(fetchingAllStoresWeb());
      try {
        const token = cookies.get('token');
        if (!token) {
          throw new Error('Invalid Token');
        }
        const response = await API.StoreAPI.brandList(token);
        const { data } = response;
        dispatch(fetchingAllStoreWebSuccess(data.data || []));
        return response;
      } catch (error) {
        dispatch(fetchingAllStoreWebFailed(error));
        throw error;
      }
    } else {
      return null;
    }
  };
};

export const updateStoreRedux = (data) => {
  return async (dispatch, getState) => {
    const { storeList } = getState().store;
    if (data.storeId) {
      const { storeId, imgUrl, uploadImg, ...otherData } = data;
      const newStoreList = [...storeList];
      const foundIndex = newStoreList.findIndex((x) => x.id === storeId);
      newStoreList[foundIndex] = { ...newStoreList[foundIndex], img: imgUrl, ...otherData };
      console.log(newStoreList[foundIndex], foundIndex);
      dispatch(updateStoreSuccess(newStoreList));
      return true;
    }
    return null;
  };
};
