/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import SelectComponent from '../../../components/form/select';

function ModalAdd(props) {
  const { dataBrand = {}, setBrand, selectedBrand, setType, selectedType, setUrl, valueUrl, setUsername, valueUsername, submit } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Modal heading</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Form onSubmit={submit}>
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Store / Brand</Form.Label>
                  <SelectComponent
                    isMulti={false}
                    name="store"
                    id="store"
                    options={dataBrand.map((a) => {
                      return { label: a.name, value: a.id };
                    })}
                    value={selectedBrand}
                    onChange={setBrand}
                    param="brandSelected"
                    iClassName="fas fa-user prefix white-text active pr-2"
                    title=" Brand"
                  />
                  <Form.Text className="text-muted">Select the store / brand.</Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>Type</Form.Label>
                  <Form.Control as="select" defaultValue="Choose..." value={selectedType} onChange={(event) => setType(event.target.value)}>
                    <option value="instagram">Instagram</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>URL</Form.Label>
                  <Form.Control type="input" placeholder="https://" value={valueUrl} onChange={(event) => setUrl(event.target.value)} />
                  <Form.Text className="text-muted">If instagram add /?__a=1 at the end.</Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="input"
                    placeholder="Only for instagram"
                    value={valueUsername}
                    onChange={(event) => setUsername(event.target.value)}
                  />
                  <Form.Text className="text-muted">Select the instagram username.</Form.Text>
                </Form.Group>
              </Col>
            </Row>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalAdd;
