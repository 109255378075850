/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import '../../assets/style/dashboardsection.css';
import Cookies from 'universal-cookie';
import { Line, Bar } from 'react-chartjs-2';
import DashboardAPI from '../../service/api';

const cookies = new Cookies();

const data = [
  {
    title: 'Users',
    state: 'totaluser',
    color: 'dark-blue',
  },
  {
    title: 'Posts',
    state: 'totalpost',
    color: 'red',
  },
  {
    title: 'Comment',
    state: 'totalcomment',
    color: 'green',
  },
  {
    title: 'Total discount',
    state: 'totaldiscount',
    color: 'light-gray',
  },
];

class DashboardMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'My First dataset',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40],
          },
        ],
      },
      dataPost: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'My First dataset',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40],
          },
        ],
      },
      totaluser: '',
      totaldiscount: '',
      activediscount: '',
      totalpost: '',
      totalcomment: '',
    };
  }

  componentDidMount() {
    const { globalState = { userData: { uac: null } } } = this.props;
    if (globalState.userData.uac === 1) {
      this._renderFeatured(7);
    }
    if (globalState.userData.uac <= 2) {
      this._renderFeatured(1);
    }
    this._renderFeatured(2);
    this._renderFeatured(3);
    this._renderFeatured(4);
    this._renderFeatured(5);
    this._renderFeatured(6);
  }

  async _renderFeatured(type) {
    this.setState({ error: '', loadingFeatured: true });
    const token = cookies.get('token');
    const header = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await DashboardAPI.ChartAPI.chartHome(type, header);
      if (response.data.status === true) {
        type == 1
          ? this.setState({ totaluser: response.data.result[0].totalUser })
          : type == 2
          ? this.setState({ totaldiscount: response.data.result[0].totalDiscount })
          : type == 3
          ? this.setState({ activediscount: response.data.result[0].activeDiscount })
          : type == 4
          ? this.setState({ totalpost: response.data.result[0].totalPost })
          : type == 6
          ? this.setState({ listpost: response.data.result })
          : type == 7
          ? this.setState({ listregister: response.data.result })
          : this.setState({ totalcomment: response.data.result[0].totalPost });
        if (type == 6) {
          let arr = [];
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < response.data.result.length; i++) {
            arr.push(response.data.result[i].date);
          }
          let arr2 = [];
          for (let i = 0; i < response.data.result.length; i++) {
            arr2.push(response.data.result[i].count);
          }
          arr2 = arr2.reverse();
          arr = arr.reverse();
          this.setState((prevState) => ({ data: { ...prevState.data, labels: arr } }));
          this.setState((prevState) => ({
            ...prevState,
            data: {
              ...prevState.data,
              datasets: [
                {
                  label: 'Data Post',
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: 'rgba(75,192,192,0.4)',
                  borderColor: 'rgba(75,192,192,1)',
                  borderCapStyle: 'butt',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: 'rgba(75,192,192,1)',
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                  pointHoverBorderColor: 'rgba(220,220,220,1)',
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: arr2,
                },
              ],
            },
          }));
        }
        if (type == 7) {
          let arr = [];
          for (let i = 0; i < response.data.result.length; i++) {
            arr.push(response.data.result[i].date);
          }
          let arr2 = [];
          for (let i = 0; i < response.data.result.length; i++) {
            arr2.push(response.data.result[i].count);
          }
          arr = arr.reverse();
          this.setState((prevState) => ({ dataPost: { ...prevState.dataPost, labels: arr } }));
          arr2 = arr2.reverse();
          this.setState((prevState) => ({
            ...prevState,
            dataPost: {
              ...prevState.dataPost,
              datasets: [
                {
                  label: 'Data Register',
                  fill: false,
                  lineTension: 0.1,
                  backgroundColor: '#f39898',
                  borderColor: '#f39898',
                  borderCapStyle: 'butt',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBorderColor: 'rgba(75,192,192,1)',
                  pointBackgroundColor: '#fff',
                  pointBorderWidth: 1,
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: '#f39898',
                  pointHoverBorderColor: '#f39898',
                  pointHoverBorderWidth: 2,
                  pointRadius: 1,
                  pointHitRadius: 10,
                  data: arr2,
                },
              ],
            },
          }));
        }
      }
      this.setState({ loadingFeatured: false });
    } catch (error) {
      console.log(error);
      this.setState({ loadingFeatured: false });
    }
  }

  render() {
    const { globalState = { userData: { uac: null } } } = this.props;
    const { loadingFeatured } = this.state;
    return (
      <div className="container" style={{ paddingTop: 20 }}>
        {!!loadingFeatured && <div className="loading">Loading&#8230;</div>}
        <div className="row">
          {data.map((item, index) => {
            if (item.state === 'totaluser' && globalState.userData.uac !== 1) return null;
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className="col-lg-3 col-sm-6" key={index}>
                <div className="circle-tile ">
                  <a href="#">
                    <div className={`circle-tile-heading ${item.color}`}>
                      <i className="fa fa-users fa-fw fa-3x" />
                    </div>
                  </a>
                  <div className={`circle-tile-content ${item.color}`}>
                    <div className="circle-tile-description text-faded"> {item.title}</div>
                    <div className="circle-tile-number text-faded ">{this.state[item.state]}</div>
                    {/* <a className="circle-tile-footer" href="#">More Info<i className="fa fa-chevron-circle-right"></i></a> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-lg-3 col-sm-12">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="circle-tile ">
                  <a href="#">
                    <div className="circle-tile-heading blue">
                      <i className="fa fa-percent fa-fw fa-3x" />
                    </div>
                  </a>
                  <div className="circle-tile-content blue">
                    <div className="circle-tile-description text-faded"> Total Active Discount</div>
                    <div className="circle-tile-number text-faded ">{this.state.activediscount}</div>
                    {/* <a className="circle-tile-footer" href="#">More Info<i className="fa fa-chevron-circle-right"></i></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-sm-12">
            {globalState.userData.uac === 1 && (
              <div style={{ backgroundColor: 'rgb(241, 241, 241)' }}>
                <Line data={this.state.dataPost} />
              </div>
            )}
            <div style={{ backgroundColor: 'rgb(241, 241, 241)' }}>
              <Bar
                data={this.state.data}
                width={100}
                height={300}
                options={{
                  maintainAspectRatio: false,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardMain;
