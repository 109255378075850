/* eslint-disable react/destructuring-assignment */
import React from 'react';

function Content(props) {
  return (
    <div className="col-md-12 col-xl-12">
      <div className="rounded shadow wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
        <div className="col-md-12 col-xl-12 pt-3 ml-2 mr-2">
          <div className="wow fadeInRight" data-wow-delay="0.3s">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
