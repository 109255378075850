import React, { Component } from 'react';
import logowhite from '../assets/img/logo-white-left.png';

class FooterScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer className="footer bg-dark pt-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-left my-auto text-light">
              <img style={{ width: '200px' }} alt="logo" src={logowhite} />
              <p>Platform disqon terbaik dan terlengkap se-Indonesia. Akan tersedia di Play Store dan App Store!</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default FooterScreen;
