/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { MDBDataTable } from 'mdbreact';
import Cookies from 'universal-cookie';
import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';
import DashboardAPI from '../../../service/api';

const cookies = new Cookies();
const data = {
  columns: [
    {
      label: 'Id',
      field: 'id',
      sort: 'asc',
      width: 3,
    },
    {
      label: 'User ID',
      field: 'userId',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'Post ID',
      field: 'postId',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Discount ID',
      field: 'discountid',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'News ID',
      field: 'newsId',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Type',
      field: 'typeId',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Who?',
      field: 'whoId',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Report',
      field: 'content',
      sort: 'asc',
      width: 100,
    },
    {
      label: 'Created',
      field: 'created',
      sort: 'asc',
      width: 100,
    },
  ],
};

class ReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.renderReport();
  }

  renderReport = async () => {
    this.setState({ loading: true });
    const token = cookies.get('token');
    try {
      const res = await DashboardAPI.ReportAPI.list(token, 100);
      if (res.data.status === true) {
        this.setState({ loading: false });
        const result = [];
        // eslint-disable-next-line no-shadow
        res.data.result.map((data) => {
          result.push({
            id: data.id,
            userId: data.userId || ' ',
            postId: data.postId || ' ',
            discountid: data.discountid || ' ',
            newsId: data.newsId || ' ',
            typeId: data.typeId || ' ',
            whoId: data.whoId || ' ',
            content: data.content,
            created: <Moment format="DD-MMM-YYYY">{data.created || ` `}</Moment>,
          });
        });
        const resulttopush = [];
        resulttopush.columns = data.columns;
        resulttopush.rows = result;
        console.log(JSON.stringify(resulttopush));
        this.setState({
          dataRows: resulttopush,
          loading: false,
        });
      } else {
        alert('Ada masalah, silahkan coba lagi');
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  render() {
    console.log(this.props);
    return (
      <div>
        {this.state.loading === true && <div className="loading">Loading</div>}
        <div className="view" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: 10 }}>
          <div className="mask rgba-gradient align-items-center">
            <div className="container">
              <div className="row mt-5">
                <div className="col-md-12 col-xl-12 mb-0">
                  <h2 style={{ textAlign: 'left' }}>Report</h2>
                </div>
                <div className="col-md-12 col-xl-12 mb-0">
                  <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
                    <div className="card-body">
                      <MDBDataTable striped bordered hover data={this.state.dataRows} responsive />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReportList);
