import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const ButtonRegular = ({ style, children, onClick }) => (
  <Button color="default" rounded="true" size="sm" style={{ backgroundColor: '#5EC5CC', color: '#fff', ...style }} onClick={onClick}>
    {children}
  </Button>
);

ButtonRegular.propTypes = {
  style: PropTypes.shape({ color: PropTypes.string, backgroundColor: PropTypes.string }),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  onClick: PropTypes.func.isRequired,
};

ButtonRegular.defaultProps = {
  style: {},
  children: '',
};
