/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import Select from 'react-select';
import qs from 'qs';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const cat = [
  { value: 1, label: 'Makanan' },
  { value: 2, label: 'Fashion' },
  { value: 3, label: 'Hiburan' },
  { value: 4, label: 'Travel' },
  { value: 5, label: 'Elektronik' },
  { value: 6, label: 'Kecantikan' },
  { value: 7, label: 'Otomotif' },
  { value: 8, label: 'Kesehatan' },
  { value: 9, label: 'Peliharaan' },
  { value: 10, label: 'Layanan' },
  { value: 11, label: 'Katalog' },
  { value: 12, label: 'Online' },
  { value: 13, label: 'Transportasi' },
  { value: 14, label: 'Bank dan E-Money' },
  { value: 15, label: 'Marketplace' },
  { value: 16, label: 'Hobi' },
];

class UploadImgScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catSelected: [],
      dataBrand: [],
      newBrand: [],
    };
    this.renderBrand = this.renderBrand.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.renderBrand();
    /* this.renderPlace(); */
  }

  /* renderPlace = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    axios
      .post(`${BASE_URL}api/showplaceall`)
      .then((res) => {
        this.setState({
          dataPlace: res.data.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  } */

  renderBrand = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    axios
      .post(`${BASE_URL}api/showbrandweb`)
      .then((res) => {
        this.setState({
          dataBrand: res.data.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleChangeOptCat = (selectedOption) => {
    this.setState({ catSelected: selectedOption });
    console.log(`Cat selected:`, selectedOption);
  };

  handleChangeOpt2 = (selectedOption) => {
    this.setState({ newBrand: selectedOption });
    console.log(`Option selected:`, selectedOption.value);
  };

  onSubmit(event) {
    event.preventDefault();
    console.log(this.state.dataBrand);
    console.log(this.state.newBrand);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.state.newBrand.length; i++) {
      if (this.state.dataBrand.find((x) => x.label === this.state.newBrand[i].label)) {
        console.log(`${this.state.newBrand[i].label} exist`);
      } else {
        const headers = new Headers({
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Access-Control-Allow-Origins': 'http://localhost:3000',
          'Content-Type': 'application/json',
        });
        const data = qs.stringify({
          storeName: this.state.dataBrand[i].label,
          cat: this.state.catSelected,
        });
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        axios
          .post(`${BASE_URL}api/addstore`, data, { headers })
          .then(function (response) {
            console.log(response);
            if (response.data.message === 'Success') {
              alert('Berhasil menambahkan store');
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }

  render() {
    return (
      <div>
        <div className="view" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: 50 }}>
          <div className="mask rgba-gradient align-items-center">
            <div className="container">
              <div className="row mt-5">
                <div className="col-md-8 col-xl-8 mb-4">
                  <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
                    <div className="card-body">
                      <h2>Manage Images</h2>
                      <hr className="pb-3" />
                      <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data">
                        <div className="col-md-12 mx-auto">
                          <div className="form-group pb-3">
                            <Select
                              required
                              name="catSelected"
                              options={cat}
                              value={this.state.catSelected}
                              onChange={this.handleChangeOptCat}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                            <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', top: '-30px', fontSize: 12 }}>
                              <i className="fas fa-user prefix white-text active pr-2" /> Kategori
                            </label>
                          </div>
                          <button className="btn btn-primary btn-block" type="submit" style={{ backgroundColor: '#27A4DD', color: '#fff' }}>
                            Submit
                          </button>
                        </div>
                      </form>
                      <div className="text-center m1-4">
                        {/* this.state.show === true && 
                                <button className="btn btn-indigo btn-block"  type="button" style={{backgroundColor:'#27A4DD', color:'#fff'}}>
                                    Pay
                                </button> */}
                        <hr className="hr-light mb-3 mt-4" />
                        <div className="inline-ul text-center">
                          <i className="fab fa-twitter white-text" />
                          <i className="fab fa-linkedin-in white-text"> </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 mb-5 mt-md-0 mt-5 text-light text-right" style={{ paddingTop: '70px' }}>
                  <h1
                    className="h1-responsive font-weight-bold wow fadeInLeft"
                    data-wow-delay="0.3s"
                    style={{ backgroundColor: 'rgba(29, 28, 28, 0.61)', borderRadius: '10px', padding: '5px' }}
                  >
                    Submit store!{' '}
                  </h1>
                  <hr className="hr-light wow fadeInLeft" data-wow-delay="0.3s" />
                  <h6
                    className="mb-3 wow fadeInLeft"
                    data-wow-delay="0.3s"
                    style={{ backgroundColor: 'rgba(29, 28, 28, 0.61)', borderRadius: '10px', padding: '15px' }}
                  >
                    Cuku dengan mengisi brand, kategori, titile dan lainnya
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UploadImgScreen;
