/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import Creatable from 'react-select/lib/Creatable';
import { Modal } from 'react-bootstrap';
import Select, { createFilter } from 'react-select';
import { connect } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import Axios from 'axios';
import { fetchAllStoreWeb, fetchAllStore } from '../../../../redux/dispatchers/storeDispatcher';

const cat = [
  { value: 1, label: 'Makanan' },
  { value: 2, label: 'Fashion' },
  { value: 3, label: 'Hiburan' },
  { value: 4, label: 'Travel' },
  { value: 5, label: 'Elektronik' },
  { value: 6, label: 'Kecantikan' },
  { value: 7, label: 'Otomotif' },
  { value: 8, label: 'Kesehatan' },
  { value: 9, label: 'Peliharaan' },
  { value: 10, label: 'Layanan' },
  { value: 11, label: 'Katalog' },
  { value: 12, label: 'Online' },
  { value: 13, label: 'Transportasi' },
  { value: 14, label: 'Bank dan E-Money' },
  { value: 15, label: 'Marketplace' },
  { value: 16, label: 'Hobi' },
];

function ModalAddStore(props) {
  const { show = false, onHide = () => null, storeListWeb = [] } = props;

  const [newBrand, setNewBrand] = useState('');
  const [catSelected, setCatSelected] = useState('');

  useEffect(() => {
    if (storeListWeb.length === 0) {
      props.fetchAllStoreWeb();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmit = (event) => {
    event.preventDefault();
    // eslint-disable-next-line no-unused-vars
    const headers = new Headers({
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Origin': 'http://localhost:3000',
      'Access-Control-Allow-Origins': 'http://localhost:3000',
      'Content-Type': 'application/json',
    });
    const data = {
      store: newBrand,
      catId: catSelected.value,
    };
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    Axios.post(`${BASE_URL}dashboard/addstorenew`, data, { headers })
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          alert('Berhasil menambahkan store');
          props.fetchAllStore();
          onHide();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add new Store</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit} method="post" encType="multipart/form-data">
          <div className="col-md-12 mx-auto">
            {/* 
                        <div className="form-group pb-3">
                            <Select
                                required
                                isMulti
                                name="store"
                                options={this.state.dataBrand}
                                value={this.state.brandSelected}
                                onChange={this.handleChangeOpt}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                                <label className="form-control-placeholder" htmlFor="discount" style={{left: '-10px', top:'-30px', fontSize:12}}>
                                    <i className="fas fa-user prefix white-text active pr-2"></i> Brand
                                </label>
                        </div> */}
            <div className="form-group pb-3">
              <Creatable
                required
                isMulti
                name="type"
                options={storeListWeb}
                filterOption={createFilter({ ignoreAccents: false })}
                value={newBrand}
                onChange={(val) => setNewBrand(val)}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', top: '-30px', fontSize: 12 }}>
                <i className="fas fa-user prefix white-text active pr-2" /> Tambah Baru
              </label>
            </div>
            <div className="form-group pb-3">
              <Select
                required
                name="catSelected"
                options={cat}
                value={catSelected}
                onChange={(val) => setCatSelected(val)}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', top: '-30px', fontSize: 12 }}>
                <i className="fas fa-user prefix white-text active pr-2" /> Kategori
              </label>
            </div>
            <button className="btn btn-primary btn-block" type="submit" style={{ backgroundColor: '#27A4DD', color: '#fff' }}>
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = ({ store }) => {
  const { storeListWeb } = store;
  return { storeListWeb };
};

export default connect(mapStateToProps, { fetchAllStore, fetchAllStoreWeb })(ModalAddStore);
