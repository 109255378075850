import Cookies from 'universal-cookie';
import update from 'immutability-helper';
import {
  fetchingAllLatestPromo,
  fetchingAllLatestPromoFailed,
  fetchingAllLatestPromoSuccess,
  removeLatestPromoThirdParty,
  fetchingAllSearchPromo,
  fetchingAllSearchPromoSuccess,
  fetchingAllSearchPromoFailed,
} from '../actions/thirdPartyactions';
import API from '../../service/api';

const cookies = new Cookies();

// eslint-disable-next-line import/prefer-default-export
export const fetchThirdPartylatestPromo = () => {
  return async (dispatch, getState) => {
    const { thirdPartyPromoLoading } = getState().thirdParty;
    if (!thirdPartyPromoLoading) {
      dispatch(fetchingAllLatestPromo());
      try {
        const token = cookies.get('token');
        if (!token) {
          throw new Error('Invalid Token');
        }
        const result = await API.ThirdPartyAPI.getLatestPoinin5Pages();
        dispatch(fetchingAllLatestPromoSuccess(result || []));
        return result;
      } catch (error) {
        dispatch(fetchingAllLatestPromoFailed(error));
        throw error;
      }
    } else {
      return null;
    }
  };
};

export const fetchThirdPartySearchPromo = (term) => {
  return async (dispatch, getState) => {
    const { thirdPartyPromoLoading } = getState().thirdParty;
    if (!thirdPartyPromoLoading) {
      dispatch(fetchingAllSearchPromo());
      try {
        const token = cookies.get('token');
        if (!token) {
          throw new Error('Invalid Token');
        }
        const result = await API.ThirdPartyAPI.getSearchPoinin(term);
        dispatch(fetchingAllSearchPromoSuccess(result || []));
        return result;
      } catch (error) {
        dispatch(fetchingAllSearchPromoFailed(error));
        throw error;
      }
    } else {
      return null;
    }
  };
};

export const removeThirdPartyLatestPromo = (id) => {
  return async (dispatch, getState) => {
    const { thirdPartyPromo = [] } = getState().thirdParty;
    if (thirdPartyPromo.length > 0) {
      const findIndex = thirdPartyPromo.findIndex((a) => a.id === id);
      const thirdPartyPromoTemp = update(thirdPartyPromo, { $splice: [[findIndex, 1]] });
      dispatch(removeLatestPromoThirdParty(thirdPartyPromoTemp));
      return true;
    }
    return null;
  };
};
