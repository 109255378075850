/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
// eslint-disable-next-line import/no-duplicates
import Select from 'react-select';
// eslint-disable-next-line import/no-duplicates
import { Creatable } from 'react-select';

import qs from 'qs';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import Cookies from 'universal-cookie';
// eslint-disable-next-line no-unused-vars
import MapsView from '../components/home';

const cookies = new Cookies();

const doType = [
  { value: 'addstore', label: 'Modify/Add Store' },
  { value: 'editplace', label: 'Edit Place Data' },
  { value: 'addplace', label: 'Add New Place' },
];

const placeType = [
  { value: '1', label: 'Mall' },
  { value: '2', label: 'Airport' },
  { value: '3', label: 'Stasiun' },
];

class AddPlaceStoreScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandSelected: [],
      dataBrand: [],
      dataPlace: [],
      placeSelected: '',
      placeDetail: [],
      doType: { value: 'addstore', label: 'Modify/Add Store' },
      placeTypeSelected: { value: '1', label: 'Mall' },
      AddEditPlaceName: '',
      AddEditPlaceAddress: '',
      latitude: '',
      longitude: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.renderBrand = this.renderBrand.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitNewPlace = this.onSubmitNewPlace.bind(this);
    this.renderGetPlaceData = this.renderGetPlaceData.bind(this);
  }

  componentDidMount() {
    this.renderBrand();
    this.renderPlace();
  }

  renderPlace() {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    axios
      .post(`${BASE_URL}api/showplaceall`)
      .then((res) => {
        this.setState({
          dataPlace: res.data.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  renderBrand() {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    axios
      .post(`${BASE_URL}api/showbrandweb`)
      .then((res) => {
        this.setState({
          dataBrand: res.data.data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  renderSelectedBrand(id) {
    console.log(id);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    axios
      .post(`${BASE_URL}api/showstorebyplace`, {
        placeId: id,
      })
      .then((res) => {
        console.log(res);
        const { data } = res.data;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < data.length; i++) {
          data[i].value = data[i].storeId;
          delete data[i].storeId;
        }
        this.setState({
          brandSelected: data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  renderGetPlaceData = (placeId) => {
    const placeData = {
      placeId,
    };
    const token = cookies.get('token');
    const header = {
      Authorization: `Bearer ${token}`,
    };
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    axios({
      method: 'post',
      url: `${BASE_URL}dashboard/placedetail`,
      data: qs.stringify(placeData),
      headers: header,
    })
      .then(function (response) {
        console.log(response.data.result);
        if (response.data.status === true) {
          this.setState({
            AddEditPlaceName: response.data.result.name,
            AddEditPlaceAddress: response.data.result.address,
            placeCatId: response.data.result.placeCatId,
            latitude: response.data.result.lat,
            longitude: response.data.result.lng,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  handleChange = (event, type) => {
    console.log('EVENT', event);
    this.setState({
      [type]: event,
    });
  };

  handleChangeOpt = (selectedOption) => {
    this.setState({ brandSelected: selectedOption });
    console.log(`Option selected:`, selectedOption);
  };

  handleChangeType = (selectedOption) => {
    this.setState({ doType: selectedOption });
    if (selectedOption.value === 'addplace') {
      this.setState({
        placeSelected: '',
        placeDetail: [],
        AddEditPlaceName: '',
        AddEditPlaceAddress: '',
        placeCatId: 1,
        latitude: '',
        longitude: '',
      });
    }
    console.log(`Option selected11:`, selectedOption);
  };

  handleChangeOpt2 = (selectedOption) => {
    this.setState({ placeSelected: selectedOption });
    console.log(`Option selected:`, selectedOption.value);
    console.log(`Option selected:`, selectedOption.toString());
    this.renderGetPlaceData(selectedOption.value);
    this.renderSelectedBrand(selectedOption.value);
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const token = cookies.get('token');
    const dataAddStore = qs.stringify({
      placeId: this.state.placeSelected.value,
      store: this.state.brandSelected,
    });
    const dataEditPlace = qs.stringify({
      name: this.state.AddEditPlaceName,
      placeCatId: this.state.placeTypeSelected.value,
      address: this.state.AddEditPlaceAddress,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      placeId: this.state.placeSelected.value,
    });
    const placeId = this.state.placeSelected.value;
    console.log('test');
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    axios
      .post(
        this.state.doType.value === 'addstore' ? `${BASE_URL}api/addstorebyplace` : `${BASE_URL}dashboard/placeupdate`,
        this.state.doType.value === 'addstore' ? dataAddStore : dataEditPlace,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      // eslint-disable-next-line consistent-return
      .then((response) => {
        console.log(response);
        console.log('masuk');
        if (response.data.status === true) {
          if (this.state.doType === 'addstore') {
            const dataAdd = qs.stringify({
              placeId,
              store: response.data.data,
            });
            axios
              .post(`${BASE_URL}api/addstorebyplace`, dataAdd, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } })
              // eslint-disable-next-line no-shadow
              .then((response) => {
                console.log(response);
                if (response.data.status === true) {
                  alert('Berhasil menambahkan store');
                  // window.location.reload()
                }
                this.setState({ loading: false });
              })
              .catch(function (error) {
                console.log(error);
                this.setState({ loading: false });
              });
          } else {
            alert('Berhasil');
            this.setState({ loading: false });
            window.location.reload();
            return true;
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        alert('ada masalah, mohon coba lagi');
        this.setState({ loading: false });
        // window.location.reload()
      });
  };

  onSubmitNewPlace = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const token = cookies.get('token');
    const dataAddPlaceNew = qs.stringify({
      name: this.state.AddEditPlaceName,
      placeCatId: this.state.placeTypeSelected.value,
      address: this.state.AddEditPlaceAddress,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    });
    const form = document.querySelector('form');
    const data = new FormData(form);
    data.append('name', this.state.AddEditPlaceName);
    data.append('placeCatId', this.state.placeTypeSelected.value);
    data.append('address', this.state.AddEditPlaceAddress);
    data.append('latitude', this.state.latitude);
    data.append('longitude', this.state.longitude);
    console.log(data);
    if (document.getElementById('recfile').files[0].size < 250000) {
      const BASE_URL = process.env.REACT_APP_BASE_URL;
      axios
        .post(`${BASE_URL}dashboard/placeadd`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          console.log(response);
          console.log('masuk');
          if (response.data.status === true) {
            alert('succeed');
          }
          this.setState({ loading: false });
          return true;
        })
        .catch(function (error) {
          this.setState({ loading: false });
          console.log(dataAddPlaceNew);
          console.log(error);
          /* alert('ada masalah, mohon coba lagi') */
        });
    } else {
      alert('File is too big');
      this.setState({ loading: false });
    }
  };

  render() {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    return (
      <div className="view" style={{ height: '100%', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: 50 }}>
        {this.state.loading === true && <div className="loading">Loading&#8230;</div>}
        <div className="mask rgba-gradient align-items-center">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12 col-xl-12">
                <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
                  <div className="card-body">
                    <h2>Manage Place</h2>
                    <hr className="pb-3" />
                    <form
                      onSubmit={this.state.doType.value === 'addplace' ? this.onSubmitNewPlace : this.onSubmit}
                      method="post"
                      encType="multipart/form-data"
                    >
                      <div className="col-md-12 mx-auto">
                        <div className="form-group pb-3" style={{ backgroundColor: '#FF6C6C', padding: 15, marginBottom: 40, borderRadius: 10 }}>
                          <Select
                            required
                            name="type"
                            options={doType}
                            value={this.state.doType}
                            onChange={this.handleChangeType}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                          <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', top: '-30px', fontSize: 15 }}>
                            <i className="fas fa-user prefix white-text active pr-2" /> What Do You Want To Do?
                          </label>
                        </div>
                        {this.state.doType.value === 'addstore' ? (
                          <>
                            <div className="form-group pb-3">
                              <Select
                                required
                                name="type"
                                options={this.props.globalState.listplace}
                                value={this.state.placeSelected}
                                onChange={this.handleChangeOpt2}
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                              <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', top: '-30px', fontSize: 12 }}>
                                <i className="fas fa-user prefix white-text active pr-2" /> Place
                              </label>
                            </div>
                            <div className="form-group pb-2">
                              <Creatable
                                required
                                isMulti
                                name="store"
                                options={this.state.dataBrand}
                                value={this.state.brandSelected}
                                onChange={this.handleChangeOpt}
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                              <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', top: '-30px', fontSize: 12 }}>
                                <i className="fas fa-user prefix white-text active pr-2" /> Brand
                              </label>
                            </div>
                            <div className="form-group pb-3">
                              <input
                                type="text"
                                name="totalStoreInPlacec"
                                id="totalStoreInPlacec"
                                className="form-control"
                                disabled
                                value={`TOTAL BRAND ${this.state.brandSelected.length}`}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            {this.state.doType.value === 'editplace' && (
                              <div className="form-group pb-3">
                                <Select
                                  required
                                  name="type"
                                  options={this.state.dataPlace}
                                  value={this.state.placeSelected}
                                  onChange={this.handleChangeOpt2}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                />
                                <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', top: '-30px', fontSize: 12 }}>
                                  <i className="fas fa-user prefix white-text active pr-2" /> Place
                                </label>
                              </div>
                            )}
                            <div className="form-group pb-3">
                              <input
                                type="text"
                                name="placeName"
                                id="placeName"
                                className="form-control"
                                onChange={(event) => this.handleChange(event.target.value, 'AddEditPlaceName')}
                                value={this.state.AddEditPlaceName}
                                required
                              />
                              <label className="form-control-placeholder" htmlFor="placeName">
                                <i className="fas fa-marker prefix white-text active pr-2" /> Add / Change Place Name
                              </label>
                            </div>
                            <div className="form-group pb-3">
                              <input
                                type="text"
                                name="placeName"
                                id="placeName"
                                className="form-control"
                                onChange={(event) => this.handleChange(event.target.value, 'AddEditPlaceAddress')}
                                value={this.state.AddEditPlaceAddress}
                                required
                              />
                              <label className="form-control-placeholder" htmlFor="placeName">
                                <i className="fas fa-envelope-square prefix white-text active pr-2" /> Address
                              </label>
                            </div>
                            <div className="form-group pb-3">
                              <Select
                                required
                                name="placeTypeSelected"
                                options={placeType}
                                value={this.state.placeTypeSelected}
                                onChange={(event) => this.handleChange(event, 'placeTypeSelected')}
                                className="basic-multi-select"
                                classNamePrefix="select"
                              />
                              <label
                                className="form-control-placeholder"
                                htmlFor="placeTypeSelected"
                                style={{ left: '-10px', top: '-30px', fontSize: 12 }}
                              >
                                <i className="fas fa-user prefix white-text active pr-2" /> Place
                              </label>
                            </div>
                            <div className="form-group pb-3 row">
                              <div className="col">
                                <input
                                  type="text"
                                  name="placeLat"
                                  id="placeLat"
                                  className="form-control"
                                  onChange={(event) => this.handleChange(event.target.value, 'latitude')}
                                  value={this.state.latitude}
                                  required
                                />
                                <label className="form-control-placeholder pl-4" htmlFor="placeLat">
                                  <i className="fas fa-location-arrow prefix white-text active pr-2" /> Location Latitude
                                </label>
                              </div>
                              <div className="col">
                                <input
                                  type="text"
                                  name="placeLong"
                                  id="placeLong"
                                  className="form-control"
                                  onChange={(event) => this.handleChange(event.target.value, 'longitude')}
                                  value={this.state.longitude}
                                  required
                                />
                                <label className="form-control-placeholder pl-4" htmlFor="placeLong">
                                  <i className="fas fa-location-arrow prefix white-text active pr-2" /> Location Longitude
                                </label>
                              </div>
                            </div>

                            {this.state.doType.value === 'addplace' && (
                              <div className="form-group row">
                                <div className="col-3">
                                  <label className="switch" style={{ float: 'left' }}>
                                    <input type="checkbox" name="isFeatured" />
                                    <span className="slider round"> </span>
                                  </label>
                                  <span style={{ fontSize: 18, fontWeight: 'bold' }}>Add New Image?</span>
                                </div>

                                <div className="col-3">
                                  {this.state.placeDetail.img && (
                                    <img
                                      alt="place"
                                      src={
                                        this.state.placeDetail.img.substr(0, 4) === 'http'
                                          ? this.state.placeDetail.img
                                          : `${BASE_URL}placeimg/${this.state.placeDetail.img}`
                                      }
                                    />
                                  )}
                                </div>
                                <div className="col-6">
                                  <input
                                    type="file"
                                    id="recfile"
                                    name="recfile"
                                    className="form-control"
                                    placeholder="Select file"
                                    accept="image/*"
                                    onChange={this.handleselectedFile}
                                  />
                                </div>
                              </div>
                            )}
                            {/* <div className="form-group pb-3">
                                                            <MapsView/>
                                                        </div> */}
                          </>
                        )}
                        <button className="btn btn-primary btn-block" type="submit" style={{ backgroundColor: '#27A4DD', color: '#fff' }}>
                          {this.state.doType.value === 'addstore' ? 'Add Store' : 'Add/Edit Place'}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddPlaceStoreScreen;
