import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

async function list(token, limit) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const url = `${BASE_URL}dashboard/user/list`;
  const response = await axios({
    method: 'get',
    url,
    headers,
    params: {
      limit: limit || 100,
    },
  });
  console.log(response);
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function search(token, keyword, limit) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const url = `${BASE_URL}dashboard/user/search`;
  const response = await axios({
    method: 'get',
    url,
    headers,
    params: {
      keyword,
      limit: limit || 20,
    },
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

const UserAPI = {
  list,
  search,
};

export default UserAPI;
