/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Modal, Button, FormControl, InputGroup } from 'react-bootstrap';
import SelectComponent from '../form/select';

const ModalNotification = (props) => {
  const {
    modalVisible,
    modalId,
    modalTitle,
    modalTitleFixed,
    modalType,
    modalContent,
    notifyToAll,
    notifyToAllToggle,
    notifyToFollowers,
    notifyToFollowersToggle,
    loading,
    onHide,
    sendNotif,
    changeModalTitle,
    changeModalContent,
    extend = false,
    brandSelected,
    handleOnChangeBrand,
    liststore,
  } = props;
  return (
    <Modal show={modalVisible} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Send Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3 mt-3">
          <FormControl
            placeholder="Ex: Ayo Buruan Promo Gopay 20%"
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={modalTitle}
            onChange={changeModalTitle}
          />
          <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', fontSize: 12 }}>
            <i className="fas fa-user prefix white-text active pr-2" /> Heading
          </label>
        </InputGroup>

        <InputGroup className="mb-3 row">
          <div className="form-group pb-1 col">
            <label className="switch" style={{ float: 'left' }}>
              <input type="checkbox" name="notifyToAll" onClick={notifyToAllToggle} checked={notifyToAll} />
              <span className="slider round"> </span>
            </label>
            <span style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, marginLeft: 20 }}>Notify to All?</span>
          </div>
          <div className="form-group pb-1 col">
            <label className="switch" style={{ float: 'left' }}>
              <input type="checkbox" name="notifyToFollowers" onClick={notifyToFollowersToggle} checked={notifyToFollowers} />
              <span className="slider round"> </span>
            </label>
            <span style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, marginLeft: 20 }}>Notify to Followers?</span>
          </div>
        </InputGroup>

        <InputGroup className="mb-5">
          <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', fontSize: 12 }}>
            <i className="fas fa-user prefix white-text active pr-2" /> Content
          </label>
          <SelectComponent
            isMulti
            name="store"
            id="store"
            options={liststore || []}
            value={brandSelected}
            onChange={handleOnChangeBrand}
            param="brandSelected"
            iClassName="fas fa-user prefix white-text active pr-2"
            title=" Brand"
            classNameSelect="w-100"
          />
        </InputGroup>
        <InputGroup>
          <FormControl as="textarea" aria-label="With textarea" value={modalContent} onChange={changeModalContent} />
          <label className="form-control-placeholder" htmlFor="discount" style={{ left: '5px', fontSize: 12 }}>
            <i className="fas fa-user prefix white-text active pr-2" /> Content
          </label>
        </InputGroup>
        <InputGroup className="mb-3 mt-5 row">
          <div className="form-group pb-1 col">
            <FormControl
              placeholder="Ex: Ayo Buruan Promo Gopay 20%"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={`ID: ${modalId}`}
              disabled
            />
          </div>
          <div className="form-group pb-1 col">
            <FormControl
              placeholder="Ex: Ayo Buruan Promo Gopay 20%"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={`Title: ${modalTitleFixed}`}
              disabled
            />
          </div>
          <div className="form-group pb-1 col">
            <FormControl
              placeholder="Ex: Ayo Buruan Promo Gopay 20%"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={`Type: ${modalType}`}
              disabled
            />
          </div>
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        {!!extend && (
          <Button onClick={onHide} variant="danger">
            Check Total Followers
          </Button>
        )}
        {loading === true ? (
          <h3>Loading...</h3>
        ) : (
          <Button onClick={sendNotif} variant="info">
            Send Notification
          </Button>
        )}
        <Button onClick={onHide} variant="danger">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalNotification;
