/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactQuill from 'react-quill';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router-dom';
import DashboardAPI from '../../service/api';

const cookies = new Cookies();
class AddNewsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      source: '',
      img: '',
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
  }

  componentDidMount = () => {
    if (this.props.update) {
      this.renderUpdate(this.props.match.params.id);
    }
  };

  // eslint-disable-next-line no-unused-vars
  renderUpdate = async (id) => {
    if (!this.props.location.state) {
      alert('Please access it through news list');
      this.props.history.push('/dashboard/news');
    } else {
      const { title, content, img, source } = this.props.location.state;
      console.log({
        title,
        content,
        img,
        source,
      });
      this.setState({
        title,
        description: content,
        source,
        img,
      });
    }
  };

  handleChange = (type, event) => {
    console.log(event);
    this.setState({
      [type]: event,
    });
  };

  onSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const { update = false } = this.props;
    const data = {
      title: this.state.title,
      name: this.state.title,
      img: this.state.img,
      content: this.state.description,
      source: this.state.source,
    };
    const token = cookies.get('token');
    const header = {
      Authorization: `Bearer ${token}`,
    };
    if (update) {
      data.id = this.props.location.state.id;
    }

    try {
      const response = await DashboardAPI.NewsAPI.post(header, data, update);
      if (response.data.message === 'Success') {
        this.props.history.goBack();
        this.setState({ description: '' });
        alert(`Berhasil ${update ? 'mengupdate' : 'menambahkan'} news`);
        document.getElementById('formPost').reset();
        this.setState({ loading: false });
      } else {
        this.setState({ loading: false });
      }
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  handleLoading = () => {
    this.setState({ loading: false });
  };

  render() {
    const { title, description, img, source } = this.state;
    return (
      <div>
        {this.state.loading === true && <div className="loading">Loading&#8230;</div>}
        <div className="view" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: 50 }}>
          <div className="mask rgba-gradient align-items-center">
            <div className="container">
              <h2>Submit News</h2>
              <form onSubmit={this.onSubmit} method="post" encType="multipart/form-data" id="formPost">
                <div className="row mt-5">
                  <div className="col-md-8 col-xl-8 mb-4">
                    <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
                      <div className="card-body">
                        <hr className="pb-3" />
                        <div className="col-md-12 mx-auto">
                          <div className="form-group pb-3">
                            <input
                              type="text"
                              name="title"
                              id="title"
                              className="form-control"
                              value={title}
                              onChange={(event) => this.setState({ title: event.target.value })}
                              required
                            />
                            <label className="form-control-placeholder" htmlFor="title">
                              <i className="fas fa-envelope-square prefix white-text active pr-2" /> Judul
                            </label>
                          </div>
                          <div className="form-group pb-3">
                            <label htmlFor="amount"> Deskripsi</label>
                            <ReactQuill
                              defaultValue={description}
                              value={this.state.description}
                              name="description"
                              onChange={(event) => this.handleChange('description', event)}
                            />
                            {/* <textarea className="form-control" name="description" rows="9" id="comment" placeholder={'Deskripsi'} required></textarea> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xl-4 mb-4">
                    <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0', padding: 10, paddingTop: 40 }}>
                      <div className="form-group pb-3">
                        <input
                          type="text"
                          id="source"
                          name="source"
                          className="form-control"
                          value={source}
                          onChange={(event) => this.setState({ source: event.target.value })}
                        />
                        <label className="form-control-placeholder" htmlFor="source">
                          <i className="fas fa-link prefix white-text active pr-2" /> Link (optional)
                        </label>
                      </div>
                      <div className="form-group pb-3">
                        <input
                          type="text"
                          id="img"
                          name="img"
                          className="form-control"
                          value={img}
                          onChange={(event) => this.setState({ img: event.target.value })}
                        />
                        <label className="form-control-placeholder" htmlFor="img">
                          <i className="fas fa-image prefix white-text active pr-2" /> Url Image
                        </label>
                      </div>
                      <button className="btn btn-primary btn-block" type="submit" style={{ backgroundColor: '#27A4DD', color: '#fff' }}>
                        {this.props.update ? 'Update' : 'Submit'}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddNewsScreen);
