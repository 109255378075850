import {
  FETCH_ALL_DISCOUNT,
  FETCH_ALL_DISCOUNT_FAILED,
  FETCH_ALL_DISCOUNT_SUCCESS,
  UPDATE_DISCOUNT_SUCCESS,
  SET_DISCOUNT_SEARCH_SUCCESS,
  SET_DISCOUNT_SEARCH_FAILED,
  SET_DISCOUNT_SEARCH,
  CLEAR_DISCOUNT_SEARCH,
} from '../actions/discountActions';

const storeInitialState = {
  discountLoading: false,
  discountError: null,
  discountList: [],
  discountLastFetched: null,

  discountSearchText: '',
  discountSearchLoading: false,
  discountSearchList: [],
  discountSearchError: null,
};

export default (state = storeInitialState, action) => {
  switch (action.type) {
    case FETCH_ALL_DISCOUNT:
      return { ...state, discountLoading: true, discountError: null };
    case FETCH_ALL_DISCOUNT_SUCCESS:
      return { ...state, discountLoading: false, discountList: action.payload, discountLastFetched: action.date };
    case FETCH_ALL_DISCOUNT_FAILED:
      return { ...state, discountLoading: false, discountError: action.payload };

    case SET_DISCOUNT_SEARCH:
      return { ...state, discountSearchLoading: true, discountSearchText: action.payload };
    case SET_DISCOUNT_SEARCH_SUCCESS:
      return { ...state, discountSearchLoading: false, discountSearchError: null, discountSearchList: action.payload };
    case SET_DISCOUNT_SEARCH_FAILED:
      return { ...state, discountSearchLoading: false, discountSearchError: action.payload };
    case CLEAR_DISCOUNT_SEARCH:
      return { ...state, discountSearchLoading: false, discountSearchText: '', discountSearchList: [] };

    case UPDATE_DISCOUNT_SUCCESS:
      return { ...state, discountList: action.payload };
    default:
      return state;
  }
};
