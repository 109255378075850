/* eslint-disable no-else-return */
/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Modal, Button, FormControl, InputGroup } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import qs from 'qs';
import axios from 'axios';
import SelectComponent from '../form/select';

const cookies = new Cookies();

function ModalNotificationV2(props) {
  const {
    modalVisible,
    modalId,
    modalTitle,
    modalTitleFixed,
    modalType,
    modalContent,
    notifyToAll,
    notifyToAllToggle,
    notifyToFollowers,
    notifyToFollowersToggle,
    onHide,
    sendNotif,
    changeModalTitle,
    changeModalContent,
    extend = false,
    liststore,
    brandSelected = [],
    modalImg,
  } = props;

  const [state, setState] = useState({
    brandFollowers: props.brandFollowers,
    modalImg: props.modalImg,
    modalType: props.modalType,
    modalId: props.modalId,
    modalTitleFixed: props.modalTitleFixed,
    modalTitle: props.modalTitle,
    modalContent: props.modalContent,
  });

  const [loading, setLoading] = useState(false);

  const [notifBrandSelected, setNotifBrandSelected] = useState(brandSelected);

  const [brandFollowers, setBrandFollowers] = useState([]);

  useEffect(() => {
    setState({
      modalImg: props.modalImg,
      modalType: props.modalType,
      modalId: props.modalId,
      modalTitleFixed: props.modalTitleFixed,
      modalTitle: props.modalTitle,
      modalContent: props.modalContent,
    });
    setNotifBrandSelected(brandSelected);
    setBrandFollowers(props.brandFollowers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandSelected, modalImg, props.brandFollowers]);

  const checkFollowers = async (withSend = false, showAlert = false) => {
    // eslint-disable-next-line camelcase
    const result_array = [];
    // eslint-disable-next-line array-callback-return
    notifBrandSelected.map((data) => {
      result_array.push(data.value.toString());
    });
    const token = cookies.get('token');
    const header = {
      Authorization: `Bearer ${token}`,
    };
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    axios
      .get(`${BASE_URL}dashboard/v2/storefollowers`, {
        params: {
          storeId: result_array.toString().split(',').join(','),
        },
        headers: header,
      })
      // eslint-disable-next-line consistent-return
      .then(async (response) => {
        const tempData = [];
        console.log({ response });
        if (response.status === 200) {
          const data = response.data.result;
          if (data && data.length > 0) {
            await data.map((item) => tempData.push(item.id));
            if (showAlert) {
              alert(`Total followers ${tempData.length <= 0 ? 0 : tempData.length}`);
            }
            setBrandFollowers(tempData);
            if (withSend) {
              sendNotifFromFunction(tempData);
            }
            setTimeout(() => {
              return tempData;
            }, 1000);
          } else {
            setBrandFollowers([]);
            alert(`No followers`);
            if (withSend) {
              sendNotifFromFunction(tempData);
            }
            setTimeout(() => {
              return [];
            }, 500);
          }
        }
      })
      .catch((err) => {
        console.log('ERR', err);
        throw err;
      });
  };

  const checkFollowersAndSend = async () => {
    try {
      setLoading(true);
      await checkFollowers(true);
    } catch (err) {
      console.log({ err });
      alert('Error');
      setLoading(false);
    }
  };

  const sendNotifFromFunction = async (followers = undefined) => {
    // eslint-disable-next-line no-shadow
    const { modalImg } = state;
    if (modalImg) {
      if (notifyToAll === true) {
        const data = {
          app_id: '942046ea-d755-4f5c-9384-ea20410a0fb9',
          included_segments: ['All'],
          data: { type: modalType, id: modalId, notif: 'true', title: modalTitleFixed },
          big_picture: modalImg,
          large_icon: modalImg,
          headings: { en: modalTitle },
          contents: { en: modalContent },
          filters: [{ field: 'tag', key: 'pushnotif', relation: '!=', value: '0' }],
        };
        console.log('DATA NOTIF ALL', data);
        postNotif('all', data);
      } else if (notifyToFollowers === true) {
        const data = {
          app_id: '942046ea-d755-4f5c-9384-ea20410a0fb9',
          include_external_user_ids: followers.map((a) => `${a}`) || brandFollowers.map((a) => `${a}`),
          data: { type: modalType, id: modalId, notif: 'true', title: modalTitleFixed },
          big_picture: modalImg,
          large_icon: modalImg,
          headings: { en: modalTitle },
          contents: { en: modalContent },
        };
        console.log('DATA NOTIF FOLL', data);
        postNotif('followers', data);
      } else {
        console.log('SEND TO NONE');
      }
    } else {
      alert('No image');
      setLoading(false);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const postNotif = async (type, dataPass) => {
    let notifiers = [];
    let pass = false;
    if (type === 'all') {
      notifiers = '[1]';
      pass = true;
    } else if (type === 'followers' && brandFollowers.length > 0) {
      notifiers = `[${brandFollowers.toString()}]`;
      pass = true;
    } else if (type === 'followers' && brandFollowers.length < 1) {
      alert('No followers');
    }
    if (pass === true) {
      const data = qs.stringify({
        entityType: 1,
        entityId: modalId,
        notifiers,
        actorId: 8,
      });
      const token = cookies.get('token');
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      try {
        console.log('dataPass', dataPass);
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const response = await axios.post(`${BASE_URL}dashboard/addnotification`, data, {
          headers,
        });
        if (response.status === 200) {
          if (response.data.status === true) {
            console.log('RESPP', response);
            sendNotifOnesignal(dataPass);
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const sendNotifOnesignal = async (data) => {
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Basic NWI3MWViMjAtZTFjYS00ZWI5LTg4YzEtMWI3MzA0ZGNhY2Rl',
    };
    setState((prev) => ({ ...prev, loading: true }));
    console.log({ data });
    axios
      .post('https://onesignal.com/api/v1/notifications', data, {
        headers,
      })
      .then((res) => {
        console.log('RESSS', res);
        alert(`Sent to ${res.data.recipients}`);
        setState((prev) => ({
          ...prev,
          modalNotif: false,
          modalTitle: '',
          modalTitleFixed: '',
          modalContent: '',
          modalId: '',
          modalType: '',
          modalImg: '',
          brandFollowers: [],
        }));
        setLoading(false);
      })
      .catch((err) => {
        console.log('ERROR', err);
        setLoading(false);
      });
  };

  const onHideFull = () => {
    onHide();
    setLoading(false);
  };

  return (
    <Modal show={modalVisible} onHide={onHideFull} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Send Notification V2</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3 mt-3">
          <FormControl
            placeholder="Ex: Ayo Buruan Promo Gopay 20%"
            aria-label="Username"
            aria-describedby="basic-addon1"
            value={modalTitle}
            onChange={changeModalTitle}
          />
          <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', fontSize: 12 }}>
            <i className="fas fa-user prefix white-text active pr-2" /> Heading
          </label>
        </InputGroup>

        <InputGroup className="mb-3 row">
          <div className="form-group pb-1 col">
            <label className="switch" style={{ float: 'left' }}>
              <input type="checkbox" name="notifyToAll" onClick={notifyToAllToggle} checked={notifyToAll} />
              <span className="slider round"> </span>
            </label>
            <span style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, marginLeft: 20 }}>Notify to All?</span>
          </div>
          <div className="form-group pb-1 col">
            <label className="switch" style={{ float: 'left' }}>
              <input type="checkbox" name="notifyToFollowers" onClick={notifyToFollowersToggle} checked={notifyToFollowers} />
              <span className="slider round"> </span>
            </label>
            <span style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, marginLeft: 20 }}>Notify to Followers?</span>
          </div>
        </InputGroup>

        {!!extend && (
          <InputGroup className="mb-5">
            <SelectComponent
              isMulti
              name="store"
              id="store"
              options={liststore || []}
              value={notifBrandSelected}
              onChange={(e) => setNotifBrandSelected(e)}
              param="brandSelected"
              iClassName="fas fa-user prefix white-text active pr-2"
              title=" Brand"
              classNameSelect="w-100"
            />
          </InputGroup>
        )}
        <InputGroup>
          <FormControl as="textarea" aria-label="With textarea" value={modalContent} onChange={changeModalContent} />
          <label className="form-control-placeholder" htmlFor="discount" style={{ left: '5px', fontSize: 12 }}>
            <i className="fas fa-user prefix white-text active pr-2" /> Content
          </label>
        </InputGroup>
        <InputGroup className="mb-3 mt-5 row">
          <div className="form-group pb-1 col">
            <FormControl
              placeholder="Ex: Ayo Buruan Promo Gopay 20%"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={`ID: ${modalId}`}
              disabled
            />
          </div>
          <div className="form-group pb-1 col">
            <FormControl
              placeholder="Ex: Ayo Buruan Promo Gopay 20%"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={`Title: ${modalTitleFixed}`}
              disabled
            />
          </div>
          <div className="form-group pb-1 col">
            <FormControl
              placeholder="Ex: Ayo Buruan Promo Gopay 20%"
              aria-label="Username"
              aria-describedby="basic-addon1"
              value={`Type: ${modalType}`}
              disabled
            />
          </div>
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        {!!extend && (
          <Button onClick={() => checkFollowers(false, true)} variant="danger">
            Check Total Followers
          </Button>
        )}
        {loading === true ? (
          <h3>Loading...</h3>
        ) : (
          <Button onClick={sendNotif || checkFollowersAndSend} variant="info">
            Send Notification
          </Button>
        )}
        <Button onClick={onHideFull} variant="danger">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalNotificationV2;
