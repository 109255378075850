// import './wdyr';

import React from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'jquery/dist/jquery';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
// eslint-disable-next-line import/order
import { CookiesProvider } from 'react-cookie';
import 'babel-polyfill';
import configureStore from './redux/store';

const rootElement = document.getElementById('root');
const { store, persistor } = configureStore();

if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </PersistGate>
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </PersistGate>
    </Provider>,
    rootElement
  );
}
// ReactDOM.render(<CookiesProvider><App /></CookiesProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
