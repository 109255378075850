import { combineReducers } from 'redux';
import store from './storeReducers';
import place from './placeReducers';
import discount from './discountReducers';
import thirdParty from './thirdPartyReducers';

export default combineReducers({
  store,
  place,
  discount,
  thirdParty,
});
