/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withRouter, Link } from 'react-router-dom';
import { Table } from 'antd';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { SpinnerComponent, SpinnerOverlay } from '../../../components/molecules/spinner';
import DashboardAPI from '../../../service/api';
import { fetchThirdPartylatestPromo, removeThirdPartyLatestPromo } from '../../../redux/dispatchers/thirdPartyDispatcher';

const columns = [
  {
    title: 'Merchant Name & logo',
    dataIndex: 'MerchantName',
    key: 'MerchantName',
    render: (data, row, ext) => {
      return (
        <div>
          <p>{data}: </p>
          <img src={row.Logo} alt="asd" width="60" height="30" />
        </div>
      );
    },
  },
  {
    title: 'Title',
    dataIndex: 'Title',
    key: 'Title',
    render: (data, row) => {
      return (
        <p>
          {` 
            ${row.MerchantName} ${row.TitleRow1} ${row.TitleRow2}
          `}
        </p>
      );
    },
  },
  {
    title: 'Image',
    dataIndex: 'Background',
    key: 'Background',
    render: (data) => {
      return <img src={data} alt="asd" width="100" height="100" />;
    },
  },
  {
    title: 'Start Date',
    dataIndex: 'StartDate',
    key: 'StartDate',
    render: (data) => {
      return <Moment format="DD-MMM-YY">{data}</Moment>;
    },
  },
  {
    title: 'End Date',
    dataIndex: 'EndDate',
    key: 'EndDate',
    render: (data) => {
      return <Moment format="DD-MMM-YY">{data}</Moment>;
    },
  },
  {
    title: 'Action',
    dataIndex: '',
    key: 'x',
    render: (data, row) => (
      <>
        <Link
          to={`/dashboard/discount/submit?crawlThirdParty=true&id=${row.Id}`}
          target="_blank"
          query={{ crawlThirdParty: true, id: row.Id }}
          params={{ crawlThirdParty: true, id: row.Id }}
        >
          <a>Post</a>
        </Link>
      </>
    ),
  },
];

function ThirdPartyMerchant(props) {
  const { match } = props;
  const { params } = match;
  const { id } = params;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const loadData = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const result = await DashboardAPI.ThirdPartyAPI.getMerchantDetailPoinin(id);
        const { Promotions } = result;
        setData(Promotions);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log({ err });
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (!loading) {
        loadData();
      }
    }, 1000);
  }, []);

  return (
    <div>
      <div className="view" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: 10 }}>
        <div className="mask rgba-gradient align-items-center">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-12 col-xl-12 mb-0">
                <h2 style={{ textAlign: 'left' }}>List Merchant Promo</h2>
              </div>
              <div className="col-md-12 col-xl-12 mb-0">
                <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
                  <div className="card-body">
                    <SpinnerComponent loading={loading} />
                    <Table columns={columns} dataSource={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ thirdParty }) => {
  const { thirdPartyPromoLoading, thirdPartyPromoError, thirdPartyPromo, thirdPartyPromoSearch } = thirdParty;
  return { thirdPartyPromoLoading, thirdPartyPromoError, thirdPartyPromo, thirdPartyPromoSearch };
};

export default connect(mapStateToProps, { fetchThirdPartylatestPromo, removeThirdPartyLatestPromo })(withRouter(ThirdPartyMerchant));
