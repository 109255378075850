import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

async function getList(token) {
  const url = `${BASE_URL}dashboard/crawl`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'GET',
    url,
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function addList(token, data) {
  const url = `${BASE_URL}dashboard/crawl`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'POST',
    url,
    data,
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function updateList(token, data) {
  const url = `${BASE_URL}dashboard/crawl`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'PATCH',
    url,
    data,
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function updateLastCheck(token, data) {
  const url = `${BASE_URL}dashboard/crawl/check`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'PATCH',
    url,
    data,
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

const crawlAPI = {
  getList,
  addList,
  updateList,
  updateLastCheck,
};

export default crawlAPI;
