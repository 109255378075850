import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

async function brandList(token) {
  const url = `${BASE_URL}api/showbrandweb`;
  const response = await axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function storeUpdate(token, data = {}) {
  const url = `${BASE_URL}dashboard/store`;
  const response = await axios({
    method: 'PATCH',
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url,
  });
  if (response.data.status !== true) {
    throw response;
  } else {
    return response;
  }
}

async function storeList(token, limit = 3000) {
  const url = `${BASE_URL}dashboard/storelist`;
  const response = await axios({
    method: 'GET',
    params: {
      limit,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function placeAssociation(id, token) {
  const url = `${BASE_URL}dashboard/store/place`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'GET',
    params: {
      id,
    },
    headers,
    url,
  });
  console.log(response);
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function placeAssociationAdd(data, token, id) {
  const url = `${BASE_URL}dashboard/store/place`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'POST',
    data: {
      id,
      data,
    },
    headers,
    url,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function placeAssociationDelete(data, token, id) {
  const url = `${BASE_URL}dashboard/store/place`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'DELETE',
    data: {
      id,
      data,
    },
    headers,
    url,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

const storeAPI = {
  brandList,
  storeUpdate,
  storeList,
  placeAssociation,
  placeAssociationAdd,
  placeAssociationDelete,
};

export default storeAPI;
