import Cookies from 'universal-cookie';

const cookies = new Cookies();

const authService = {
  async saveItem(value) {
    try {
      await cookies.set('token', value);
      console.log(cookies.get('token'));
    } catch (error) {
      console.log(`Cookies Error: ${error.message}`);
    }
  },
  async saveCookies(param, value) {
    try {
      await cookies.set(param, value);
      console.log(cookies.get(param));
    } catch (error) {
      console.log(`Cookies Error: ${error.message}`);
    }
  },
  // eslint-disable-next-line consistent-return
  async deleteCookies(param) {
    try {
      await cookies.remove(param).then(() => {
        console.log('LOGOUT');
        return true;
      });
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async loadJWT() {
    try {
      const value = await cookies.get('token');
      console.log(value);
      if (value) {
        return value;
      }
      return false;
    } catch (error) {
      return false;
    }
  },

  // eslint-disable-next-line consistent-return
  async deleteJWT() {
    try {
      await cookies.remove('token').then(() => {
        console.log('LOGOUT');
        return true;
      });
    } catch (error) {
      return false;
    }
    /* try{
        await AsyncStorage.removeItem('id_token')
        .then(
          () => {
            this.setState({
              jwt: ''
            })
            console.log('LOGOUT')
            OneSignal.sendTags({"userId" : ""});
          }
        );
      } catch (error) {
        console.log('AsyncStorage Error: ' + error.message);
      } */
  },
};

export default authService;
