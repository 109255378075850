/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Modal, InputGroup, FormControl, Button } from 'react-bootstrap';

const CreatePollingModal = ({ modal, modalContent, changeModalContent, leftOnPress, rightOnPress, onHide }) => (
  <Modal show={modal} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ marginTop: 20 }}>
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">Create new polling (still manual)</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div>
        <InputGroup style={{ marginTop: 10 }}>
          <FormControl style={{ height: 200 }} as="textarea" aria-label="With textarea" value={modalContent} onChange={changeModalContent} />
          <label className="form-control-placeholder" htmlFor="discount" style={{ left: '5px', fontSize: 12 }}>
            <i className="fas fa-user prefix white-text active pr-2" /> Content
          </label>
        </InputGroup>
        <h3>Sample</h3>
        <p>
          {`{
                        "onTimeline": 1,
                        "content": "Test polling title 4",
                        "pollingTypeId": 1,
                        "isFeatured": 1,
                        "options": ["option 1", "option 2"]
                    }`}
        </p>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={leftOnPress} variant="info">
        Create
      </Button>
      <Button onClick={rightOnPress} variant="danger">
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

export default CreatePollingModal;
