import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

async function login(input, password) {
  const url = `${BASE_URL}dashboard/authenticate`;
  const response = await axios({
    method: 'POST',
    url,
    data: {
      password,
      input,
    },
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

const AuthAPI = {
  login,
};

export default AuthAPI;
