/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Modal, Button, InputGroup } from 'react-bootstrap';
import Select from 'react-select';

function ModalSource(props) {
  const { isLoading, storeList, sourcePartnerList, modalData, setModalData } = props;
  const [storeListRefactor, setStoreListRefactor] = useState([]);

  useEffect(() => {
    if (storeList.length > 0) {
      const refactorStoreList = () => {
        const data = storeList;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < data.length; i++) {
          data[i].value = data[i].id;
          data[i].label = data[i].name;
        }
        setStoreListRefactor(data);
      };
      refactorStoreList();
    }
  }, [storeList]);

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add new source</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3">
          <div className="form-group pb-1 col">
            <Select
              placeholder="Select Store"
              name="colors"
              isLoading={isLoading}
              options={storeListRefactor}
              className="basic-multi-select"
              classNamePrefix="select"
              value={modalData.selectedStore}
              onChange={(selected) => setModalData((prevState) => ({ ...prevState, storeId: selected.id }))}
            />
          </div>
        </InputGroup>
        <InputGroup className="mb-3">
          <div className="form-group pb-1 col">
            <Select
              placeholder="Select Partner Source"
              name="colors"
              isLoading={isLoading}
              options={sourcePartnerList}
              className="basic-multi-select"
              classNamePrefix="select"
              value={modalData.selectedPartner}
              onChange={(selected) => setModalData((prevState) => ({ ...prevState, couponpartnerId: selected.id }))}
            />
          </div>
        </InputGroup>
        <InputGroup className="mb-3">
          <div className="form-group pb-1 col">
            <input
              type="text"
              name="name"
              id="name"
              className="form-control"
              value={modalData.url}
              onChange={(event) => {
                const { value } = event.target;
                setModalData((prevState) => {
                  return { ...prevState, url: value };
                });
              }}
              required
            />
            <label className="form-control-placeholder ml-2" htmlFor="name">
              <i className="fas fa-envelope-square prefix white-text active pr-2" /> Url
            </label>
          </div>
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.onUpdate}>
          Update
        </Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalSource;
