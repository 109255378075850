/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withRouter, Link } from 'react-router-dom';
import { Table } from 'antd';
import Moment from 'react-moment';
import Search from 'antd/lib/input/Search';
import { connect } from 'react-redux';
import { SpinnerComponent, SpinnerOverlay } from '../../../components/molecules/spinner';
import Layout from '../../../components/layout';
import Content from '../../../components/content';
import ModalDetailDiscount from '../discount/components/modalDetailDiscount';
import { ButtonRegular } from '../../../components/button';
import { fetchThirdPartySearchPromo } from '../../../redux/dispatchers/thirdPartyDispatcher';
import searchJson from './search.json';

const columns = [
  {
    title: 'Name & logo',
    dataIndex: 'Name',
    key: 'Name',
    render: (data, row, ext) => {
      return (
        <div>
          <p>{data}: </p>
          <img src={row.Logo} alt="asd" width="60" height="30" />
        </div>
      );
    },
  },
  {
    title: 'Id',
    dataIndex: 'Id',
    key: 'Id',
  },
  {
    title: 'Category',
    dataIndex: 'Category',
    key: 'Category',
  },
  {
    title: 'Action',
    dataIndex: '',
    key: 'x',
    render: (data, row) => (
      <>
        <Link
          to={`/dashboard/third-party/merchant/${row.Id}`}
          target="_blank"
          query={{ crawlThirdParty: true, id: row.Id }}
          params={{ crawlThirdParty: true, id: row.Id }}
        >
          <a>View</a>
        </Link>
      </>
    ),
  },
];

function ThirdPartySearch(props) {
  const { thirdPartyPromoSearch } = props;
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [modalData, setModalData] = useState({
    modalVisible: false,
    modalTitle: '',
    modalShortName: '',
    modalContent: '',
    modalTnc: '',
    modalId: '',
  });
  const [merchantData, setMerchantData] = useState(searchJson);
  const [merchantDataSearch, setMerchantDataSearch] = useState([]);

  const fetchSearch = async (value) => {
    setSearch(value);
    try {
      setLoadingSearch(true);
      const result = await props.fetchThirdPartySearchPromo(search);
      console.log({ result });
      setLoadingSearch(false);
    } catch (err) {
      console.log({ err });
      setLoadingSearch(false);
    }
  };

  const fetchSearchJson = async (value = '') => {
    if (value.length === 0) {
      setMerchantDataSearch([]);
    } else {
      setSearch(value);
      const stateTemp = merchantData.filter((a) => a.Keyword.includes(value.toLowerCase()));
      setMerchantDataSearch(stateTemp);
    }
  };

  return (
    <Layout level1="discount" level1Href="/discount">
      <SpinnerOverlay loading={loadingSearch} />
      <div className="view" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: 10 }}>
        <ModalDetailDiscount
          visible={modalData.modalVisible}
          onHide={() => setModalData((prev) => ({ ...prev, modalVisible: false }))}
          title={modalData.modalTitle}
          shortName={modalData.modalShortName}
          content={modalData.modalContent}
          tnc={modalData.modalTnc}
          id={modalData.modalId}
        />
        <div className="row mt-3">
          <div className="col-md-12 col-xl-12 mb-0">
            <h2 className="h3 text-left font-weight-bold mb-2">Discount & Promo</h2>
            <Link to="/dashboard/discount/submit">
              <ButtonRegular style={{ position: 'absolute', top: 0, right: 20 }}>+ Add new discount</ButtonRegular>
            </Link>
          </div>
          <Content>
            <SpinnerComponent loading={loadingSearch} />
            <Search
              className="d-flex mb-2"
              placeholder="Cari disini"
              onSearch={(value) => fetchSearchJson(value)}
              style={{ width: 200 }}
              enterButton
            />
            <Table
              bordered
              className="thead-solid table-striped-rows"
              responsive
              columns={columns}
              dataSource={merchantDataSearch.length > 0 ? merchantDataSearch : merchantData}
              size="middle"
            />
          </Content>
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = ({ thirdParty }) => {
  const { thirdPartyPromoLoading, thirdPartyPromoError, thirdPartyPromo, thirdPartyPromoSearch } = thirdParty;
  return { thirdPartyPromoLoading, thirdPartyPromoError, thirdPartyPromo, thirdPartyPromoSearch };
};

export default connect(mapStateToProps, { fetchThirdPartySearchPromo })(withRouter(ThirdPartySearch));
