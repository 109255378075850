import { FETCH_ALL_PLACE_SUCCESS, FETCH_ALL_PLACE, FETCH_ALL_PLACE_FAILED } from '../actions/placeActions';

const InitialState = {
  placeLoading: false,
  placeError: null,
  placeList: [],
  placeLastFetched: null,
};

export default (state = InitialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PLACE:
      return { ...state, placeLoading: true, placeError: null };
    case FETCH_ALL_PLACE_SUCCESS:
      return { ...state, placeLoading: false, placeError: null, placeList: action.payload, placeLastFetched: action.date };
    case FETCH_ALL_PLACE_FAILED:
      return { ...state, placeLoading: false, placeError: action.payload };
    default:
      return state;
  }
};
