/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Modal, Table } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import Cookies from 'universal-cookie';
import Moment from 'react-moment';
import { withRouter } from 'react-router-dom';
import DashboardAPI from '../../../service/api';
import CreatePollingModal from './modal';

const cookies = new Cookies();
const data = {
  columns: [
    {
      label: 'Id',
      field: 'id',
      sort: 'asc',
      width: 3,
    },
    {
      label: 'User ID',
      field: 'userId',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'On Timeline?',
      field: 'onTimeline',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Content',
      field: 'content',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'Type',
      field: 'pollingTypeId',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'isFeatured?',
      field: 'isFeatured',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Total vote',
      field: 'totalVote',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Created',
      field: 'created',
      sort: 'asc',
      width: 100,
    },
    {
      label: 'View',
      field: 'view',
      sort: 'asc',
      width: 100,
    },
  ],
};

class Polling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal: false,
      show: false,
      showData: [],
      createModalJson: '',
    };
  }

  componentDidMount() {
    this.renderPolling();
  }

  // eslint-disable-next-line class-methods-use-this
  groupByJavascript(input) {
    const result = [];
    // eslint-disable-next-line no-unused-vars
    let count = 0;
    // eslint-disable-next-line no-shadow
    input.map((data, i) => {
      const index = result.findIndex((e) => e.id === data.id);
      // console.log(index, result, data.id)
      if (index !== -1) {
        result[index].options.push({
          content: data.optionContent,
          totalOptionVote: data.totalOptionVote,
        });
      } else {
        // console.log(data);
        result.push({
          id: data.id,
          userId: data.userId,
          onTimeline: data.onTimeline,
          content: data.content,
          image: data.image,
          pollingTypeId: data.pollingTypeId,
          isFeatured: data.isFeatured,
          created: data.created,
          totalVote: data.totalVote,
          options: [
            {
              content: data.optionContent,
              totalOptionVote: data.totalOptionVote,
            },
          ],
        });
        count = +1;
      }
    });
    // The result array is your desired result
    return result;
  }

  renderPolling = async () => {
    this.setState({ loading: true });
    const token = cookies.get('token');
    try {
      const res = await DashboardAPI.PollingAPI.list(token, 100);
      if (res.data.status === true) {
        const dataFiltered = this.groupByJavascript(res.data.result);
        this.buildTable(dataFiltered);
      } else {
        alert('Ada masalah, silahkan coba lagi');
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  buildTable = (dataPolling) => {
    const result = [];
    // eslint-disable-next-line no-shadow
    dataPolling.map((data) => {
      result.push({
        id: data.id,
        userId: data.userId || ' ',
        onTimeline: data.onTimeline || ' ',
        content: data.content || ' ',
        pollingTypeId: data.pollingTypeId || ' ',
        isFeatured: data.isFeatured || ' ',
        totalOptionVote: data.totalOptionVote,
        totalVote: data.totalVote,
        created: <Moment format="DD-MMM-YYYY">{data.created || ` `}</Moment>,
        view: (
          <Button color="default" rounded size="sm" style={{ backgroundColor: '#5EC5CC', color: '#fff' }} onClick={() => this.viewDetail(data)}>
            View
          </Button>
        ),
      });
    });
    const resulttopush = [];
    resulttopush.columns = data.columns;
    resulttopush.rows = result;
    console.log(JSON.stringify(resulttopush));
    this.setState({
      dataRows: resulttopush,
      loading: false,
    });
  };

  handleChange = (value, params) => {
    this.setState({ [params]: value });
  };

  createNewPolling = async () => {
    const token = cookies.get('token');
    const { createModalJson } = this.state;
    try {
      const res = await DashboardAPI.PollingAPI.addJson(token, JSON.parse(createModalJson));
      if (res.data.status === true) {
        this.renderPolling();
      } else {
        alert('There are some error');
      }
    } catch (error) {
      console.log(error);
      alert('There are some error');
    }
  };

  // eslint-disable-next-line no-shadow
  viewDetail = (data) => {
    this.setState({
      show: true,
      showData: data,
    });
  };

  render() {
    const { modal, createModalJson, show, showData } = this.state;
    return (
      <div>
        <CreatePollingModal
          modal={modal}
          modalContent={createModalJson}
          changeModalContent={(text) => this.handleChange(text.target.value, 'createModalJson')}
          leftOnPress={this.createNewPolling}
          rightOnPress={() => this.handleChange(false, 'modal')}
          onHide={() => this.handleChange(false, 'modal')}
        />
        {show && (
          <Modal show={show} onHide={() => this.handleChange(false, 'show')} centered>
            <Modal.Header closeButton>
              <Modal.Title>{showData.content}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Options</th>
                    <th>Total vote</th>
                  </tr>
                </thead>
                <tbody>
                  {showData.options.map((a, i) => {
                    return (
                      <tr>
                        <td>{i}</td>
                        <td>{a.content}</td>
                        <td>{a.totalOptionVote}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.handleChange(false, 'show')}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.loading === true && <div className="loading">Loading</div>}
        <div className="view" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: 10 }}>
          <div className="mask rgba-gradient align-items-center">
            <div className="container">
              <div className="row mt-5">
                <div className="col-md-12 col-xl-12 mb-0">
                  <h2 style={{ textAlign: 'left' }}>Polling</h2>
                  <Button variant="primary" style={{ position: 'absolute', top: 0, right: 20 }} onClick={() => this.handleChange(true, 'modal')}>
                    + Add new polling
                  </Button>
                </div>
                <div className="col-md-12 col-xl-12 mb-0">
                  <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
                    <div className="card-body">
                      <MDBDataTable striped bordered hover data={this.state.dataRows} responsive />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Polling);
