/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { withRouter, Link } from 'react-router-dom';
import { Table } from 'antd';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { SpinnerComponent, SpinnerOverlay } from '../../../components/molecules/spinner';
import DashboardAPI from '../../../service/api';
import { fetchThirdPartylatestPromo, removeThirdPartyLatestPromo } from '../../../redux/dispatchers/thirdPartyDispatcher';
import { ButtonRegular } from '../../../components/button';
import Content from '../../../components/content';
import Layout from '../../../components/layout';

const columns = [
  {
    title: 'Merchant Name & logo',
    dataIndex: 'MerchantName',
    key: 'MerchantName',
    render: (data, row, ext) => {
      return (
        <div>
          <p>{data}: </p>
          <img src={row.Logo} alt="asd" width="60" height="30" />
        </div>
      );
    },
  },
  {
    title: 'Title',
    dataIndex: 'Title',
    key: 'Title',
    render: (data, row) => {
      return (
        <p>
          {` 
            ${row.MerchantName} ${row.TitleRow1} ${row.TitleRow2}
          `}
        </p>
      );
    },
  },
  {
    title: 'Image',
    dataIndex: 'Background',
    key: 'Background',
    render: (data) => {
      return <img src={data} alt="asd" width="100" height="100" />;
    },
  },
  {
    title: 'Start Date',
    dataIndex: 'StartDate',
    key: 'StartDate',
    render: (data) => {
      return <Moment format="DD-MMM-YY">{data}</Moment>;
    },
  },
  {
    title: 'End Date',
    dataIndex: 'EndDate',
    key: 'EndDate',
    render: (data) => {
      return <Moment format="DD-MMM-YY">{data}</Moment>;
    },
  },
  {
    title: 'Action',
    dataIndex: '',
    key: 'x',
    render: (data, row) => (
      <>
        <a>Delete</a>
        <div />
        <Link
          to={`/dashboard/discount/submit?crawlThirdParty=true&id=${row.Id}`}
          target="_blank"
          query={{ crawlThirdParty: true, id: row.Id }}
          params={{ crawlThirdParty: true, id: row.Id }}
        >
          <a>Post</a>
        </Link>
      </>
    ),
  },
];

function ThirdParty(props) {
  const { thirdPartyPromoLoading, thirdPartyPromoError, thirdPartyPromo, thirdPartyPromoSearch } = props;
  const [loadingSearch, setLoadingSearch] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (!!thirdPartyPromo && thirdPartyPromo.length < 1) {
        alert('Load the data');
        props.fetchThirdPartylatestPromo();
      }
    }, 1500);
  }, [props, thirdPartyPromo]);

  return (
    <Layout level1="discount" level1Href="/discount">
      <SpinnerOverlay loading={loadingSearch} />
      <SpinnerOverlay loading={thirdPartyPromoLoading} />
      <div className="row mt-3">
        <div className="col-md-12 col-xl-12 mb-0">
          <h2 className="h3 text-left font-weight-bold mb-2">3rd party Promo</h2>
          <ButtonRegular onClick={() => props.fetchThirdPartylatestPromo()} style={{ position: 'absolute', top: 0, right: 20 }}>
            + Refresh
          </ButtonRegular>
        </div>
        <Content>
          <div className="row mt-5">
            <div className="col-md-12 col-xl-12 mb-0">
              <h2 style={{ textAlign: 'left' }}>List Latest promo</h2>
            </div>
            <div className="col-md-12 col-xl-12 mb-0">
              <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
                <div className="card-body">
                  <SpinnerComponent loading={loadingSearch} />
                  <Table columns={columns} dataSource={thirdPartyPromo || []} />
                </div>
              </div>
            </div>
          </div>
        </Content>
      </div>
    </Layout>
  );
}

const mapStateToProps = ({ thirdParty }) => {
  const { thirdPartyPromoLoading, thirdPartyPromoError, thirdPartyPromo, thirdPartyPromoSearch } = thirdParty;
  return { thirdPartyPromoLoading, thirdPartyPromoError, thirdPartyPromo, thirdPartyPromoSearch };
};

export default connect(mapStateToProps, { fetchThirdPartylatestPromo, removeThirdPartyLatestPromo })(withRouter(ThirdParty));
