import React from 'react';
import ReactQuill from 'react-quill';

// eslint-disable-next-line import/prefer-default-export
export const TextAreaOne = ({ label, value, name, onChange, isSmall = false, className = '' }) => (
  <div className="form-group pb-3">
    <label htmlFor="amount"> {label}</label>
    {!isSmall ? (
      <ReactQuill value={value} name={name} onChange={onChange} className={className} />
    ) : (
      <ReactQuill value={value} name={name} onChange={onChange} style={{ height: 170 }} className={className} />
    )}
  </div>
);
