/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import Select from 'react-select';
import qs from 'qs';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { Button } from 'react-bootstrap';
// eslint-disable-next-line no-unused-vars
import { MDBDataTable } from 'mdbreact';
import Cookies from 'universal-cookie';
import $ from 'jquery';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table } from 'antd';
import Search from 'antd/lib/input/Search';
import ModalNotification from '../../../components/modal/modal-nofication';
import { setDiscountSearch } from '../../../redux/actions/discountActions';
import { fetchDiscountSearch, fetchAllDiscount } from '../../../redux/dispatchers/discountDispatcher';
import { SpinnerOverlay, SpinnerComponent } from '../../../components/molecules/spinner';
import notification from '../../../service/notification';

const cookies = new Cookies();
const data = {
  columns: [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      width: 3,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, data) => {
        return (
          <div>
            <p className="text-left">{text}</p>
            <p className="font-weight-bold text-left" style={{ fontSize: 11 }}>
              {`url: /${data?.data?.url || ''}`}
            </p>
          </div>
        );
      },
      width: 200,
    },
    {
      title: 'Short Name',
      dataIndex: 'shortName',
      key: 'shortName',
      width: 50,
    },
    {
      title: 'Store Name',
      dataIndex: 'storeName',
      key: 'storeName',
      width: 50,
    },
    {
      title: 'Featured',
      dataIndex: 'featured',
      key: 'featured',
      sorter: (a, b) => a.featured - b.featured,
      sortDirections: ['descend', 'ascend'],
      width: 30,
    },
    {
      title: 'Popular',
      dataIndex: 'popular',
      key: 'popular',
      sorter: (a, b) => a.popular - b.popular,
      sortDirections: ['descend', 'ascend'],
      width: 30,
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (text) => <Moment format="DD-MMM-YYYY">{text}</Moment>,
      sorter: (a, b) => a.created - b.created,
      sortDirections: ['descend', 'ascend'],

      label: 'Created',
      field: 'created',
      sort: 'asc',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text) => <Moment format="DD-MMM-YYYY">{text}</Moment>,
      sorter: (a, b) => a.endDate - b.endDate,
      sortDirections: ['descend', 'ascend'],

      label: 'End Date',
      field: 'endDate',
      sort: 'asc',
    },
    {
      title: 'Notified?',
      dataIndex: 'notified',
      key: 'notified',
      render: (text) => text,
      sorter: (a, b) => a.text - b.text,
      sortDirections: ['descend', 'ascend'],

      label: 'Notified',
      field: 'notified',
      sort: 'asc',
    },
    {
      title: 'View',
      dataIndex: 'send',
      key: 'send',
      render: (text, data) => (
        <Button type="primary" shape="round" onClick={() => data.checkNotif(data.data)}>
          View
        </Button>
      ),

      label: 'View',
      field: 'send',
      sort: 'asc',
    },
  ],
};

const doType = [
  { value: 'notifadd', label: 'New Discount Notification' },
  { value: 'notifhistory', label: 'Check Notification History' },
];

class NotificationScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      catSelected: [],
      dataBrand: [],
      newBrand: [],
      modal: false,
      modalTitle: '',
      modalTitleFixed: '',
      modalContent: '',
      modalId: '',
      modalType: '',
      modalImg: '',
      modalStore: [],
      dataRows: [],
      notifyToFollowers: false,
      notifyToAll: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  truncate(str, no_words) {
    return str.split(' ').splice(0, no_words).join(' ');
  }

  componentDidMount() {
    this.renderDiscount();
  }

  componentDidUpdate = (prevProps) => {
    const { discountList } = this.props;
    if (prevProps.discountList !== discountList) {
      this.buildTable(discountList);
    }
  };

  renderDiscount = async () => {
    const { discountList = [] } = this.props;
    // eslint-disable-next-line no-empty
    if (discountList.length < 1) {
      this.props.fetchAllDiscount();
    }
    if (discountList.length > 0 && this.state.dataRows.length < 1) {
      this.buildTable(discountList);
    }
  };

  buildTable = (discountData) => {
    const result = [];
    // eslint-disable-next-line no-shadow
    discountData.map((data) =>
      result.push({
        key: data.id,
        id: data.id,
        data,
        title: data.name,
        featured: data.IsFeatured === 1 ? 'Yes' : ` `,
        popular: data.IsPopular === 1 ? 'Yes' : ` `,
        created: data.created || ` `,
        notified: data.notifType === 1 ? 'Yes' : ` `,
        checkNotif: this.checkNotif,
      })
    );
    const resulttopush = [];
    resulttopush.columns = data.columns;
    resulttopush.rows = result;
    this.setState({
      dataRows: resulttopush,
      loading: false,
    });
  };

  onSubmit(event) {
    event.preventDefault();
    console.log(this.state.dataBrand);
    console.log(this.state.newBrand);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.state.newBrand.length; i++) {
      if (this.state.dataBrand.find((x) => x.label === this.state.newBrand[i].label)) {
        console.log(`${this.state.newBrand[i].label} exist`);
      } else {
        const headers = new Headers({
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          'Access-Control-Allow-Origins': 'http://localhost:3000',
          'Content-Type': 'application/json',
        });
        const data = qs.stringify({
          storeName: this.state.dataBrand[i].label,
          cat: this.state.catSelected,
        });
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        axios
          .post(`${BASE_URL}api/addstore`, data, { headers })
          .then(function (response) {
            console.log(response);
            if (response.data.message === 'Success') {
              alert('Berhasil menambahkan store');
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }

  renderSearch = async (keyword) => {
    try {
      const res = await this.props.fetchDiscountSearch(keyword);
      if (keyword.length >= 3) {
        this.buildTable(res.data.result, true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (event, type) => {
    console.log('EVENT', event);
    this.setState({
      [type]: event,
    });
  };

  checkNotif = async (data) => {
    console.log(data);
    this.setState({
      modal: true,
      modalTitle: data.name,
      modalTitleFixed: data.name,
      modalContent: $(`<p>${this.truncate(data.description, 20)}</p>`).text(),
      modalId: data.id,
      modalType: 'discount',
      modalImg: data.img,
      modalStore: data['GROUP_CONCAT(storeid)'] ? JSON.parse(`[${data['GROUP_CONCAT(storeid)']}]`) : [],
    });
  };

  sendNotif = async () => {
    const { notifyToAll, notifyToFollowers, modalImg, modalTitle, modalId, modalType, modalTitleFixed, modalContent, modalStore } = this.state;
    notification.sendNotif({
      notifyToAll,
      notifyToFollowers,
      modalStore,
      modalImg,
      modalTitle,
      modalId,
      modalType,
      modalTitleFixed,
      modalContent,
    });
  };

  async checkFollowers() {
    const { modalStore } = this.state;
    const result_array = [];
    this.setState({ loading: true });
    console.log(modalStore);
    // eslint-disable-next-line array-callback-return
    modalStore.map((data) => {
      result_array.push(data.toString());
    });
    const token = cookies.get('token');
    const header = {
      Authorization: `Bearer ${token}`,
    };
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const result = await axios.get(`${BASE_URL}dashboard/v2/storefollowers`, {
      params: {
        storeId: result_array.toString().split(',').join(','),
      },
      headers: header,
    });
    console.log('RESP', result);
    const tempData = [];
    if (result.status === 200) {
      const data = result.data.result;
      if (data.length > 0) {
        data.map((data) => tempData.push(data.id));
        return tempData;
      }
      alert('No Followers');
      return null;
    }
    alert('There are some error, please try again');
    return null;
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { discountLoading, discountSearchLoading, discountSearchText } = this.props;
    const { dataRows, loading } = this.state;
    return (
      <div>
        <SpinnerOverlay loading={loading || discountLoading} />
        {this.state.loading === true && <div className="loading">Loading</div>}
        <div className="view" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: 10 }}>
          <ModalNotification
            modalVisible={this.state.modal}
            modalId={this.state.modalId}
            modalTitle={this.state.modalTitle}
            changeModalTitle={(text) => this.setState({ modalTitle: text.target.value })}
            modalImg={this.state.modalImg}
            modalTitleFixed={this.state.modalTitleFixed}
            modalType="discount"
            modalContent={this.state.modalContent}
            changeModalContent={(text) => this.setState({ modalContent: text.target.value })}
            notifyToAll={this.state.notifyToAll}
            notifyToAllToggle={() => this.setState((prevState) => ({ notifyToAll: !prevState.notifyToAll }))}
            notifyToFollowers={this.state.notifyToFollowers}
            notifyToFollowersToggle={() => this.setState((prevState) => ({ notifyToFollowers: !prevState.notifyToFollowers }))}
            loading={this.state.loading}
            onHide={() => this.handleChange(false, 'modal')}
            sendNotif={this.sendNotif}
          />
          <div className="mask rgba-gradient align-items-center">
            <div className="container">
              <div className="row mt-4">
                <div className="col-md-12 col-xl-12 mb-0">
                  <h2 className="text-left font-weight-bold mb-3">Manage Notifications</h2>
                  <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
                    <div className="card-body mt-3">
                      <div className="form-group pb-3">
                        <Select
                          required
                          name="type"
                          options={doType}
                          value={this.state.doType}
                          onChange={this.handleChangeType}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                        <label className="form-control-placeholder" htmlFor="discount" style={{ left: '-10px', top: '-30px', fontSize: 12 }}>
                          <i className="fas fa-user prefix white-text active pr-2" /> What Do You Want To Do?
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xl-12 mb-0">
                  <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
                    <div className="card-body">
                      <SpinnerComponent loading={discountSearchLoading} />
                      <Search
                        className="d-flex mb-2"
                        placeholder="Cari disini"
                        onSearch={(value) => this.renderSearch(value)}
                        style={{ width: 200 }}
                        enterButton
                      />
                      <Table
                        bordered
                        className="thead-solid table-striped-rows"
                        responsive
                        columns={dataRows.columns}
                        dataSource={dataRows.rows}
                        size="middle"
                      />
                      {/* <MDBDataTable
                        striped
                        bordered
                        hover
                        columns={data.columns}
                                    rows={this.state.dataRows}
                        data={this.state.dataRows}
                        responsive
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ discount }) => {
  const { discountLoading, discountList, discountLastFetched, discountSearchText, discountSearchLoading, discountSearchList } = discount;
  return { discountLoading, discountList, discountLastFetched, discountSearchText, discountSearchLoading, discountSearchList };
};
export default connect(mapStateToProps, { setDiscountSearch, fetchDiscountSearch, fetchAllDiscount })(withRouter(NotificationScreen));
