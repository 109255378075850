import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

async function placeList() {
  const response = await axios.post(`${BASE_URL}api/showplaceall`);
  const { data } = response;
  if (data.status === false) {
    throw data;
  } else {
    return data.data;
  }
}

export default {
  placeList,
};
