/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
// eslint-disable-next-line import/order

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import qs from 'qs';
import { Modal, Button, Table } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import Moment from 'react-moment';
import $ from 'jquery';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import Resizer from 'react-image-file-resizer';
// eslint-disable-next-line no-unused-vars
import { withRouter, Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import CheckboxComponent from '../../../components/form/checkbox';
import SelectComponent from '../../../components/form/select';
import DashboardFunction from '../../../service/function';
import DashboardAPI from '../../../service/api';
import { TextAreaOne } from '../../../components/form/textarea';
import Layout from '../../../components/layout';
import Content from '../../../components/content';
import { fetchAllStore } from '../../../redux/dispatchers/storeDispatcher';
import { fetchAllPlaces } from '../../../redux/dispatchers/placeDispatcher';
import { removeAmpInDesc } from '../../../service/prePostDiscount';
import ModalNotificationV2 from '../../../components/modal/modal-noficationv2';

const cookies = new Cookies();

const cat = [
  { value: 1, label: 'Makanan' },
  { value: 2, label: 'Fashion' },
  { value: 3, label: 'Hiburan' },
  { value: 4, label: 'Travel' },
  { value: 5, label: 'Elektronik' },
  { value: 6, label: 'Kecantikan' },
  { value: 7, label: 'Otomotif' },
  { value: 8, label: 'Kesehatan' },
  { value: 9, label: 'Peliharaan' },
  { value: 10, label: 'Layanan' },
  { value: 11, label: 'Katalog' },
  { value: 12, label: 'Online' },
  { value: 13, label: 'Transportasi' },
  { value: 14, label: 'Bank dan E-Money' },
  { value: 15, label: 'Marketplace' },
  { value: 16, label: 'Hobi' },
  { value: 17, label: 'Event' },
];

const type = [
  { value: 1, label: 'Diskon' },
  { value: 2, label: 'Cashback' },
  { value: 3, label: 'Promosi' },
  { value: 4, label: 'Bonus' },
];

const BrandDiscountModal = ({ visible, data, hide }) => {
  return (
    <Modal show={visible} onHide={hide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">List Discount Brands</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Title</th>
              <th>Valid Until?</th>
              <th>Brand</th>
              <th>Expired?</th>
              <th>Preview / Edit?</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td>{index}</td>
                  <td>
                    <img src={item.img} alt={item.name} width="100px" />
                  </td>
                  <td>
                    {item.name}
                    <br /> <p style={{ fontSize: 11, fontWeight: 'bold' }}>{`url: /${item.url}`}</p>
                  </td>
                  <td>
                    <Moment format="DD-MMM-YY">{item.end}</Moment>
                  </td>
                  <td>{item.storeName}</td>
                  <td style={{ color: '#d21e1e', fontWeight: 'bold' }}>{item.DateDiff < 0 ? 'Expired' : ''}</td>
                  <td>
                    <Link push to={`/dashboard/edit-discount/${item.id}`}>
                      <Button variant="primary">Edit</Button>
                    </Link>
                    <Link target="_blank" to={`https://disqonin.com/discount/${item.url}`}>
                      <Button variant="info">Preview</Button>
                    </Link>
                  </td>
                  <td>{item.id}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

// eslint-disable-next-line no-underscore-dangle
let _this = null;
class AddDiscountScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchUrl: '',
      crawl: false,
      id: '',
      alert: false,
      show: false,
      startDate: '',
      endDate: '',
      isOpenStart: false,
      name: '',
      shortName: '',
      description: '',
      tnc: '',
      link: '',
      brandSelected: [],
      typeSelected: [],
      catSelected: [],
      recFile: [],
      loading: false,
      notifyToAll: false,
      notifyToFollowers: false,
      uploadWithUrl: false,
      brandDiscountPopUp: false,
      brandDiscountList: [],
      imgUrl: '',
      modalNotif: false,
      modalTitle: '',
      modalTitleFixed: '',
      modalContent: '',
      modalId: '',
      modalType: '',
      modalImg: '',
      brandFollowers: [],
      onlySelectedPlace: false,
      onlySelectedPlaceData: [],
      isPopular: false,
      isFeatured: false,
      dateNotConfirmed: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitWithUrl = this.onSubmitWithUrl.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
  }

  componentDidMount = () => {
    _this = this;
    this.setState({
      show: true,
    });
    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { crawlIg = false, crawlThirdParty = false, id = '', igUrl = '' } = query;
    if (crawlIg) {
      this.setState({ crawl: true, fetchUrl: igUrl });
    }
    if (crawlThirdParty && id) {
      this.renderThirdPartyDetail(id);
    }
    this.renderStore();
  };

  renderThirdPartyDetail = async (id) => {
    try {
      const response = await DashboardAPI.ThirdPartyAPI.getDetailPoinin(id);
      const { Terms = '', MerchantName, Name, Description, StartDate = '', EndDate = '', Images = [] } = response;
      let tnc = '';
      Terms.map((a) => {
        tnc = `${tnc}<p>${a}</p>`;
      });
      /* Description.map((a) => {
        desc = `${desc}<p>${a}</p>`;
      }); */
      console.log({
        tnc: Images[0],
      });
      const name = `${MerchantName} Promo ${Name}`;
      this.setState({
        name,
        uploadWithUrl: true,
        description: `<p>${Description}</p>`,
        tnc,
        startDate: StartDate,
        endDate: EndDate,
        imgUrl: Images[0],
      });
    } catch (err) {
      console.log({ err });
    }
  };

  renderStore = () => {
    const { storeList = [], placeList = [] } = this.props;
    if (storeList.length < 1 || !storeList[0].label) {
      console.log({ storeList: storeList.length });
      this.props.fetchAllStore();
    }
    if (placeList.length < 1) {
      console.log({ placeList: placeList.length });
      this.props.fetchAllPlaces();
    }
  };

  // eslint-disable-next-line no-shadow
  handleChange = (type, event) => {
    this.setState({
      [type]: event,
    });
  };

  handleChangeDate = (event) => {
    this.setState({
      startDate: event,
    });
  };

  handleChangeDate2 = (event) => {
    this.setState({
      endDate: event,
    });
  };

  handleChangeOpt = async (selectedOption) => {
    await this.setState({ brandSelected: selectedOption });
    console.log(`Option selected:`, selectedOption, this.state.brandSelected);
  };

  handleChangeOpt2 = (selectedOption) => {
    this.setState({ typeSelected: selectedOption });
    console.log(`Option selected:`, selectedOption);
  };

  handleChangeOpt3 = (selectedOption) => {
    this.setState({ catSelected: selectedOption });
    console.log(`Option selected:`, selectedOption);
  };

  handleChangeOptSelectedPlace = (selectedOption) => {
    this.setState({ onlySelectedPlaceData: selectedOption });
    console.log(`Option selected:`, selectedOption);
  };

  handleselectedFile = (event) => {
    console.log(document.getElementById('recfile').files[0]);
    if (event.target.files[0].size < 1020000) {
      this.setState({
        recFile: event.target.files[0],
        loaded: 0,
      });
    } else {
      alert('Ukuran Gambar terlalu besar');
    }
    /* console.log(document.getElementById('recfile').files[0]);
    if (event.target.files[0].size < 1020000) {
      console.log('FILE', event.target.files[0]);
      Resizer.imageFileResizer(
        event.target.files[0],
        700,
        700,
        'JPEG',
        80,
        0,
        (uri) => {
          this.setState({
            recFile: uri,
            loaded: 0,
          });
        },
        'blob'
      );
    } else {
      alert('Ukuran Gambar terlalu besar');
    } */
  };

  onSubmit = async (event) => {
    event.preventDefault();
    this.checkFollowers();
    if (this.state.notifyToAll === true) {
      alert('true');
    }
    const token = cookies.get('token');
    const header = {
      Authorization: `Bearer ${token}`,
      uploadWithUrl: this.state.uploadWithUrl,
    };

    this.setState({ loading: true });
    const form = document.querySelector('form');
    const data = new FormData(form);
    data.append('description', removeAmpInDesc(this.state.description));
    data.append('tnc', this.state.tnc);
    data.append('startDate', DashboardFunction.formatDate(this.state.startDate));
    data.append('endDate', DashboardFunction.formatDate(this.state.endDate));
    // data.delete('recfile');
    // data.append('recfile', this.state.recFile);
    console.log(this.state);
    console.log(data, document.getElementById('recfile').type);
    const fileSize = document.getElementById('recfile').type === 'file' ? document.getElementById('recfile').files[0].size : 200000000;
    if (fileSize < 1020000 || this.state.uploadWithUrl) {
      if (this.state.brandSelected.length > 0 || this.state.catSelected.length > 0) {
        try {
          const response = await DashboardAPI.DiscountAPI.submit(header, data, 1);
          console.log(response);
          _this.handleLoading();
          if (response.data.message === 'Success') {
            _this.setState({ description: '' });
            alert('Berhasil menambahkan diskon');
            document.getElementById('formPost').reset();
            this.setState({
              startDate: '',
              endDate: '',
              tnc: '',
              brandSelected: [],
              catSelected: [],
              typeSelected: [],
            });
            this.checkNotif(response.data.insertedId);
          }
        } catch (error) {
          _this.handleLoading();
          if (error) {
            alert('Gagal, Silahkan Coba lagi');
            console.log('ERROR', error);
          }
        }
      } else {
        _this.handleLoading();
        alert('Pastikan sudah pilih brand atau kategori');
      }
    } else {
      _this.handleLoading();
      _this.setState({ loading: false });
      alert('File is too big');
    }
  };

  onSubmitWithUrl = async (event) => {
    event.preventDefault();
    this.checkFollowers();
    const { uploadWithUrl, brandSelected, catSelected, typeSelected, endDate } = this.state;
    if (this.state.notifyToAll === true) {
      alert('true');
    }
    const token = cookies.get('token');
    const header = {
      Authorization: `Bearer ${token}`,
      uploadWithUrl: this.state.uploadWithUrl,
    };
    this.setState({ loading: true });
    if (uploadWithUrl) {
      if (brandSelected.length > 0 && catSelected.length > 0 && typeSelected && endDate) {
        const dataToPost = {
          name: this.state.name,
          shortName: this.state.shortName,
          typeId: this.state.type,
          description: removeAmpInDesc(this.state.description),
          tnc: this.state.tnc,
          imgUrl: this.state.imgUrl,
          link: this.state.link ? this.state.link : 'NULL',
          cat: this.state.catSelected.map((data) => data.value),
          store: this.state.brandSelected.map((data) => data.value),
          type: this.state.typeSelected.value,
          isFeatured: document.getElementById('isFeatured').checked === true ? 'on' : 'off',
          isPopular: document.getElementById('isPopular').checked === true ? 'on' : 'off',
          onlySelectedPlace: document.getElementById('onlySelectedPlace').checked === true ? 1 : null,
          onlySelectedPlaceData: this.state.onlySelectedPlaceData,
          dateNotConfirmed: document.getElementById('dateNotConfirmed').checked === true ? 1 : null,
          startDate: DashboardFunction.formatDate(this.state.startDate),
          endDate: DashboardFunction.formatDate(this.state.endDate),
        };
        try {
          const response = await DashboardAPI.DiscountAPI.submit(header, qs.stringify(dataToPost), 2);
          console.log(response);
          _this.handleLoading();
          if (response.data.message === 'Success') {
            _this.setState({ description: '' });
            alert('Berhasil menambahkan diskon');
            document.getElementById('formPost').reset();
            this.setState({
              startDate: '',
              endDate: '',
              tnc: '',
              brandSelected: [],
              catSelected: [],
              typeSelected: [],
            });
            this.checkNotif(response.data.insertedId);
          }
        } catch (error) {
          _this.handleLoading();
          if (error) {
            alert('Gagal, Silahkan Coba lagi');
            console.log('ERROR', error);
          }
        }
      } else if (!endDate) {
        _this.handleLoading();
        alert('Pastikan sudah masukkan tanggal berakhir, jika tidak ada data tanggal berakhir masukkan tanggal terakhir bulan ini');
      } else {
        _this.handleLoading();
        alert('Pastikan sudah pilih brand, kategori & tipe');
      }
    }
  };

  checkNotif = async (id) => {
    if (this.state.notifyToAll || this.state.notifyToFollowers) {
      const data = qs.stringify({
        id,
      });
      try {
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const res = await axios.post(`${BASE_URL}api/discountdetail`, data);
        console.log(res);
        if (res.data.status === true) {
          this.setState({
            modalNotif: true,
            modalTitle: res.data.data[0].name,
            modalTitleFixed: res.data.data[0].name,
            modalContent: $(`<p>${DashboardFunction.truncate(res.data.data[0].description, 20)}</p>`).text(),
            modalId: id,
            modalType: 'discount',
            modalImg: res.data.data[0].img,
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  sendNotif = async () => {
    const { notifyToAll, notifyToFollowers, brandFollowers, modalImg } = this.state;
    if (modalImg) {
      if (notifyToAll === true) {
        const data = {
          app_id: '942046ea-d755-4f5c-9384-ea20410a0fb9',
          included_segments: ['All'],
          data: { type: this.state.modalType, id: this.state.modalId, notif: 'true', title: this.state.modalTitleFixed },
          big_picture: this.state.modalImg,
          large_icon: this.state.modalImg,
          headings: { en: this.state.modalTitle },
          contents: { en: this.state.modalContent },
          filters: [{ field: 'tag', key: 'pushnotif', relation: '!=', value: '0' }],
        };
        console.log('DATA NOTIF ALL', data);
        this.postNotif('all', data);
      } else if (notifyToFollowers === true) {
        const data = {
          app_id: '942046ea-d755-4f5c-9384-ea20410a0fb9',
          include_external_user_ids: brandFollowers.map((a) => `${a}`),
          data: { type: this.state.modalType, id: this.state.modalId, notif: 'true', title: this.state.modalTitleFixed },
          big_picture: this.state.modalImg,
          large_icon: this.state.modalImg,
          headings: { en: this.state.modalTitle },
          contents: { en: this.state.modalContent },
        };
        console.log('DATA NOTIF FOLL', data);
        this.postNotif('followers', data);
      } else {
        console.log('SEND TO NONE');
      }
    }
  };

  // eslint-disable-next-line no-shadow
  postNotif = async (type, dataPass) => {
    const { brandFollowers } = this.state;
    let notifiers = [];
    let pass = false;
    if (type === 'all') {
      notifiers = '[1]';
      pass = true;
    } else if (type === 'followers' && brandFollowers.length > 0) {
      notifiers = `[${brandFollowers.toString()}]`;
      pass = true;
    } else if (type === 'followers' && brandFollowers.length < 1) {
      alert('No followers');
    }
    if (pass === true) {
      const data = qs.stringify({
        entityType: 1,
        entityId: this.state.modalId,
        notifiers,
        actorId: 8,
      });
      const token = cookies.get('token');
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      try {
        console.log('dataPass', dataPass);
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const response = await axios.post(`${BASE_URL}dashboard/addnotification`, data, {
          headers,
        });
        if (response.status === 200) {
          if (response.data.status === true) {
            console.log('RESPP IN ADD', response);
            this.sendNotifOnesignal(dataPass);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  sendNotifOnesignal = async (data) => {
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'Basic NWI3MWViMjAtZTFjYS00ZWI5LTg4YzEtMWI3MzA0ZGNhY2Rl',
    };
    this.setState({ loading: true });
    axios
      .post('https://onesignal.com/api/v1/notifications', data, {
        headers,
      })
      .then((res) => {
        console.log('RESSS', res);
        alert(`Sent to ${res.data.recipients}`);
        this.setState({
          modalNotif: false,
          modalTitle: '',
          modalTitleFixed: '',
          modalContent: '',
          modalId: '',
          modalType: '',
          modalImg: '',
          brandFollowers: [],
          loading: false,
        });
      })
      .catch((err) => {
        console.log('ERROR', err);
        this.setState({ loading: false });
      });
  };

  handleLoading = () => {
    this.setState({ loading: false });
  };

  async checkBrandDiscount() {
    // eslint-disable-next-line camelcase
    const result_array = [];
    // eslint-disable-next-line array-callback-return
    this.state.brandSelected.map((data) => {
      result_array.push(data.value.toString());
    });
    const token = cookies.get('token');
    const listBrand = result_array.toString().split(',').join("','");
    this.setState({ loading: true });
    try {
      const response = await DashboardAPI.DiscountAPI.multipleBrandDiscount(token, listBrand);
      console.log('response', response);
      if (response.data.status === true) {
        this.setState({ brandDiscountList: response.data.result });
        this.setState({ brandDiscountPopUp: true });
      } else {
        alert('Terjadi kesalaahn, silahkan cek internet anda atau coba beberapa saat lagi');
      }
      this.setState({ loading: false });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      alert('Terjadi kesalaahn, silahkan cek internet anda atau coba beberapa saat lagi');
    }
  }

  checkFollowers = async (showAlert = false) => {
    const result_array = [];
    // eslint-disable-next-line array-callback-return
    this.state.brandSelected.map((data) => {
      result_array.push(data.value.toString());
    });
    const token = cookies.get('token');
    const header = {
      Authorization: `Bearer ${token}`,
    };
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    axios
      .get(`${BASE_URL}dashboard/v2/storefollowers`, {
        params: {
          storeId: result_array.toString().split(',').join(','),
        },
        headers: header,
      })
      .then((response) => {
        console.log('RESP', response);
        const tempData = [];
        if (response.status === 200) {
          const data = response.data.result;
          if (data.length > 0) {
            data.map((item) => tempData.push(item.id));
            if (showAlert) {
              alert(`Total followers ${tempData.length <= 0 ? 0 : tempData.length}`);
            }
            this.setState({ brandFollowers: tempData });
          } else {
            this.setState({ brandFollowers: [] });
            alert(`No followers`);
          }
        }
      })
      .catch((err) => {
        console.log('ERR', err);
      });
  };

  setStateFunction = (variable, value) => {
    this.setState({ [variable]: value });
  };

  handleChangeOptions = (selectedOption, param) => {
    this.setState({ [param]: selectedOption });
    console.log(param, selectedOption);
  };

  checkName = async () => {
    try {
      const token = cookies.get('token');
      const { name = '' } = this.state;
      const nameTruncate = name.replace(/(([^\s]+\s\s*){5})(.*)/, '$1');
      console.log(nameTruncate);
      const response = await DashboardAPI.DiscountAPI.searchname(token, nameTruncate, 5);
      const { data = {} } = response;
      if (data.status === true && data.data.length > 0) {
        const r = window.confirm('Terdapat posting mirip yang sudah pernah di post, mau cek? (disarankan untuk memodifikasi promo yang sama)');
        if (r === true) {
          this.setState({ brandDiscountList: data.result, brandDiscountPopUp: true });
          return false;
        }
        return true;
      }
      throw response;
    } catch (error) {
      console.log(error);
      const r = window.confirm('Ada masalah mengecek duplikat data, mau coba lagi?');
      if (r === true) {
        this.checkName();
        return false;
      }
      return true;
    }
  };

  render() {
    const {
      // eslint-disable-next-line no-unused-vars
      submittedData,
      notifyToFollowers,
      notifyToAll,
      modalNotif,
      modalContent,
      modalId,
      modalImg,
      modalTitle,
      modalTitleFixed,
      fetchUrl,
      brandSelected,
      catSelected,
      onlySelectedPlace,
      onlySelectedPlaceData,
      typeSelected,
      dateNotConfirmed,
      loading = false,
    } = this.state;
    const { storeList = [], placeList = [] } = this.props;
    return (
      <Layout level1="discount" level1Href="/discount" level2="submit disqon" level2Href="/submit-discount">
        {!!loading && <div className="loading">Loading&#8230;</div>}
        <ModalNotificationV2
          modalVisible={modalNotif}
          modalId={modalId}
          modalTitle={modalTitle}
          changeModalTitle={(text) => this.setState({ modalTitle: text.target.value })}
          modalImg={modalImg}
          modalTitleFixed={modalTitleFixed}
          modalType="discount"
          modalContent={modalContent}
          changeModalContent={(text) => this.setState({ modalContent: text.target.value })}
          notifyToAll={notifyToAll}
          notifyToAllToggle={() => this.setState({ notifyToAll: !notifyToAll })}
          notifyToFollowers={notifyToFollowers}
          notifyToFollowersToggle={() => this.setState({ notifyToFollowers: !notifyToFollowers })}
          loading={loading}
          onHide={() => this.setState({ modalNotif: false })}
          liststore={storeList}
          brandSelected={brandSelected}
          extend
        />
        <BrandDiscountModal
          data={this.state.brandDiscountList}
          visible={this.state.brandDiscountPopUp}
          hide={() => this.setState({ brandDiscountPopUp: false })}
        />
        <div className="row mt-4">
          <div className="col-md-12 col-xl-12 mb-0">
            <h2 className="h3 text-left font-weight-bold mb-2">Submit Disqon</h2>
          </div>
          <Content>
            <form
              onSubmit={this.state.uploadWithUrl === true ? this.onSubmitWithUrl : this.onSubmit}
              method="post"
              encType="multipart/form-data"
              id="formPost"
            >
              <div className="row mt-3">
                <div className="col-md-8 col-xl-8 mb-4">
                  <div className="col-md-12 mx-auto">
                    <div className="form-group pb-3 row">
                      <div className="col-4">
                        <div className="form-group pb-1">
                          <label className="switch" style={{ float: 'left' }}>
                            <input
                              type="checkbox"
                              name="crawl"
                              onClick={() => this.setState((prevState) => ({ crawl: !prevState.crawl }))}
                              checked={this.state.crawl}
                            />
                            <span className="slider round"> </span>
                          </label>
                          <span style={{ fontSize: 18, fontWeight: 'bold' }}>Crawl?</span>
                        </div>
                      </div>
                      {this.state.crawl === true && (
                        <>
                          <div className="col-6">
                            <input
                              type="text"
                              name="fetchurl"
                              id="fetchurl"
                              className="form-control"
                              value={this.state.fetchUrl}
                              onChange={(event) => this.setState({ fetchUrl: event.target.value })}
                            />
                            <label className="form-control-placeholder" htmlFor="fetchurl">
                              <i className="fas fa-envelope-square prefix white-text active pr-2" /> Url
                            </label>
                          </div>
                          <div className="col-2">
                            <button
                              type="button"
                              className="btn btn-warning btn-block"
                              onClick={() => DashboardFunction.crawlSite(fetchUrl, brandSelected, catSelected, this.setStateFunction)}
                              style={{ backgroundColor: '#27A4DD', color: '#fff' }}
                            >
                              Submit
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="form-group pb-3">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        value={this.state.name}
                        onChange={(event) => this.setState({ name: event.target.value })}
                        required
                      />
                      <label className="form-control-placeholder" htmlFor="name">
                        <i className="fas fa-envelope-square prefix white-text active pr-2" /> Judul
                      </label>
                      <Button onClick={this.checkName} variant="secondary" block className="mt-2" style={{ width: 120, fontSize: 12 }}>
                        Cek Duplikat
                      </Button>
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="shortName"
                        id="shortName"
                        className="form-control"
                        maxLength={40}
                        onChange={(event) => this.setState({ shortName: event.target.value })}
                        required
                      />
                      <label className="form-control-placeholder" htmlFor="shortName">
                        <i className="fas fa-tag prefix white-text active pr-2" /> Judul Pendek eg: Burger King Cashback 20%
                      </label>
                      <p style={{ fontSize: 12, width: '100%', textAlign: 'left' }}>format: brand+tipe+potongan</p>
                    </div>
                    <div className="row">
                      <div className="form-group pb-1 col-6">
                        <label>Tanggal Mulai</label>
                        <DatePicker
                          autoComplete="off"
                          id="startDate"
                          selected={moment(this.state.startDate).isValid() ? moment(this.state.startDate).toDate() : null}
                          value={this.state.startDate}
                          onChange={(e) => this.handleChangeDate(e)}
                          isClearable
                          placeholderText="Biarkan jika tidak ada!"
                        />
                      </div>
                      <div className="form-group pb-1 col-6">
                        <label>Tanggal Selesai</label>
                        <DatePicker
                          autoComplete="off"
                          id="endDate"
                          value={this.state.endDate}
                          selected={moment(this.state.endDate).isValid() ? moment(this.state.endDate).toDate() : null}
                          onChange={(e) => this.handleChangeDate2(e)}
                          isClearable
                          placeholderText="Biarkan jika tidak ada!"
                        />
                      </div>
                    </div>
                    <TextAreaOne
                      label=" Deskripsi"
                      name="description"
                      value={this.state.description}
                      onChange={(event) => this.handleChange('description', event)}
                    />
                    <TextAreaOne
                      label=" Syarat dan Ketentuan"
                      name="Terms and Condition"
                      value={this.state.tnc}
                      onChange={(event) => this.handleChange('tnc', event)}
                      isSmall
                      className="tncquill"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-xl-4 mb-4">
                  <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0', padding: 10, paddingTop: 40 }}>
                    <div className="form-group pb-1">
                      <CheckboxComponent
                        name="dateNotConfirmed"
                        id="dateNotConfirmed"
                        checked={dateNotConfirmed}
                        param="dateNotConfirmed"
                        setState={this.setStateFunction}
                        title="End date not confirmed yet?"
                      />
                    </div>
                    <div className="form-group pb-3">
                      <SelectComponent
                        isMulti
                        name="store"
                        id="store"
                        options={storeList || []}
                        value={brandSelected}
                        onChange={this.handleChangeOpt}
                        param="brandSelected"
                        iClassName="fas fa-user prefix white-text active pr-2"
                        title=" Brand"
                      />
                      {storeList.length < 1 && (
                        <Button onClick={() => this.renderStore()} variant="danger" block className="mt-2">
                          Refresh brand?
                        </Button>
                      )}
                      <Button onClick={() => this.checkBrandDiscount()} variant="warning" block className="mt-2">
                        Cek diskon dari brand?
                      </Button>
                      <Button onClick={() => this.checkFollowers(true)} variant="secondary" block className="mt-2">
                        Cek total followers
                      </Button>
                    </div>
                    <div className="form-group pb-3">
                      <SelectComponent
                        name="cat"
                        id="cat"
                        isMulti
                        options={cat}
                        value={catSelected}
                        onChange={this.handleChangeOpt3}
                        param="catSelected"
                        iClassName="fas fa-user prefix white-text active pr-2"
                        title=" Kategori"
                      />
                    </div>
                    <div className="form-group pb-3">
                      <SelectComponent
                        name="type"
                        id="type"
                        options={type}
                        value={typeSelected}
                        onChange={this.handleChangeOpt2}
                        param="typeSelected"
                        iClassName="fas fa-user prefix white-text active pr-2"
                        title=" Tipe"
                      />
                    </div>
                    <div className="form-group pb-3">
                      <input
                        type="text"
                        id="link"
                        name="link"
                        className="form-control"
                        value={this.state.link}
                        onChange={(event) => this.setState({ link: event.target.value })}
                      />
                      <label className="form-control-placeholder" htmlFor="link">
                        <i className="fas fa-envelope-square prefix white-text active pr-2" /> Link (optional)
                      </label>
                    </div>
                    <div className="form-group pb-1">
                      <CheckboxComponent
                        name="onlySelectedPlace"
                        id="onlySelectedPlace"
                        checked={onlySelectedPlace}
                        param="onlySelectedPlace"
                        setState={this.setStateFunction}
                        title="Only on selected place/s?"
                      />
                    </div>
                    {onlySelectedPlace && (
                      <div className="form-group pb-1">
                        <SelectComponent
                          name="onlySelectedPlaceData"
                          id="onlySelectedPlaceData"
                          isMulti
                          options={placeList}
                          value={onlySelectedPlaceData}
                          onChange={this.handleChangeOptions}
                          param="onlySelectedPlaceData"
                          title="Place (If the name not exist, let it empty)"
                        />
                      </div>
                    )}
                    <div className="form-group pb-1">
                      <CheckboxComponent
                        name="isFeatured"
                        id="isFeatured"
                        param="isFeatured"
                        setState={(params, checked) => {
                          this.setState({ [params]: !checked });
                          if (document.getElementById('isFeatured').checked === true) {
                            this.setState({ notifyToAll: true });
                            document.getElementById('isPopular').checked = true;
                          }
                        }}
                        title="Add to Featured?"
                      />
                    </div>
                    <div className="form-group pb-1">
                      <label className="switch" style={{ float: 'left' }}>
                        <input
                          type="checkbox"
                          name="isPopular"
                          id="isPopular"
                          onChange={() => {
                            if (document.getElementById('isPopular').checked === true) {
                              this.setState({ notifyToFollowers: true });
                            }
                          }}
                        />
                        <span className="slider round"> </span>
                      </label>
                      <span style={{ fontSize: 18, fontWeight: 'bold' }}>Add to popular?</span>
                    </div>
                    <div className="form-group pb-1">
                      <label className="switch" style={{ float: 'left' }}>
                        <input
                          type="checkbox"
                          name="notifyToAll"
                          onClick={() => this.setState((prevState) => ({ notifyToAll: !prevState.notifyToAll }))}
                          checked={this.state.notifyToAll}
                        />
                        <span className="slider round"> </span>
                      </label>
                      <span style={{ fontSize: 18, fontWeight: 'bold' }}>Notify to All?</span>
                    </div>
                    <div className="form-group pb-1">
                      <label className="switch" style={{ float: 'left' }}>
                        <input
                          type="checkbox"
                          name="notifyToFollowers"
                          onClick={() => this.setState((prevState) => ({ notifyToFollowers: !prevState.notifyToFollowers }))}
                          checked={this.state.notifyToFollowers}
                        />
                        <span className="slider round"> </span>
                      </label>
                      <span style={{ fontSize: 18, fontWeight: 'bold' }}>Notify to Followers?</span>
                    </div>

                    <div className="form-group pb-1">
                      <label className="switch" style={{ float: 'left' }}>
                        <input
                          type="checkbox"
                          name="uploadWithUrl"
                          onClick={() => this.setState((prevState) => ({ uploadWithUrl: !prevState.uploadWithUrl }))}
                          checked={this.state.uploadWithUrl}
                        />
                        <span className="slider round"> </span>
                      </label>
                      <span style={{ fontSize: 18, fontWeight: 'bold' }}>Upload with URL?</span>
                    </div>
                    {this.state.uploadWithUrl === false ? (
                      <div className="form-group">
                        <input
                          required={this.state.uploadWithUrl !== true}
                          type="file"
                          id="recfile"
                          name="recfile"
                          className="form-control"
                          placeholder="Select file"
                          accept="image/*"
                          onChange={this.handleselectedFile}
                        />
                        <p>Max 900kb</p>
                      </div>
                    ) : (
                      <div className="form-group">
                        <input
                          type="text"
                          name="imgUrl"
                          id="imgUrl"
                          className="form-control"
                          value={this.state.imgUrl}
                          onChange={(event) => this.setState({ imgUrl: event.target.value })}
                          required
                        />
                        <label className="form-control-placeholder" htmlFor="imgUrl">
                          <i className="fas fa-image prefix white-text active pr-2" /> Image url
                        </label>
                      </div>
                    )}
                    <button className="btn btn-primary btn-block" type="submit" style={{ backgroundColor: '#27A4DD', color: '#fff' }}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Content>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ store, place }) => {
  const { storeLoading, storeError, storeList, storeLastFetched, storeListWeb } = store;
  const { placeList } = place;
  return { storeLoading, storeError, storeList, storeLastFetched, storeListWeb, placeList };
};
export default connect(mapStateToProps, { fetchAllPlaces, fetchAllStore })(withRouter(AddDiscountScreen));
