import AuthAPI from './auth';
import ChartAPI from './chart';
import DiscountAPI from './discount';
import StoreAPI from './store';
import UserAPI from './user';
import ReportAPI from './report';
import PollingAPI from './polling';
import NewsAPI from './news';
import CouponAPI from './coupon';
import CrawlAPI from './crawl.api';
import PlaceAPI from './place.api';
import ThirdPartyAPI from './thirdparty.api';

const DashboardAPI = {
  AuthAPI,
  ChartAPI,
  DiscountAPI,
  StoreAPI,
  UserAPI,
  ReportAPI,
  PollingAPI,
  NewsAPI,
  CouponAPI,
  CrawlAPI,
  PlaceAPI,
  ThirdPartyAPI,
};

export default DashboardAPI;
