/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { MDBDataTable } from 'mdbreact';
import Cookies from 'universal-cookie';
import { withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import { Image, Button } from 'react-bootstrap';
import DashboardAPI from '../../../service/api';
import { SpinnerComponent, SpinnerOverlay } from '../../../components/molecules/spinner';

const cookies = new Cookies();
const data = {
  columns: [
    {
      label: 'Id',
      field: 'id',
      sort: 'asc',
      width: 3,
    },
    {
      label: 'Name',
      field: 'name',
      sort: 'asc',
      width: 150,
    },
    {
      label: 'Email',
      field: 'email',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Username',
      field: 'username',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'Image',
      field: 'img',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Rank',
      field: 'rank',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Access',
      field: 'uac',
      sort: 'asc',
      width: 50,
    },
    {
      label: 'Created',
      field: 'created',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'Social Media?',
      field: 'isCon',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'Active?',
      field: 'active',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'View',
      field: 'view',
      sort: 'asc',
      width: 100,
    },
  ],
};

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingSearch: false,
    };
  }

  componentDidMount() {
    this.renderUser();
  }

  renderUser = async () => {
    this.setState({ loading: true });
    const token = cookies.get('token');
    try {
      const res = await DashboardAPI.UserAPI.list(token, 20);
      console.log('RESS', res);
      if (res.data.status === true) {
        this.setState({ loading: false });
        const result = [];
        // eslint-disable-next-line no-shadow
        res.data.result.map((data) => {
          result.push({
            id: data.id,
            name: data.name,
            email: data.email,
            username: data.username,
            img: data.img ? <Image src={data.img} style={{ width: 50, height: 50 }} /> : ` `,
            rank: data.rank || ` `,
            uac: data.uac || ` `,
            created: <Moment format="DD-MMM-YYYY">{data.created || ` `}</Moment>,
            isCon: data.isCon === 1 ? 'Yes' : ` `,
            active: data.active === 1 ? 'Yes' : ` `,
            view: (
              <Button
                color="default"
                rounded
                size="sm"
                style={{ backgroundColor: '#5EC5CC', color: '#fff' }}
                onClick={() => alert('Not available yet')}
              >
                View
              </Button>
            ),
          });
        });
        const resulttopush = [];
        resulttopush.columns = data.columns;
        resulttopush.rows = result;
        console.log(JSON.stringify(resulttopush));
        this.setState({
          dataRows: resulttopush,
          loading: false,
        });
      } else {
        alert('Ada masalah, silahkan coba lagi');
        this.setState({ loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  renderSearch = async (keyword) => {
    if (keyword.length >= 3) {
      this.setState({ loadingSearch: true });
      const token = cookies.get('token');
      try {
        const res = await DashboardAPI.UserAPI.search(token, keyword, 20);
        console.log('RESS', res);
        if (res.data.status === true) {
          this.setState({ loadingSearch: false });
          const result = [];
          // eslint-disable-next-line no-shadow
          res.data.result.map((data) => {
            result.push({
              id: data.id,
              name: data.name,
              email: data.email,
              username: data.username,
              img: data.img ? <Image src={data.img} style={{ width: 50, height: 50 }} /> : ` `,
              rank: data.rank || ` `,
              uac: data.uac || ` `,
              created: <Moment format="DD-MMM-YYYY">{data.created || ` `}</Moment>,
              isCon: data.isCon === 1 ? 'Yes' : ` `,
              active: data.active === 1 ? 'Yes' : ` `,
              view: (
                <Button
                  color="default"
                  rounded
                  size="sm"
                  style={{ backgroundColor: '#5EC5CC', color: '#fff' }}
                  onClick={() => alert('Not available yet')}
                >
                  View
                </Button>
              ),
            });
          });
          const resulttopush = [];
          resulttopush.columns = data.columns;
          resulttopush.rows = result;
          console.log(JSON.stringify(resulttopush));
          this.setState({
            dataRows: resulttopush,
            loadingSearch: false,
          });
        } else {
          alert('Ada masalah, silahkan coba lagi');
          this.setState({ loadingSearch: false });
        }
      } catch (error) {
        console.log(error);
        this.setState({ loadingSearch: false });
      }
    }
  };

  render() {
    const { loadingSearch, loading, dataRows } = this.state;
    return (
      <div>
        <SpinnerOverlay loading={loading} />
        <div className="view" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', paddingTop: 10 }}>
          <div className="mask rgba-gradient align-items-center">
            <div className="container">
              <div className="row mt-5">
                <div className="col-md-12 col-xl-12 mb-0">
                  <h2 style={{ textAlign: 'left' }}>List User</h2>
                </div>
                <div className="col-md-12 col-xl-12 mb-0">
                  <div className="card wow fadeInRight" data-wow-delay="0.3s" style={{ backgroundColor: '#ffffffb0' }}>
                    <div className="card-body">
                      <SpinnerComponent loading={loadingSearch} />
                      <MDBDataTable striped bordered hover data={dataRows} responsive onSearch={(text) => this.renderSearch(text)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(UserList);
