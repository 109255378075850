import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

async function submit(header, data, type) {
  const url = type === 1 ? `${BASE_URL}api/postdiscountaws` : `${BASE_URL}api/postdiscountawswithurl`;
  const response = await axios({
    method: 'post',
    url,
    data,
    headers: header,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function multipleBrandDiscount(token, brandList) {
  const url = `${BASE_URL}dashboard/listdiscountbrand`;
  const header = {
    Authorization: `Bearer ${token}`,
    brandlist: `'${brandList}'`,
  };
  const response = await axios({
    method: 'GET',
    url,
    headers: header,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function detail(token, id) {
  const url = `${BASE_URL}dashboard/detaildiscount`;
  const header = {
    Authorization: `Bearer ${token}`,
    id,
  };
  const response = await axios({
    method: 'GET',
    url,
    headers: header,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function listdiscount(token, limit) {
  const url = `${BASE_URL}dashboard/listdiscount`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'GET',
    url,
    params: {
      limit,
    },
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function search(token, keyword, limit = 10) {
  const url = `${BASE_URL}dashboard/discount/search`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(url, {
    params: {
      limit,
      keyword,
    },
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

async function searchname(token, keyword, limit = 10) {
  const url = `${BASE_URL}dashboard/discount/searchname`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios({
    method: 'GET',
    url,
    params: {
      limit,
      search: keyword,
    },
    headers,
  });
  if (response.status !== 200) {
    throw new Error('Failed to fetch');
  } else {
    return response;
  }
}

const AuthAPI = {
  submit,
  multipleBrandDiscount,
  detail,
  listdiscount,
  search,
  searchname,
};

export default AuthAPI;
