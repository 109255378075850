export const FETCH_ALL_PLACE = 'FETCH_ALL_PLACE';
export const FETCH_ALL_PLACE_SUCCESS = 'FETCH_ALL_PLACE_SUCCESS';
export const FETCH_ALL_PLACE_FAILED = 'FETCH_ALL_PLACE_FAILED';

export const fetchingAllPlaces = () => {
  return {
    type: FETCH_ALL_PLACE,
  };
};

export const fetchingAllPlaceSuccess = (place) => {
  const today = new Date();
  return {
    type: FETCH_ALL_PLACE_SUCCESS,
    payload: place,
    date: today,
  };
};

export const fetchingAllPlaceFailed = (error) => {
  return {
    type: FETCH_ALL_PLACE_SUCCESS,
    payload: error,
  };
};
