/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import Map from './map';

// eslint-disable-next-line react/prefer-stateless-function
class MapsView extends PureComponent {
  render() {
    return (
      <div style={{ margin: '100px' }}>
        <Map google={this.props.google} center={{ lat: 18.5204, lng: 73.8567 }} height="300px" zoom={15} />
      </div>
    );
  }
}

export default MapsView;
