export const FETCH_ALL_DISCOUNT = 'FETCH_ALL_DISCOUNT';
export const FETCH_ALL_DISCOUNT_SUCCESS = 'FETCH_ALL_DISCOUNT_SUCCESS';
export const FETCH_ALL_DISCOUNT_FAILED = 'FETCH_ALL_DISCOUNT_FAILED';

export const SET_DISCOUNT_SEARCH = 'SET_DISCOUNT_SEARCH';
export const SET_DISCOUNT_SEARCH_SUCCESS = 'SET_DISCOUNT_SEARCH_SUCCESS';
export const SET_DISCOUNT_SEARCH_FAILED = 'SET_DISCOUNT_SEARCH_FAILED';

export const UPDATE_DISCOUNT = 'UPDATE_DISCOUNT';
export const UPDATE_DISCOUNT_SUCCESS = 'UPDATE_DISCOUNT_SUCCESS';
export const UPDATE_DISCOUNT_FAILED = 'UPDATE_DISCOUNT_FAILED';

export const CLEAR_DISCOUNT_SEARCH = 'CLEAR_DISCOUNT_SEARCH';

export const fetchingAllDiscounts = () => {
  return {
    type: FETCH_ALL_DISCOUNT,
  };
};

export const fetchingAllDiscountSuccess = (discount) => {
  const today = new Date();
  return {
    type: FETCH_ALL_DISCOUNT_SUCCESS,
    payload: discount,
    date: today,
  };
};

export const fetchingAllDiscountFailed = (error) => {
  return {
    type: FETCH_ALL_DISCOUNT_SUCCESS,
    payload: error,
  };
};

export const setDiscountSearch = (text) => {
  return {
    type: SET_DISCOUNT_SEARCH,
    payload: text,
  };
};

export const setDiscountSearchSuccess = (discount) => {
  const today = new Date();
  return {
    type: SET_DISCOUNT_SEARCH_SUCCESS,
    payload: discount,
    date: today,
  };
};

export const setDiscountSearchFailed = (error) => {
  return {
    type: SET_DISCOUNT_SEARCH_FAILED,
    payload: error,
  };
};

export const updateDiscountSuccess = (store) => {
  return {
    type: UPDATE_DISCOUNT_SUCCESS,
    payload: store,
  };
};

export const clearDiscountSearch = () => {
  return {
    type: CLEAR_DISCOUNT_SEARCH,
  };
};
